import { QueryClient } from 'react-query'

const queryConfig = {
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
}

const queryClient = new QueryClient(queryConfig)

export default queryClient
