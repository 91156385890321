import { Trans } from '@lingui/macro'

import Button from 'src/components/Button'

type RejectCancellationRequestButtonProps = {
  setShowRejectCancellationRequestDialog: (value: boolean) => void
  size: string
}

const RejectCancellationRequestButton: React.FC<
  RejectCancellationRequestButtonProps
> = ({ setShowRejectCancellationRequestDialog, size }) => {
  return (
    <Button
      type="button"
      variant="critical"
      size={size}
      className="h-full"
      onClick={(e) => {
        e.stopPropagation()
        setShowRejectCancellationRequestDialog(true)
      }}
    >
      <Trans>Reject</Trans>
    </Button>
  )
}

export default RejectCancellationRequestButton
