import { Trans, t } from '@lingui/macro'
import Spinner from 'react-svg-spinner'

import { ErpOrderCancellation } from 'src/api/erp-order-cancellation/types'

import useUpdateOrderCancelation from 'src/hooks/data/useUpdateOrderCancellation'

import Button from 'src/components/Button'

import { openSuccessToast } from 'src/lib/toast'

type ApprovalCancellationRequestButtonProps = {
  cancellationRequest: ErpOrderCancellation
  size: string
}

const ApprovalCancellationRequestButton: React.FC<
  ApprovalCancellationRequestButtonProps
> = ({ cancellationRequest, size }) => {
  const { isLoading, mutate: approveCancellation } = useUpdateOrderCancelation({
    onSuccess: () => {
      openSuccessToast(t`Cancellation request approved`)
    },
  })

  return (
    <Button
      variant="purple"
      size={size}
      className="h-full"
      onClick={(e) => {
        e.stopPropagation()
        approveCancellation({
          uuid: cancellationRequest?.uuid,
          debitorNumber: cancellationRequest?.debitorNumber,
          state: 'approved',
        })
      }}
    >
      {isLoading ? (
        <Spinner size="1.7rem" color="white" />
      ) : (
        <Trans>Approve</Trans>
      )}
    </Button>
  )
}

export default ApprovalCancellationRequestButton
