import { XIcon } from '@heroicons/react/outline'

import { DialogClose } from 'src/components/Dialog/elements/DialogClose'

export const DialogXButton = () => (
  <DialogClose asChild>
    <button className="absolute right-0 top-0 m-2 flex h-10 w-10 items-center justify-center text-gray-400 hover:text-gray-800 md:h-8 md:w-8">
      <XIcon className="h-full w-full" />
    </button>
  </DialogClose>
)
