import React, { FC } from 'react'

import cn from '@meltdownjs/cn'
import Spinner from 'react-svg-spinner'

import { PhotographIcon } from '@heroicons/react/outline'

const cache = new Set()

const useTimeout = (action: Function, timeout: number) => {
  React.useEffect(() => {
    const timer = setTimeout(action, timeout)
    return () => {
      clearTimeout(timer)
    }
  })
}

interface DelayedSpinnerProps {
  size: string
  color: string
  thickness: number
}

const DelayedSpinner: FC<DelayedSpinnerProps> = (props) => {
  const [show, setShow] = React.useState(false)

  useTimeout(() => setShow(true), 400)

  if (show === false) {
    return null
  }

  return <Spinner {...props} />
}

const useImageState = (src: string): string => {
  const [state, setState] = React.useState<string>(() =>
    cache.has(src) ? 'loaded' : 'idle'
  )

  React.useEffect(() => {
    const img = new Image()

    img.onload = () => {
      cache.add(src)
      setState('loaded')
    }

    img.onerror = () => {
      setState('error')
    }
    if (state === 'idle' && src) {
      img.src = src

      setState('loading')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, src])

  return state
}

interface ImgProps {
  src: string
  className?: string
  spinnerSize: string
  spinnerThickness: number
  placeholderClassName?: string
  alt?: string
  onClick?: React.MouseEventHandler<HTMLImageElement>
}

const Img: FC<ImgProps> = React.memo(
  ({
    src,
    className,
    spinnerSize = '100%',
    spinnerThickness = 1,
    placeholderClassName,
    onClick,
    ...props
  }: ImgProps) => {
    const imageState = useImageState(src)

    const placeholder = (
      <div className={cn(className, 'flex items-center justify-center')}>
        <PhotographIcon className={cn('text-gray-200', placeholderClassName)} />
      </div>
    )

    if (imageState === 'idle' || !src) {
      return placeholder
    }

    if (imageState === 'error') {
      return placeholder
    }

    if (imageState === 'loading') {
      return (
        <div
          className={cn(
            'flex items-center justify-center text-gray-400',
            className
          )}
          {...props}
        >
          <DelayedSpinner
            size={spinnerSize}
            color="currentColor"
            thickness={spinnerThickness}
          />
        </div>
      )
    }

    return (
      <img
        className={className}
        src={src}
        onClick={onClick}
        {...props}
        alt=""
      />
    )
  }
)

export default Img
