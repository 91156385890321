import { useMutation } from 'react-query'
import queryClient from 'src/react-query-client'

import { i18n } from '@lingui/core'

import { postCart, transformCartItemsToApiShape } from 'src/api'

const filters = {
  searchOptions: {
    sort: 'global_sort_order_asc',
    filters: {
      brand: [],
    },
  },
  locale: i18n.locale,
  cartOptions: { hiddenColumns: [] },
}

const useCreateCartWithItems = (options) =>
  useMutation(
    ({ companyUserId, name, lineItems }) => {
      const items = transformCartItemsToApiShape(lineItems)
      return postCart({ companyUserId, name, filters, items })
    },
    {
      onSettled: () => {
        setTimeout(() => {
          queryClient.invalidateQueries('carts')
          queryClient.invalidateQueries('carts-infinite')
        }, 1000)
      },
      ...options,
    }
  )

export default useCreateCartWithItems
