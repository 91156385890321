import { useMutation } from 'react-query'
import queryClient from 'src/react-query-client'

import produce from 'immer'

import { patchCart } from 'src/api'

const getPreviousCartFromInfiniteQueryCacheById = (cartId) => {
  const queries = queryClient.getQueriesData('carts-infinite')

  for (const query of queries) {
    for (const page of query[1].pages) {
      const infiniteCart = page.carts.find(({ id }) => id === cartId)

      if (infiniteCart !== undefined) {
        return infiniteCart
      }
    }
  }

  return undefined
}

const getPreviousCartById = (cartId) => {
  const cart = queryClient.getQueryData(['cart', cartId])

  if (cart !== undefined) {
    return cart
  }

  return getPreviousCartFromInfiniteQueryCacheById(cartId)
}

const useUpdateCart = (_companyUserId, _cartId) =>
  useMutation(
    ({ companyUserId = _companyUserId, cartId = _cartId, updaterFn }) => {
      const cart = getPreviousCartById(cartId)

      const { items: _items, ...attributes } = produce(cart, updaterFn)
      return patchCart({ companyUserId, cartId, attributes })
    },
    {
      onMutate: async ({ cartId = _cartId, updaterFn }) => {
        await queryClient.cancelQueries(['cart', cartId])
        const previousCart = queryClient.getQueryData(['cart', cartId])
        if (previousCart) {
          queryClient.setQueryData(['cart', cartId], (previousCart) =>
            produce(previousCart, updaterFn)
          )
          return { previousCart }
        }
      },
      onSettled: ({ id: cartId }) => {
        queryClient.invalidateQueries('carts-infinite')
        queryClient.invalidateQueries('carts')
        queryClient.invalidateQueries(['cart', cartId])
      },
    }
  )

export default useUpdateCart
