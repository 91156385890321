import { useMutation } from 'react-query'
import queryClient from 'src/react-query-client'

import { postReleaseCart } from 'src/api'

const useReleaseCart = () =>
  useMutation(postReleaseCart, {
    onSettled: ({ cartId }) => {
      queryClient.invalidateQueries(['cart', cartId])
      queryClient.invalidateQueries('carts-infinite')
      queryClient.invalidateQueries(['carts'])
    },
  })

export default useReleaseCart
