import { useQuery } from 'react-query'

import { privateClient } from 'src/api/api-client'
import { normalizePayloadForType } from 'src/api/utils'

export const MAIN_QUERY_KEY = 'customer-locale'

export const getCustomerLocale = async () => {
  try {
    const customerPayload: any = await privateClient.get('customers').json()

    const customer = normalizePayloadForType(
      customerPayload,
      'customers',
      customerPayload.data[0].id
    )

    return customer.locale
  } catch {
    return null
  }
}

export const getCustomerLocaleQueryOptions = () => ({
  queryKey: [MAIN_QUERY_KEY],
  queryFn: () => getCustomerLocale(),
  //retry: 0,
  //refetchOnWindowFocus: false,
  // should be refetched in the background every 8 hours
  staleTime: 1000 * 60 * 60 * 8,
})

const useCustomerLocale = () => useQuery(getCustomerLocaleQueryOptions())

export default useCustomerLocale
