import React from 'react'

export type TUseBooleanResponse = [
  state: boolean,
  enable: () => void,
  disable: () => void
]

type TUseBoolean = (
  /*eslint-disable*/
  initialState: boolean
  /*eslint-enable*/
) => TUseBooleanResponse

const useBoolean: TUseBoolean = (initialState = false) => {
  const [state, setState] = React.useState(initialState)
  const enable = React.useCallback(() => setState(true), [])
  const disable = React.useCallback(() => setState(false), [])

  return [state, enable, disable]
}

export default useBoolean
