import React, { Fragment } from 'react'

import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'

import { AVAILABILITY_STATES } from 'src/hooks/data/usePaginatedAvailabilities'

import useDefaultCompanyUserPermissions from 'src/hooks/utils/useDefaultCompanyUserPermissions'

const AvailabilityMask = ({ quantity }) => {
  if (quantity < 20) {
    return <Trans>Low quantity</Trans>
  }
  if (quantity <= 300) {
    return <Trans>Medium quantity</Trans>
  }
  return <Trans>High quantity</Trans>
}

const AvailabilityDetails = ({
  availability,
  allowedQuantity: { quantityMax, quantityInterval },
}) => {
  const { ability } = useDefaultCompanyUserPermissions()

  const canSeeAvailability = ability.can('see', 'availability')

  return (
    <Fragment>
      {(availability.state === AVAILABILITY_STATES.available ||
        availability.state === AVAILABILITY_STATES.later) && (
        <div
          className="grid gap-x-3 gap-y-1 px-4 leading-snug"
          style={{
            gridTemplateColumns: 'max-content max-content',
          }}
        >
          {canSeeAvailability && availability.originalStartDate && (
            <Fragment>
              <div className="text-gray-800 text-opacity-50">
                <Trans>Launch date</Trans>
              </div>
              <div>
                {i18n.date(availability.earliestDeliveryDate, {
                  day: '2-digit',
                  month: 'long',
                  year: 'numeric',
                })}
              </div>
            </Fragment>
          )}

          <div className="text-gray-800 text-opacity-50">
            <Trans>Available from</Trans>
          </div>
          <div>
            {i18n.date(
              canSeeAvailability && availability.originalStartDate
                ? availability.originalStartDate
                : availability.earliestDeliveryDate,
              {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
              }
            )}
          </div>
          <div className="text-gray-800 text-opacity-50">
            <Trans>Quantity</Trans>
          </div>
          {canSeeAvailability || availability.quantity < 20 ? (
            <div>
              <Trans
                id="{count, plural, =1 {# item} other {# items}}"
                values={{ count: availability.quantity }}
              />
            </div>
          ) : (
            <div>
              <AvailabilityMask quantity={availability.quantity} />
            </div>
          )}
        </div>
      )}

      {availability.state === AVAILABILITY_STATES.unavailable && (
        <div className="flex h-8 items-center px-4">
          <Trans>This item is not available</Trans>
        </div>
      )}

      {(quantityInterval > 1 || quantityMax !== Infinity) && (
        <div className="pt-4">
          {quantityInterval > 1 && (
            <div className="px-4 text-xs text-gray-800 text-opacity-50">
              <Trans>Please order in steps of {quantityInterval}</Trans>
            </div>
          )}
          {quantityMax !== Infinity && (
            <div className="px-4 text-xs text-gray-800 text-opacity-50">
              <Trans>Max. {quantityMax} pieces per order</Trans>
            </div>
          )}
        </div>
      )}
    </Fragment>
  )
}

export default AvailabilityDetails
