import normalizeJSONApiResponse from 'json-api-normalizer'
import expandJSONApiResponse from 'redux-object'

import { privateClient } from './api-client'

export const normalizePayloadForType = (payload, type, id) => {
  const normalized = normalizeJSONApiResponse(payload)
  const expanded = expandJSONApiResponse(normalized, type, id, {
    eager: true,
    includeType: true,
  })

  // list endpoints might return an empty array. unfortunately,
  // expandJSONApiResponse defaults to returning null in this case.
  // we need return an empty array here to keep things consistent
  // NOTE: only do this if we're not getting an id to extract out of
  // the payload
  if (expanded === null && id === undefined) {
    return []
  }

  return expanded
}

export const extractTokens = ({
  data: {
    attributes: { accessToken, refreshToken },
  },
}) => ({
  accessToken,
  refreshToken,
})

export const getAccessToken = () => localStorage.getItem('accessToken')
export const getRefreshToken = () => localStorage.getItem('refreshToken')

export const removeAccessToken = () => localStorage.removeItem('accessToken')
export const removeRefreshToken = () => localStorage.removeItem('refreshToken')

export const setAccessToken = (accessToken) =>
  localStorage.setItem('accessToken', accessToken)
export const setRefreshToken = (refreshToken) =>
  localStorage.setItem('refreshToken', refreshToken)

export const fetchInBatches = async ({
  url,
  propertyName,
  pageSize = 12,
  getSearchParams,
}) => {
  const {
    data: [
      {
        attributes: {
          pagination: { numFound: numberOfItems },
          [propertyName]: firstPageItems,
        },
      },
    ],
  } = await privateClient(url, {
    searchParams: getSearchParams(0, pageSize),
  }).json()

  const numberOfPages = Math.ceil(numberOfItems / pageSize)
  if (numberOfPages === 1) {
    return firstPageItems
  }

  const promises = []
  for (let index = 1; index < numberOfPages; index++) {
    promises.push(
      privateClient(url, {
        searchParams: getSearchParams(index, pageSize),
      })
        .then((response) => response.json())
        .then((body) => body.data[0].attributes[propertyName])
    )
  }

  const items = await Promise.all(promises).then((...responses) =>
    responses.flat(2)
  )

  return [...firstPageItems, ...items]
}
