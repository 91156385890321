import React from 'react'

import AvailabilityCheckDialog from 'src/components/AvailabilityCheckDialog'

import ContentContainer from 'src/pages/Carts/Overview/components/ContentContainer'
import PageHeader from 'src/pages/Carts/Overview/components/PageHeader'

const Page = () => {
  return (
    <div className="container mx-auto h-full px-8 py-10 md:px-12 md:py-11 lg:px-16 lg:py-12">
      <div className="flex h-full flex-col space-y-8">
        <PageHeader />
        <ContentContainer />
      </div>
      <AvailabilityCheckDialog />
    </div>
  )
}

export default Page
