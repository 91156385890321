import React, { Fragment, useState } from 'react'

import { Trans, t } from '@lingui/macro'

import { ShoppingCartIcon } from '@heroicons/react/outline'

import { CompanyUser } from 'src/api/company-user-search/types'

import useDefaultCompanyUser from 'src/hooks/data/useDefaultCompanyUser'
import useInfiniteCarts from 'src/hooks/data/useInfiniteCarts'

import useHeight from 'src/hooks/utils/useHeight'

import TableSearch from 'src/components/TableSearch'

import CartsTable from 'src/pages/Carts/Overview/components/CartsTable'
import CreateNewCartButton from 'src/pages/Carts/Overview/components/DialogButton/CreateNewCartDialogButton'
import CloneCartDialogContainer from 'src/pages/Carts/Overview/components/DialogContainer/CloneCartDialogContainer'
import DeleteCartDialogContainer from 'src/pages/Carts/Overview/components/DialogContainer/DeleteCartDialogContainer'
import ExportCartDialogContainer from 'src/pages/Carts/Overview/components/DialogContainer/ExportCartDialogContainer'
import ReleaseCartDialogContainer from 'src/pages/Carts/Overview/components/DialogContainer/ReleaseCartDialogContainer'
import RenameCartDialogContainer from 'src/pages/Carts/Overview/components/DialogContainer/RenameCartDialogContainer'

const ContentContainer = () => {
  const { data: defaultCompanyUser } = useDefaultCompanyUser<CompanyUser>()
  const companyUserReference = defaultCompanyUser?.companyUserReference

  const [cartToRelease, setCartToRelease] = useState<any>(null)
  const closeReleaseCartDialog = () => setCartToRelease(null)
  const [cartToRename, setCartToRename] = useState<any>(null)
  const closeRenameCartDialog = () => setCartToRename(null)
  const [cartToClone, setCartToClone] = useState<any>(null)
  const closeCloneCartDialog = () => setCartToClone(null)
  const [cartToExport, setCartToExport] = useState<any>(null)
  const closeExportCartDialog = () => setCartToExport(null)
  const [cartToDelete, setCartToDelete] = useState<any>(null)
  const closeDeleteCartDialog = () => setCartToDelete(null)

  const [searchTerm, setSearchTerm] = React.useState<string>('')
  const { height, ref: tableHeightRef } = useHeight()

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isSuccess } =
    useInfiniteCarts({
      args: {
        companyUserReference,
        q: searchTerm,
        sort: 'last_name_asc',
      },
      options: { enabled: !!companyUserReference },
    })

  const cartsData = data as any

  if (isSuccess && cartsData?.items?.length === 0 && !searchTerm) {
    return <NoCartsFound />
  }

  return (
    <Fragment>
      {cartToRename && (
        <RenameCartDialogContainer
          cart={cartToRename}
          onClose={closeRenameCartDialog}
        />
      )}
      {cartToRelease && (
        <ReleaseCartDialogContainer
          cart={cartToRelease}
          onClose={closeReleaseCartDialog}
        />
      )}
      {cartToClone && (
        <CloneCartDialogContainer
          cart={cartToClone}
          onClose={closeCloneCartDialog}
        />
      )}
      {cartToDelete && (
        <DeleteCartDialogContainer
          onClose={closeDeleteCartDialog}
          companyUserReference={companyUserReference!}
          cartId={cartToDelete?.id}
        />
      )}
      {cartToExport && (
        <ExportCartDialogContainer
          cartId={cartToExport?.id}
          onClose={closeExportCartDialog}
        />
      )}
      <div className="flex h-full flex-1 flex-grow flex-col">
        <div className="h-10 justify-between">
          <TableSearch
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder={t`Search...`}
          />
        </div>
        <div
          className="overflow relative flex-shrink-0 flex-grow"
          ref={tableHeightRef}
        >
          {isSuccess && cartsData?.items?.length === 0 && searchTerm ? (
            <NoSearchResult />
          ) : (
            <CartsTable
              items={cartsData?.items}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              height={height}
              isFetchingNextPage={isFetchingNextPage}
              itemActions={{
                showDeleteCartDialog: setCartToDelete,
                showRenameCartDialog: setCartToRename,
                showCloneCartDialog: setCartToClone,
                showReleaseCartDialog: setCartToRelease,
                showExportCartDialog: setCartToExport,
              }}
            />
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default ContentContainer

const NoSearchResult = () => (
  <div className="flex flex-col items-center justify-center space-y-6 bg-white px-4 pb-12 pt-8">
    <ShoppingCartIcon className="w-16 text-gray-200" />

    <div className="text-center">
      <div className="text-lg font-medium">
        <Trans>Your search seems to have no matches.</Trans>
      </div>
      <div className="text-gray-500">
        <Trans>
          With a successful search you will see results. You may have to change
          your search.
        </Trans>
      </div>
    </div>
  </div>
)

const NoCartsFound = () => (
  <div className="flex flex-col items-center justify-center space-y-6 bg-white px-4 pb-12 pt-8">
    <ShoppingCartIcon className="w-16 text-gray-200" />

    <div className="text-center">
      <div className="text-lg font-medium">
        <Trans>You don't seem to have any carts.</Trans>
      </div>
      <div className="text-gray-500">
        <Trans>Create a cart to start shopping!</Trans>
      </div>
    </div>

    <CreateNewCartButton />
  </div>
)
