import { ReactNode } from 'react'

import { Trans } from '@lingui/macro'
import Spinner from 'react-svg-spinner'

import { ExclamationIcon } from '@heroicons/react/outline'
import { SearchIcon } from '@heroicons/react/solid'

export const NoSearchResult = () => (
  <div className="flex h-full w-full flex-col items-center justify-center space-y-6 bg-white px-4 pb-12 pt-8">
    <SearchIcon className="w-16 text-gray-200" />
    <div className="text-center">
      <div className="text-lg font-medium">
        <Trans>Your search seems to have no matches.</Trans>
      </div>
      <div className="text-gray-500">
        <Trans>
          With a successful search you will see results. You may have to change
          your search.
        </Trans>
      </div>
    </div>
  </div>
)

interface NoDataFoundProps {
  error?: ReactNode
}

export const NoDataFound: React.FC<NoDataFoundProps> = ({ error }) => (
  <div className="flex h-full w-full flex-col items-center justify-center space-y-6 bg-white px-4 pb-12 pt-8">
    <ExclamationIcon className="w-16 text-gray-200" />
    <div className="space-y-3 text-center">
      <div className="text-lg font-medium ">
        <Trans>Unfortunately we could not find any data.</Trans>
      </div>
      {error && <div className="text-lg font-light">{error}</div>}
      <div className="flex flex-col text-sm  text-gray-500">
        <span className="font-medium">
          <Trans>You need help?</Trans>
        </span>
        <span>
          <Trans>
            Contact us at{' '}
            <span className="font-semibold"> (0) 221 956 73 220</span> or send
            us an email at{' '}
            <span className="font-semibold">service@fondof.de</span>.
          </Trans>
        </span>
      </div>
    </div>
  </div>
)

export const LoadingPlaceholder = () => (
  <div className="flex h-full w-full flex-col items-center justify-center space-y-6 px-4 pb-12 pt-8">
    <div className="h-16 w-16 text-gray-400">
      <Spinner size="100%" color="currentColor" />
    </div>
    <div className="text-center">
      <div className="text-lg font-light text-gray-800">
        <Trans>Data is loading. Please wait...</Trans>
      </div>
    </div>
  </div>
)

export default NoSearchResult

interface UnexpectedErrorPlaceholderProps {
  error?: ReactNode
}

export const UnexpectedErrorPlaceholder: React.FC<
  UnexpectedErrorPlaceholderProps
> = ({ error }) => (
  <div className="flex h-full w-full flex-col items-center justify-center space-y-6 bg-white px-4 pb-12 pt-8">
    <ExclamationIcon className="w-16 text-gray-200" />
    <div className="space-y-3 text-center">
      <div className="text-lg font-medium ">
        <Trans>An unexpected error has occurred!</Trans>
      </div>
      {error && <div className="text-lg font-light">{error}</div>}
      <div className="flex flex-col text-sm  text-gray-500">
        <span className="font-medium">
          <Trans>
            Try again later. If the error still occurs, please contact us
          </Trans>
        </span>
        <span>
          <Trans>
            Contact us at{' '}
            <span className="font-semibold"> (0) 221 956 73 220</span> or send
            us an email at{' '}
            <span className="font-semibold">service@fondof.de</span>.
          </Trans>
        </span>
      </div>
    </div>
  </div>
)
