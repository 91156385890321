import React from 'react'

import { useFlexLayout, useTable } from 'react-table'

import { Cell, HeaderCell, HeaderRow, TableContainer } from './Table'

const DataTable = ({ data, columns, userCellProps }) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
      },
      useFlexLayout
    )

  return (
    <div className="relative flex-shrink-0 flex-grow">
      <TableContainer
        {...getTableProps()}
        className="border-box w-full overflow-y-auto"
      >
        <thead className="sticky top-0 border-b bg-white">
          {headerGroups.map((headerGroup) => (
            <HeaderRow
              className="h-12 text-gray-500"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <HeaderCell
                  {...column.getHeaderProps((props) => ({
                    ...props,
                    ...column.cellProps,
                    className: 'bg-white',
                  }))}
                >
                  {column.render('Header')}
                  {column.isSorted && (
                    <span className="ml-2">
                      {column.isSortedDesc ? '↓' : '↑'}
                    </span>
                  )}
                </HeaderCell>
              ))}
            </HeaderRow>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="divide-y">
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} className="h-14">
                {row.cells.map((cell) => (
                  <Cell
                    {...cell.getCellProps((props, { cell }) => ({
                      ...props,
                      ...cell.column.cellProps,
                    }))}
                  >
                    {cell.render('Cell', userCellProps)}
                  </Cell>
                ))}
              </tr>
            )
          })}
        </tbody>
      </TableContainer>
    </div>
  )
}

export default DataTable
