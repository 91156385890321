import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import pushDataLayer from 'src/lib/pushDataLayer'

const DataLayerPushWrapper = ({ children, layer: _layer }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    const layer = { vpv_url: pathname, ..._layer }
    pushDataLayer(layer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return children
}

export default DataLayerPushWrapper
