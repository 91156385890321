class ExtendableError extends Error {
  constructor(message, error, data) {
    super(message, { cause: data })
    this.name = this.constructor.name

    if (error) {
      this.code = error.code
      this.status = error.status
      this.detail = error.detail
    }

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = new Error(message).stack
    }
  }
}

export class ApiError extends ExtendableError {}
