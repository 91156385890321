export enum ImageView {
  FRONT = 'imgFront',
  FRONT_LEFT = 'imgFrontLeft',
  LEFT = 'imgLeft',
  BACK_LEFT = 'imgBackLeft',
  BACK = 'imgBack',
  BACK_RIGHT = 'imgBackRight',
  RIGHT = 'imgRight',
  FRONT_RIGHT = 'imgFrontRight',
  TOP = 'imgTop',
  LEFT_INSIDE = 'imgLeftInside',
  RIGHT_INSIDE = 'imgRightInside',
  LEFT_OUTSIDE = 'imgLeftOutside',
  RIGHT_OUTSIDE = 'imgRightOutside',
  SOLE = 'imgSole',
  DETAIL01 = 'imgDetail01',
  DETAIL02 = 'imgDetail02',
  DETAIL03 = 'imgDetail03',
  DETAIL04 = 'imgDetail04',
  DETAIL05 = 'imgDetail05',
  DETAIL06 = 'imgDetail06',
  DETAIL07 = 'imgDetail07',
  DETAIL08 = 'imgDetail08',
  DETAIL09 = 'imgDetail09',
  DETAIL10 = 'imgDetail10',
}

export type Image = {
  large: string
  medium: string
  thumbnail: string
}

export type GroupedImage = {
  externalUrlLarge: string
  externalUrlSmall: string
  sortOrder: number
}

export type GroupedImages = { [K in ImageView]?: GroupedImage[] }
