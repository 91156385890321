const twoLetterIdentifier = (...parts) => {
  const string = parts.join(' ')
  let identifier = string
    .toUpperCase()
    .replace(/[^a-zA-Z\u00c0-\u017e ]/gi, '')
    .replace(/(gmbh)/gi, '')
    .split(' ')
    .filter(Boolean)
    .filter(({ length }) => length > 2)
    .map(([first, second = ''], _, { length }) =>
      length === 1 ? first + second : first
    )
    .slice(0, 2)
    .join('')

  if (identifier.length === 0) {
    identifier = string.replace(' ', '').slice(0, 2)
  }

  return identifier
}

export default twoLetterIdentifier
