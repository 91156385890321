import React from 'react'

import {
  OpenChangeReason,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'

import { DialogOptions } from 'src/components/Dialog/types'

const useDialog = ({
  initialOpen = false,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  onDismiss,
  onClose,
  useDismissProps = { enabled: true },
}: DialogOptions = {}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen)
  const [labelId, setLabelId] = React.useState<string | undefined>()
  const [descriptionId, setDescriptionId] = React.useState<string | undefined>()

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = React.useCallback(
    (open: boolean, event?: Event, reason?: OpenChangeReason) => {
      switch (reason) {
        case 'escape-key':
        case 'outside-press':
        case 'ancestor-scroll':
        case 'reference-press':
          if (!open) {
            onDismiss?.()
          }
          break
      }
      if (!open) {
        onClose?.()
      }

      if (!!setControlledOpen) {
        setControlledOpen(open, event, reason)
      } else {
        setUncontrolledOpen(open)
      }
    },
    [setControlledOpen, setUncontrolledOpen, onDismiss, onClose]
  )

  const data = useFloating({
    open,
    onOpenChange: setOpen,
  })

  const context = data.context

  const click = useClick(context, {
    enabled: controlledOpen == null,
  })
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
    ...useDismissProps,
  })
  const role = useRole(context)

  const interactions = useInteractions([click, dismiss, role])

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [open, setOpen, interactions, data, labelId, descriptionId]
  )
}

export default useDialog
