import { useQuery } from 'react-query'

import { TOrderBudgetSearchResponse, getOrderBudget } from 'src/api'

const orderBudgetSelector = (response: TOrderBudgetSearchResponse) => {
  return response.data[0].attributes.orderBudgets[0]
}

const useOrderBudget = (companyUserReference: string, options?: object) =>
  useQuery(
    ['orderBudget', companyUserReference],
    () => getOrderBudget(companyUserReference),
    {
      enabled:
        companyUserReference !== undefined && companyUserReference !== null,
      select: orderBudgetSelector,
      cacheTime: 0,
      staleTime: 0,
      ...options,
    }
  )

export default useOrderBudget
