import { Trans, t } from '@lingui/macro'

import {
  Dialog,
  DialogContainer,
  DialogContent,
  DialogContextType,
  DialogDescription,
  DialogHeading,
  DialogXButton,
} from 'src/components/Dialog'

import DeleteCartForm from 'src/forms/DeleteCartForm'
import { openFailureToast, openSuccessToast } from 'src/lib/toast'

type DeleteCartDialogContainerProps = {
  cartId: string
  companyUserReference: string
  onClose: () => void
}

const DeleteCartDialogContainer: React.FC<DeleteCartDialogContainerProps> = ({
  cartId,
  companyUserReference,
  onClose,
}) => (
  <Dialog initialOpen onClose={onClose}>
    <DialogContainer>
      <DialogXButton />
      <DialogHeading>
        <Trans>Delete cart</Trans>
      </DialogHeading>
      <DialogDescription>
        <Trans>Note: Are you sure you want to delete this cart?</Trans>
      </DialogDescription>
      <DialogContent>
        {
          ((context: DialogContextType) => {
            const dismiss = () => context.setOpen(false)
            return (
              <DeleteCartForm
                companyUserReference={companyUserReference!}
                cartId={cartId}
                onSuccess={() => {
                  openSuccessToast(t`Cart deleted successfully`)
                  dismiss()
                }}
                onCancel={dismiss}
                onError={() => {
                  openFailureToast(t`Cart could not be deleted`)
                  dismiss()
                }}
              />
            )
          }) as any
        }
      </DialogContent>
    </DialogContainer>
  </Dialog>
)

export default DeleteCartDialogContainer
