import React from 'react'
import { Navigate, RouteObject } from 'react-router-dom'

import DataLayerPushWrapper from 'src/components/DataLayerPushWrapper'

import lazyRetry from 'src/lib/lazyRetry'

const Reclamation = React.lazy(() =>
  lazyRetry(() => import('src/pages/Service/Reclamation'))
)
const ReclamationForm = React.lazy(() =>
  lazyRetry(() => import('src/pages/Service/Reclamation/Form'))
)
const SpareParts = React.lazy(() =>
  lazyRetry(() => import('src/pages/Service/SpareParts'))
)
const Contact = React.lazy(() =>
  lazyRetry(() => import('src/pages/Service/Contact'))
)
const Help = React.lazy(() => lazyRetry(() => import('src/pages/Service/Help')))
const StepByStepGuides = React.lazy(() =>
  lazyRetry(() => import('src/pages/Service/Help/StepByStepGuides'))
)

const serviceRoute: RouteObject = {
  path: 'service',
  children: [
    {
      path: 'reclamation',
      children: [
        {
          index: true,
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'reclamation',
              }}
            >
              <div className="container mx-auto flex flex-grow flex-col px-8 py-10 md:px-12 md:py-11 lg:px-16 lg:py-12">
                <Reclamation />
              </div>
            </DataLayerPushWrapper>
          ),
        },
        {
          path: 'form',
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'reclamation_form',
              }}
            >
              <div className="container mx-auto flex flex-grow flex-col px-8 py-10 md:px-12 md:py-11 lg:px-16 lg:py-12">
                <ReclamationForm />
              </div>
            </DataLayerPushWrapper>
          ),
        },
      ],
    },
    {
      path: 'spare-parts',
      element: (
        <DataLayerPushWrapper
          layer={{
            event: 'vpv_general',
            page_type: 'spare-parts',
          }}
        >
          <div className="container mx-auto flex flex-grow flex-col px-8 py-10 md:px-12 md:py-11 lg:px-16 lg:py-12">
            <SpareParts />
          </div>
        </DataLayerPushWrapper>
      ),
    },
    {
      path: 'contact',
      element: (
        <DataLayerPushWrapper
          layer={{
            event: 'vpv_general',
            page_type: 'contact',
          }}
        >
          <div className="container mx-auto flex flex-grow flex-col px-8 py-10 md:px-12 md:py-11 lg:px-16 lg:py-12">
            <Contact />
          </div>
        </DataLayerPushWrapper>
      ),
    },
    {
      path: 'help',
      children: [
        {
          path: '*',
          index: true,
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'help',
              }}
            >
              <div className="container mx-auto flex flex-grow flex-col px-8 py-10 md:px-12 md:py-11 lg:px-16 lg:py-12">
                <Help />
              </div>
            </DataLayerPushWrapper>
          ),
        },
        {
          path: 'step-by-step-guides',
          index: true,
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'help',
              }}
            >
              <div className="mx-auto flex w-full flex-grow flex-col">
                <StepByStepGuides />
              </div>
            </DataLayerPushWrapper>
          ),
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to="reclamation" />,
    },
  ],
}

export default serviceRoute
