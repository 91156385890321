import { Trans } from '@lingui/macro'

const ExplanationBlock = () => (
  <div className="space-y-2 rounded bg-yellow-200 p-3 text-sm font-normal text-gray-700">
    <span className="font-semibold">
      <Trans>Explanations on formatting</Trans>
    </span>
    <ul className="m-0 list-inside list-disc pl-0">
      <li>
        <span className="font-medium">
          <Trans>sku</Trans>
        </span>
        {' = '}
        <span>
          <Trans>Article number</Trans>
        </span>
      </li>
      <li>
        <span className="font-medium">
          <Trans>qty</Trans>
        </span>
        {' = '}
        <span>
          <Trans>Quantity</Trans>
        </span>
      </li>
      <li>
        <span className="font-medium">
          <Trans>deliveryDate</Trans>
        </span>
        {' = '}
        <span>
          <Trans>Desired delivery date</Trans>
        </span>
      </li>
    </ul>
    <div>
      <span>
        <Trans>
          Please note that the content must be formatted as text. Please pay
          attention to the correct date format (YYYY-MM-DD).
        </Trans>
      </span>
    </div>
  </div>
)

export default ExplanationBlock
