import React, { Fragment } from 'react'

import { Trans, t } from '@lingui/macro'

import { H1 } from 'src/components/Header'

import InvalidLinesDialog from 'src/pages/Tools/Budget/components/InvalidLinesDialog'

import UploadWeeklyBudgetsForm from 'src/forms/UploadWeeklyBudgetsForm'
import { openFailureToast, openSuccessToast } from 'src/lib/toast'

const Budget = () => {
  const [invalidLines, setInvalidLines] = React.useState<Record<number, any>>(
    {}
  )
  return (
    <Fragment>
      <div className="space-y-8">
        <H1 className="capitalize">
          <Trans>Manage weekly budgets</Trans>
        </H1>
        <div>
          <UploadWeeklyBudgetsForm
            onSuccess={({ invalidLines }) => {
              openSuccessToast(t`Uploaded successfully`)
              setInvalidLines(invalidLines || [])
            }}
            onError={() => openFailureToast(t`Upload failed`)}
          />
        </div>
      </div>
      {Object.keys(invalidLines).length > 0 && (
        <InvalidLinesDialog
          invalidLines={invalidLines}
          onClose={() => setInvalidLines({})}
        />
      )}
    </Fragment>
  )
}

export default Budget
