export const getGa4Item = (item, quantity) => ({
  item_id: item.id, // SKU
  item_name: item.name, // Produktname
  item_brand: item.brand, // Brand
  dimension10: item.size, // Größe
  item_variant: item.style ? item.style : item.name, // Tier Kategorie
  price: item.price / 100, // Bruttopreis (decimal)
  quantity, // Zahl der hinzugefügten Stück
})

export const getUniversalAnalyticsItem = (item, quantity) => ({
  name: item.name, // Produktname
  id: item.id, // SKU
  price: `${item.price / 100}`, // Bruttopreis (string)
  brand: item.brand, // Brand
  dimension10: item.size, // Größe
  variant: item.style ? item.style : item.name, // Tier Kategorie
  quantity, // Zahl der hinzugefügten Stück
})
