import { WorkBook, read, utils } from 'xlsx'

import { ParsingError } from 'src/forms/components/DropZone/error'
import { ParsingResult } from 'src/forms/components/DropZone/type'

const parseUploadedFile = async <T>(
  file: File,
  rowMapper: (row: any[]) => T
): Promise<ParsingResult<T>> => {
  const workBook: WorkBook = read(await file.arrayBuffer(), {
    cellDates: false,
    raw: true,
  })
  const [firstSheetName] = workBook.SheetNames
  const firstSheet = workBook.Sheets[firstSheetName]

  const mappedRows: T[] = []
  const parsingErrors: ParsingError[] = []
  const rows: any[][] = utils.sheet_to_json(firstSheet, {
    blankrows: false,
    header: 1,
  })

  for (let index = 1; index < rows.length; index++) {
    try {
      const mappedRow = rowMapper(rows[index])

      mappedRows.push(mappedRow)
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Unkown error'
      const parsingError = new ParsingError(message, index)

      parsingErrors.push(parsingError)
    }
  }

  return {
    rows: mappedRows,
    errors: parsingErrors,
  }
}

export default parseUploadedFile
