import { getLocale } from 'src/lib/userLocaleHelper'

import { privateClient, publicClient } from './api-client'
import { fetchInBatches, normalizePayloadForType } from './utils'

export const getCustomer = async () => {
  const customerPayload = await privateClient.get('customers').json()

  const customer = normalizePayloadForType(
    customerPayload,
    'customers',
    customerPayload.data[0].id
  )

  return customer
}

export const patchCustomer = async ({
  customerId,
  customer: { firstName, lastName, salutation },
}) =>
  await privateClient
    .patch(`customers/${customerId}`, {
      json: {
        data: {
          type: 'customers',
          attributes: {
            firstName,
            lastName,
            salutation,
          },
        },
      },
    })
    .json()

export const patchCustomerName = async ({ customerId, firstName, lastName }) =>
  await privateClient
    .patch(`customers/${customerId}`, {
      json: {
        data: {
          type: 'customers',
          attributes: {
            firstName,
            lastName,
          },
        },
      },
    })
    .json()

export const patchPassword = ({
  customerId,
  oldPassword,
  newPassword,
  confirmPassword,
}) =>
  privateClient.patch(`customer-password/${customerId}`, {
    json: {
      data: {
        type: 'customer-password',
        attributes: {
          password: oldPassword,
          newPassword,
          confirmPassword,
        },
      },
    },
  })

export const getCompanyUsersRoles = async () => {
  const companyRoles = await fetchInBatches({
    url: 'company-role-search',
    propertyName: 'companyRole',
    pageSize: 36,
    getSearchParams: (index, pageSize) => ({
      'page[offset]': pageSize * index,
      'page[limit]': pageSize,
      'only-one-per-name': true,
      sort: 'company_users_rest_api.sort.name_desc',
    }),
  })

  return companyRoles
}

export const postLogin = async (login) =>
  await publicClient
    .post('access-tokens', {
      json: {
        data: {
          attributes: login,
          type: 'access-tokens',
        },
      },
    })
    .json()

export const postRefreshToken = async (refreshToken) =>
  await publicClient
    .post('refresh-tokens', {
      json: {
        data: {
          attributes: {
            refreshToken,
          },
          type: 'refresh-tokens',
        },
      },
    })
    .json()

export const postRequestNewPassword = async ({ email }) =>
  await publicClient
    .post('customer-forgotten-password', {
      json: {
        data: {
          attributes: {
            email,
          },
          type: 'customer-forgotten-password',
        },
      },
    })
    .json()

export const patchSetNewPassword = async ({
  restorePasswordKey,
  password,
  confirmPassword,
}) =>
  await publicClient
    .patch(`customer-restore-password/${restorePasswordKey}`, {
      json: {
        data: {
          type: 'customer-restore-password',
          attributes: {
            restorePasswordKey,
            password,
            confirmPassword,
          },
        },
      },
    })
    .json()

export const patchUserLocale = async ({ customerId, locale }) =>
  await privateClient
    .patch(`customers/${customerId}`, {
      json: {
        data: {
          type: 'customers',
          attributes: {
            locale: getLocale(locale),
          },
        },
      },
    })
    .json()
