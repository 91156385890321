import { isPast, parseISO } from 'date-fns'

import { privateClient } from './api-client'

export const getAvailabilityPeriods = async (productIds, channel) => {
  const searchParams = new URLSearchParams({
    'page[offset]': 0,
    'page[limit]': 10000,
    'warehouse-group': 'EU',
    sort: 'sku_asc',
    ...(channel ? { channel } : {}),
  })

  for (const productId of productIds) {
    searchParams.append('sku[]', productId)
  }

  const response = await privateClient('conditional-availability-page-search', {
    searchParams,
  }).json()

  const periods = {}

  for (const item of response.data[0].attributes
    .conditionalAvailabilityPeriods) {
    // remove periods with non-positive quantities
    if (item.qty <= 0) {
      // console.warn('skipping availability period with quantity <= 0')
      continue
    }

    // remove periods which end in the past
    if (isPast(parseISO(item.endAt))) {
      // console.warn('skipping availability period that ended in the past')
      continue
    }

    const period = {
      sku: item.sku,
      id: Math.random().toString(32).slice(2),
      quantity: item.qty,
      start: item.startAt,
      end: item.endAt,
      earliestDeliveryDate: item.earliestDeliveryDate,
      originalStart: item.originalStartAt,
    }

    if (periods[item.sku] === undefined) {
      periods[item.sku] = [period]
    } else {
      periods[item.sku].push(period)
    }
  }

  return periods
}
