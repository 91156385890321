import React from 'react'

import cn from '@meltdownjs/cn'

export const H1 = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLProps<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h1
    className={cn('text-3xl font-bold sm:text-4xl', className)}
    {...props}
    ref={ref}
  >
    {children}
  </h1>
))

export const H2 = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLProps<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h1
    className={cn('text-2xl font-bold sm:text-3xl', className)}
    {...props}
    ref={ref}
  >
    {children}
  </h1>
))

export const H3 = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLProps<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h1
    className={cn('text-xl font-bold sm:text-2xl', className)}
    {...props}
    ref={ref}
  >
    {children}
  </h1>
))

export const H4 = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLProps<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h1
    className={cn('text-lg font-bold sm:text-xl', className)}
    {...props}
    ref={ref}
  >
    {children}
  </h1>
))
