import { useQuery } from 'react-query'

import { erpOrderSearch } from 'src/api/erp-order-page-search'

import { UseErpOrderSearchProps } from './types'

export const MAIN_QUERY_KEY = 'erp-order-page-search'

export const getErpOrderSearchQueryOptions = <TSelect>({
  args,
  options,
}: UseErpOrderSearchProps<TSelect>) => ({
  queryKey: [MAIN_QUERY_KEY, args],
  queryFn: () => erpOrderSearch(args),
  staleTime: 1000 * 60 * 60 * 8,
  cacheTime: 1000 * 60 * 60 * 24,
  ...options,
})

const useErpOrderSearch = <TSelect>(
  props: UseErpOrderSearchProps<TSelect> = {}
) =>
  useQuery<any, unknown, TSelect, string[]>(
    getErpOrderSearchQueryOptions<TSelect>(props)
  )

export default useErpOrderSearch
