import useCompanyUserSearch from 'src/hooks/data/useCompanyUserSearch'

const companyUserSelector = ({ companyUser: [companyUser] }) => companyUser

const useCompanyUser = (companyUserId, options) =>
  useCompanyUserSearch({
    args: { companyUserReference: companyUserId },
    options: {
      enabled: companyUserId !== undefined && companyUserId !== null,
      select: companyUserSelector,
      ...options,
    },
  })

export default useCompanyUser
