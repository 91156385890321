import React, { Fragment } from 'react'

import cn from '@meltdownjs/cn'

interface InputProps extends React.ComponentPropsWithoutRef<'input'> {
  disabled?: boolean
  error?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ error, disabled, ...props }, ref) => (
    <Fragment>
      <input
        ref={ref}
        className={cn(
          'h-10 w-full rounded border border-gray-400 bg-white px-3 placeholder-gray-400',
          {
            'hover:border-gray-600 focus:border-aubergine': !disabled,
            'border-red-500 hover:border-red-500 focus:border-red-500': !!error,
            'bg-gray-100 text-gray-600': disabled,
          }
        )}
        type="text"
        {...props}
      />
      {!!error && (
        <div className="text-sm font-light text-red-500">{`${error}`}</div>
      )}
    </Fragment>
  )
)

export default Input
