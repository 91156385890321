import React from 'react'
import { RouteObject, redirectDocument } from 'react-router-dom'

import DataLayerPushWrapper from 'src/components/DataLayerPushWrapper'

import lazyRetry from 'src/lib/lazyRetry'
import { getIsLoggedIn } from 'src/routes/utils'

const Login = React.lazy(() => lazyRetry(() => import('src/pages/Login')))
const RequestNewPassword = React.lazy(() =>
  lazyRetry(() => import('src/pages/RequestNewPassword'))
)
const RestorePassword = React.lazy(() =>
  lazyRetry(() => import('src/pages/RestorePassword'))
)

const loader = async () => {
  const isLoggedIn = await getIsLoggedIn()

  if (isLoggedIn) {
    return redirectDocument('/')
  }

  return null
}

const publicRoutes: RouteObject[] = [
  {
    path: 'login',
    index: true,
    loader,
    element: (
      <DataLayerPushWrapper
        layer={{
          event: 'vpv_general',
          page_type: 'login',
        }}
      >
        <Login />
      </DataLayerPushWrapper>
    ),
  },
  {
    path: 'forgotten-password',
    loader,
    element: (
      <DataLayerPushWrapper
        layer={{
          event: 'vpv_general',
          page_type: 'forgotten-password',
        }}
      >
        <RequestNewPassword />
      </DataLayerPushWrapper>
    ),
  },
  {
    path: 'restore/:restorePasswordKey',
    loader,
    element: (
      <DataLayerPushWrapper
        layer={{
          event: 'vpv_general',
          page_type: 'restore',
        }}
      >
        <RestorePassword />
      </DataLayerPushWrapper>
    ),
  },
  {
    path: 'invite/:restorePasswordKey',
    loader,
    element: (
      <DataLayerPushWrapper
        layer={{
          event: 'vpv_general',
          page_type: 'invite',
        }}
      >
        <RestorePassword />
      </DataLayerPushWrapper>
    ),
  },
]

export default publicRoutes
