import tailwindComponent from 'src/lib/tailwindComponent'

export const HeaderRow = tailwindComponent(
  'tr',
  'text-xs flex items-center text-gray-500'
)

export const TableContainer = tailwindComponent(
  'table',
  'bg-white rounded flex-grow flex flex-col'
)

export const Cell = tailwindComponent(
  'td',
  'flex items-center h-full overflow-hidden whitespace-nowrap',
  ({ align }) =>
    align === 'right' ? 'justify-end text-right' : 'justify-start text-left',
  ({ noPadding }) => ({ 'px-4': !noPadding })
)

export const HeaderCell = tailwindComponent(
  'td',
  'flex items-center h-full overflow-hidden whitespace-nowrap cursor-default',
  ({ align }) =>
    align === 'right' ? 'justify-end text-right' : 'justify-start text-left',
  ({ noPadding }) => ({ 'px-4': !noPadding })
)
