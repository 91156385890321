import { useMutation } from 'react-query'
import queryClient from 'src/react-query-client'

import { deleteCart } from 'src/api'

const useDeleteCart = () =>
  useMutation(
    ({
      companyUserReference,
      cartId,
    }: {
      companyUserReference: string
      cartId: string
    }) => deleteCart({ companyUserReference, cartId }),
    {
      onSettled: () => {
        return new Promise((resolve, reject) => {
          queryClient.invalidateQueries('carts-infinite')
          setTimeout(() => {
            queryClient.invalidateQueries('carts').then(resolve, reject)
          }, 1000)
        })
      },
    }
  )

export default useDeleteCart
