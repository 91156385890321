import React, { Fragment } from 'react'

import { i18n } from '@lingui/core'
import cn from '@meltdownjs/cn'
import {
  addDays,
  compareAsc,
  differenceInDays,
  max,
  min,
  parseISO,
} from 'date-fns'

import useDefaultCompanyUserPermissions from 'src/hooks/utils/useDefaultCompanyUserPermissions'

const AvailabilityPeriodVisualizer = ({
  id: selectedId,
  periods: unsortedPeriods,
}) => {
  const { ability } = useDefaultCompanyUserPermissions()

  const canSeeAvailability = ability.can('see', 'availability')

  if (!canSeeAvailability) {
    return null
  }

  const periods = unsortedPeriods
    .map((period) => ({
      ...period,
      start: parseISO(period.start),
      end: period.end.startsWith('9999')
        ? addDays(parseISO(period.start), 20)
        : parseISO(period.end),
      infinite: period.end.startsWith('9999') ? parseISO(period.end) : null,
    }))
    .sort((a, b) => compareAsc(a.start, b.start))

  const dates = periods.flatMap((p) => [p.start, p.end])
  const earliest = min(dates)
  const latest = max(dates)
  const intervalLength = differenceInDays(latest, earliest)

  return (
    <div
      className="mx-2 mt-2 rounded bg-white p-2"
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr auto auto',
        gridRowGap: 5,
        gridColumnGap: 10,
        width: '30vw',
      }}
    >
      {periods.map(({ id, quantity, start, end, infinite }, index) => {
        const today =
          (differenceInDays(new Date(), earliest) / intervalLength) * 100
        const left = (differenceInDays(start, earliest) / intervalLength) * 100
        const right =
          100 - (differenceInDays(end, earliest) / intervalLength) * 100
        return (
          <Fragment key={index}>
            <div className="relative bg-gray-300">
              <div
                className={cn('absolute flex h-full items-center justify-end', {
                  'bg-red-400': id === selectedId,
                  'bg-gray-600': id !== selectedId,
                })}
                style={{
                  left: `${left}%`,
                  right: infinite ? '0%' : `${right}%`,
                }}
              >
                {infinite && <span className="px-1 text-white">+</span>}
              </div>

              <div
                className="absolute bg-blue-700"
                style={{
                  position: 'absolute',
                  top: -5,
                  left: `calc(${today}% - 1px)`,
                  bottom: -5,
                  width: 3,
                }}
              />
            </div>

            <div className="tabular-nums">
              {i18n.date(start)}
              {' — '}
              {infinite ? (
                <span className="opacity-0">00/00/0000</span>
              ) : (
                i18n.date(end)
              )}
            </div>

            <div className="text-right tabular-nums">{quantity}</div>
          </Fragment>
        )
      })}
    </div>
  )
}

export default AvailabilityPeriodVisualizer
