import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Trans, t } from '@lingui/macro'

import Button from 'src/components/Button'
import {
  Dialog,
  DialogContainer,
  DialogContent,
  DialogContextType,
  DialogHeading,
  DialogTrigger,
  DialogXButton,
} from 'src/components/Dialog'
import { H3 } from 'src/components/Header'

import { ApiError } from 'src/errors'
import UploadRetailerCartForm from 'src/forms/UploadRetailerCartForm'
import { openFailureToast, openSuccessToast } from 'src/lib/toast'

type UploadRetailerCartErrorScreenProps = { errors: any; onClose: () => void }

const UploadRetailerCartErrorScreen: React.FC<
  UploadRetailerCartErrorScreenProps
> = ({ errors, onClose }) => (
  <div className="space-y-6">
    <H3>
      <Trans>Cart issues</Trans>
    </H3>
    <div>
      <Trans>The following errors occurred</Trans>
      <hr />
    </div>
    <div className="flex max-h-64 w-full flex-col space-y-2 overflow-y-auto">
      {errors.length > 0 ? (
        errors.map(({ detail }: { detail: string }, index: number) => (
          <span className="text-sm" key={index}>
            {detail}
          </span>
        ))
      ) : (
        <span className="text-sm">
          <Trans>Unexpected server error</Trans>
        </span>
      )}
    </div>
    <div className="text-xs leading-normal text-red-500">
      <Trans>
        The cart could <span className="font-semibold">not</span> be created!{' '}
        <br />
        The products do not yet exist in the Partnershop or you are not
        authorized to order them. Please contact the sale support.
      </Trans>
    </div>
    <div className="mt-8 flex">
      <Button
        onClick={onClose}
        size="xl"
        variant="purple"
        className="flex w-full flex-grow items-center justify-center"
      >
        <Trans>Got it</Trans>
      </Button>
    </div>
  </div>
)

const UploadRetailerCartDialog = () => {
  const navigate = useNavigate()

  const [cartUploadErrors, setCartUploadErrors] = React.useState(null)

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="text-sm" size="md" variant="gray">
          <Trans>Upload cart</Trans>
        </Button>
      </DialogTrigger>
      <DialogContainer>
        <DialogXButton />
        <DialogHeading>
          {cartUploadErrors ? (
            <Trans>Upload cart</Trans>
          ) : (
            <Trans>Cart issues</Trans>
          )}
        </DialogHeading>
        <DialogContent>
          {(context: DialogContextType) => {
            const dismiss = () => context.setOpen(false)
            const onDialogClose = () => {
              setCartUploadErrors(null)
              dismiss()
            }
            if (!!cartUploadErrors) {
              return (
                <UploadRetailerCartErrorScreen
                  errors={cartUploadErrors}
                  onClose={onDialogClose}
                />
              )
            }

            return (
              <UploadRetailerCartForm
                onCancel={onDialogClose}
                onError={(error) => {
                  openFailureToast(t`Cart could not be uploaded`)
                  let errors = []

                  if (error instanceof ApiError) {
                    errors = error?.cause as any
                  }

                  setCartUploadErrors(errors)
                }}
                onSuccess={(cart) => {
                  openSuccessToast(t`Cart uploaded successfully`)
                  navigate(`${cart.id}`)
                }}
              />
            )
          }}
        </DialogContent>
      </DialogContainer>
    </Dialog>
  )
}

export default UploadRetailerCartDialog
