import { Fragment } from 'react'

import Img from 'src/components/Img'

const Images = ({ images }: { images: any[] }) => {
  if (images !== null) {
    return (
      <Fragment>
        <Img
          className="object-cover"
          src={images[0].medium}
          alt=""
          spinnerSize="50%"
          spinnerThickness={2}
          placeholderClassName="w-2/3"
        />
      </Fragment>
    )
  }
  return (
    <Img
      className="object-cover"
      src=""
      alt=""
      spinnerSize="50%"
      spinnerThickness={2}
      placeholderClassName="w-2/3"
    />
  )
}
export default Images
