import React from 'react'
import { useParams } from 'react-router-dom'

import { Trans } from '@lingui/macro'
import cn from '@meltdownjs/cn'

import useCart from 'src/hooks/data/useCart'
import useDebouncedCartUpdate from 'src/hooks/data/useDebouncedCartUpdate'

import Button from 'src/components/Button'

type ResetFiltersButtonProps = {
  resetSearchTerm?: boolean
  className?: string
}

const ResetFiltersButton: React.FC<ResetFiltersButtonProps> = ({
  resetSearchTerm = true,
  className,
}) => {
  const { cartId } = useParams()
  const updateCart = useDebouncedCartUpdate(cartId)

  const { data } = useCart(cartId, {
    notifyOnChangeProps: 'tracked',
    select: ({ filters }: { filters: any }) => ({
      filters: filters.searchOptions.filters || {},
      searchTerm: filters.searchOptions.searchTerm,
    }),
  })

  const hasActiveFilter = React.useMemo(
    () =>
      Object.keys(data?.filters).some(
        (key) => data?.filters?.[key].length > 0
      ) || data?.searchTerm !== '',
    [data?.filters, data?.searchTerm]
  )

  if (!hasActiveFilter) {
    return null
  }

  return (
    <Button
      size="md"
      variant="none"
      className={cn('truncate', className)}
      onClick={() => {
        updateCart({
          updaterFn: (draft: any) => {
            Object.keys(draft.filters.searchOptions.filters).forEach(
              (key) => (draft.filters.searchOptions.filters[key] = [])
            )
            if (resetSearchTerm) {
              draft.filters.searchOptions.searchTerm = ''
            }
          },
        })
      }}
    >
      <Trans>Reset filters</Trans>
    </Button>
  )
}

export default ResetFiltersButton
