import { i18n } from '@lingui/core'
import { compareAsc, parseISO } from 'date-fns'

import { privateClient } from './api-client'
import { normalizePayloadForType } from './utils'

export const transformCartItemsToApiShape = (itemsObject) => {
  const itemsArray = []

  for (const id in itemsObject) {
    const { quantity } = itemsObject[id]
    const [sku, deliveryDate] = id.split('.')

    itemsArray.push({
      sku,
      deliveryDate,
      quantity,
    })
  }

  return itemsArray
}

export const transformCartItemsToLocalShape = (itemsArray) => {
  const itemsObject = {}

  for (const {
    id,
    quantity,
    validationMessages: messages,
    concreteDeliveryDate,
    calculations,
    productConcreteAttributes,
    name,
  } of itemsArray) {
    const [sku, deliveryDate] = id.split('.')

    itemsObject[id] = {
      id,
      name,
      quantity,
      deliveryDate,
      concreteDeliveryDate,
      calculations,
      sku,
      productConcreteAttributes,
      messages: messages.map((message) => message.value),
    }
  }

  return itemsObject
}

const transformCart = (cart, payloadAttributes) => {
  const deliveryDates = [
    'earliest-date',
    ...cart.deliveryDates
      .filter((date) => date !== 'earliest-date')
      .map((date) => parseISO(date))
      .sort(compareAsc),
  ]

  if (payloadAttributes) {
    // NOTE we're doing this because normalizePayloadForType camelizes
    // _all_ keys in the response object, which leads to problems in our
    // searchOptions.filters where we use its keys as the name for a filter
    // which _cannot_ be camelized otherwise we break our filters.
    cart.filters.searchOptions = payloadAttributes.filters.searchOptions
    cart.filters.cartOptions = payloadAttributes.filters.cartOptions || {
      hiddenColumns: [],
    }

    // we're doing this to reset the filter, just to the brand filter,
    // if the current locale is different to the locale when a filter is set.
    // otherwise the filter is not matching because translated filter facets are missing and not providable by the API
    if (
      payloadAttributes.filters.locale &&
      payloadAttributes.filters.locale !== i18n.locale
    ) {
      cart.filters.searchOptions.filters = {}
      cart.filters.cartOptions.hiddenColumns = []
    }
  }

  const items = transformCartItemsToLocalShape(cart.items || [])

  cart.totals = {
    discountTotal: cart.totals?.discountTotal || 0,
    expenseTotal: cart.totals?.expenseTotal || 0,
    grandTotal: cart.totals?.grandTotal || 0,
    priceToPay: cart.totals?.priceToPay || 0,
    subtotal: cart.totals?.subtotal || 0,
    taxTotal: cart.totals?.taxTotal || 0,
  }

  const isClaimed =
    cart.originalCustomerReference !== null &&
    cart.originalCompanyUserReference !== null

  return {
    ...cart,
    items,
    deliveryDates,
    isClaimed,
  }
}

export const getCart = async (cartId) => {
  const payload = await privateClient
    .get(`carts/${cartId}?include=items`)
    .json()

  const cart = normalizePayloadForType(payload, 'carts', cartId)

  //hotfix if attributes.id is null
  cart.id = cart.id || payload.data.id || cartId

  return transformCart(cart, payload.data.attributes)
}

export const patchCart = async ({ companyUserId, cartId, attributes }) => {
  const payload = await privateClient
    .patch(
      `company-users/${companyUserId}/company-user-carts/${cartId}?include=items`,
      {
        json: {
          data: {
            type: 'company-user-carts',
            attributes,
          },
        },
      }
    )
    .json()

  const cart = normalizePayloadForType(payload, 'companyUserCarts', cartId)
  return transformCart(cart)
}

export const postCheckoutCart = async ({
  cartId,
  billingAddressId,
  shippingAddressId,
  references,
  comments,
  preventCustomerOrderConfirmationMails,
  orderTypes,
}) => {
  const payload = await privateClient
    .post('splittable-checkout', {
      json: {
        data: {
          type: 'splittable-checkout',
          attributes: {
            idCart: cartId,
            billingAddress: {
              id: billingAddressId,
            },
            shippingAddress: {
              id: shippingAddressId,
            },
            payments: [
              {
                paymentProviderName: 'DummyPayment',
                paymentMethodName: 'invoice',
              },
            ],
            shipment: {
              idShipmentMethod: 1,
            },
            orderCustomReferences: references,
            cartNotes: comments,
            preventCustomerOrderConfirmationMails:
              preventCustomerOrderConfirmationMails,
            orderTypes: orderTypes,
          },
        },
      },
    })
    .json()

  const [checkout] = normalizePayloadForType(payload, 'splittableCheckout')

  return {
    ...checkout,
    references: payload.data.attributes.orderCustomReferences,
    comments: payload.data.attributes.cartNotes,
    preventCustomerOrderConfirmationMails:
      payload.data.attributes.preventCustomerOrderConfirmationMails,
    totalsList: payload.data.attributes.totalsList,
    orderReferences: payload.data.attributes.orderReferences,
    orderTypes: payload.data.attributes.orderTypes,
  }
}

export const postCart = async ({ companyUserId, name, filters, items }) => {
  const payload = await privateClient
    .post(`company-users/${companyUserId}/company-user-carts/`, {
      json: {
        data: {
          type: 'company-user-carts',
          attributes: {
            name,
            filters,
            items,
            priceMode: 'NET_MODE',
            currency: 'EUR',
            store: 'PS',
          },
        },
      },
    })
    .json()

  const [cart] = normalizePayloadForType(payload, 'companyUserCarts')

  //hotfix if attributes.id is null
  cart.id = cart.id || payload.data.id

  return cart
}

export const deleteCart = async ({ companyUserReference, cartId }) =>
  privateClient.delete(
    `company-users/${companyUserReference}/company-user-carts/${cartId}`
  )

export const postClaimCart = async ({ cartId }) => {
  const payload = await privateClient
    .post('collaborative-carts', {
      json: {
        data: {
          type: 'collaborative-carts',
          attributes: {
            action: 'claim',
            cartId,
          },
        },
      },
    })
    .json()

  const [claimedCart] = normalizePayloadForType(payload, 'collaborativeCarts')

  return claimedCart
}

export const postSplitCartTotals = async ({
  cartId,
  billingAddressId,
  shippingAddressId,
}) => {
  const payload = await privateClient
    .post('splittable-totals', {
      json: {
        data: {
          type: 'splittable-totals',
          attributes: {
            idCart: cartId,
            billingAddress: {
              id: billingAddressId,
            },
            shippingAddress: {
              id: shippingAddressId,
            },
            payments: [
              {
                paymentProviderName: 'DummyPayment',
                paymentMethodName: 'invoice',
              },
            ],
            shipment: {
              idShipmentMethod: 1,
            },
          },
        },
      },
    })
    .json()

  const totals = payload.data.attributes.totalsList

  return totals
}

export const postReleaseCart = async ({ cartId }) => {
  const payload = await privateClient
    .post('collaborative-carts', {
      json: {
        data: {
          type: 'collaborative-carts',
          attributes: {
            action: 'release',
            cartId,
          },
        },
      },
    })
    .json()

  const [claimedCart] = normalizePayloadForType(payload, 'collaborativeCarts')

  return claimedCart
}
