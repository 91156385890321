import { Fragment } from 'react'

const Skeleton = () => {
  return (
    <Fragment>
      <div className="mb-5 space-y-5">
        <h1 className="h-4 rounded-md bg-gray-200 dark:bg-gray-700"> </h1>
      </div>
      <div className="space-y-8">
        <ul className="mt-5 space-y-3">
          <li className="h-4 w-full rounded-md bg-gray-200 dark:bg-gray-700"></li>
          <li className="h-4 w-full rounded-md bg-gray-200 dark:bg-gray-700"></li>
          <li className="h-4 w-full rounded-md bg-gray-200 dark:bg-gray-700"></li>
          <li className="h-4 w-full rounded-md bg-gray-200 dark:bg-gray-700"></li>
        </ul>
      </div>
    </Fragment>
  )
}

export default Skeleton
