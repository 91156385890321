import React from 'react'
import { RouteObject, redirectDocument } from 'react-router-dom'

import Skeleton from 'src/pages/Skeleton'

import {
  getCompanyUsers,
  getDefaultCompanyUser,
  getIsInitialLogin,
  getIsLoggedIn,
} from 'src/routes/utils'

const loader = async () => {
  const isLoggedIn = await getIsLoggedIn()

  if (!isLoggedIn) {
    return redirectDocument('login')
  }

  const isInitialLogin = await getIsInitialLogin()

  if (isInitialLogin) {
    return redirectDocument('initial-login')
  }

  const defaultCompanyUser = await getDefaultCompanyUser()

  if (!!defaultCompanyUser) {
    return redirectDocument(`${defaultCompanyUser?.companyId}`)
  }

  const companyUsers = await getCompanyUsers()

  if (companyUsers.length === 1) {
    return redirectDocument(`${companyUsers[0].companyId}`)
  }

  return null
}

const rootIndexRoute: RouteObject = {
  index: true,
  element: <Skeleton />,
  loader,
}

export default rootIndexRoute
