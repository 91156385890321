const isIE = () => {
  const ua = navigator.userAgent
  return (
    ua.indexOf('MSIE') > -1 ||
    ua.indexOf('Trident') > -1 ||
    !!document.documentMode
  )
}

export default isIE
