import React from 'react'

import { Ability } from '@casl/ability'
import { createCanBoundTo } from '@casl/react'

import { CompanyUser } from 'src/api/company-user-search/types'

import distributor from 'src/hooks/utils/usePermissionsByCompanyUser/companyTypes/distributor'
import manufacturer from 'src/hooks/utils/usePermissionsByCompanyUser/companyTypes/manufacturer'
import retailer from 'src/hooks/utils/usePermissionsByCompanyUser/companyTypes/retailer'

const ROLES: Record<string, any> = {
  retailer,
  distributor,
  manufacturer,
}

const usePermissionsByCompanyUser = (companyUser: CompanyUser | undefined) => {
  return React.useMemo(() => {
    const roleName = companyUser?.companyRoles[0]?.name
    const type =
      companyUser?.companyTypeName === undefined
        ? 'retailer'
        : companyUser?.companyTypeName

    const permissions = ROLES[type]?.[roleName] || []

    const ability = new Ability(permissions, {
      detectSubjectType: (subject) => subject.type,
    })

    return { Can: createCanBoundTo(ability), ability }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyUser?.companyUserReference])
}

export default usePermissionsByCompanyUser
