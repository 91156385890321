import { positionMatchWidth } from '@reach/popover'

const positionMatchMinWidth = (...args) => {
  const { width, ...rest } = positionMatchWidth(...args)
  return {
    ...rest,
    minWidth: width,
  }
}

export default positionMatchMinWidth
