import React, { Fragment, useState } from 'react'

import { Trans } from '@lingui/macro'
import cn from '@meltdownjs/cn'
import { CircularProgressbar } from 'react-circular-progressbar'

import { CompanyUser } from 'src/api/company-user-search/types'

import useAvailability from 'src/hooks/data/useAvailabilities'
import useCustomer from 'src/hooks/data/useCustomer'
import useDefaultCompanyUser from 'src/hooks/data/useDefaultCompanyUser'
import useManufacturer from 'src/hooks/data/useManufacturer'
import useMutateSetDefaultCompanyUser from 'src/hooks/data/useMutateSetDefaultCompanyUser'
import {
  AVAILABILITY_STATES,
  makeAvailability,
} from 'src/hooks/data/usePaginatedAvailabilities'

import InfiniteProductSelect from 'src/components/AvailabilityCheckDialog/InfiniteProductSelect'
import AvailabilityDetails from 'src/components/AvailabilityDetails'
import AvailabilityPeriodVisualizer from 'src/components/AvailabilityPeriodVisualizer'
import Button from 'src/components/Button'
import {
  Dialog,
  DialogContainer,
  DialogContent,
  DialogHeading,
  DialogTrigger,
  DialogXButton,
} from 'src/components/Dialog'
import { LoadingPlaceholder } from 'src/components/NoDataPlaceholder'

import Select from 'src/forms/components/Select'

interface AvailabilityInterface {
  state: string
  quantity: number
  percentage: number
  availabilities: Array<object>
  id: string
}

interface ProductInterface {
  id: string
  allowedQuantity: {
    quantityInterval: number
    quantityMax: number
  }
}

const getCompanyUserReferenceToSwitch = (
  currentCompanyUser: CompanyUser,
  manufacturerUser: CompanyUser | undefined
): string | undefined => {
  if (canSwitchDefaultCompanyUser(currentCompanyUser, manufacturerUser)) {
    return manufacturerUser?.companyUserReference!
  }
  return undefined
}

const getCompanyUserReference = (
  currentCompanyUser: CompanyUser,
  manufacturerUser: CompanyUser | undefined
): string => {
  return (
    manufacturerUser?.companyUserReference ||
    currentCompanyUser.companyUserReference
  )
}

const canSwitchDefaultCompanyUser = (
  currentCompanyUser: CompanyUser,
  manufacturerUser: CompanyUser | undefined
): boolean => {
  if (manufacturerUser === undefined) {
    return false
  }

  if (
    manufacturerUser.companyUserReference ===
    currentCompanyUser.companyUserReference
  ) {
    return false
  }

  return (
    manufacturerUser.companyRoles[0]?.name === 'customer_service' &&
    currentCompanyUser.companyRoles[0]?.name === 'customer_service'
  )
}

const AvailabilityCheckDialog = () => {
  const [selectedChannel, setSelectedChannel] = useState<string | undefined>()

  const { data: customer } = useCustomer()
  const { data: companyUser } = useDefaultCompanyUser<CompanyUser>()
  const { data: manufacturerUser } = useManufacturer()

  const { mutate } = useMutateSetDefaultCompanyUser({
    onSuccess: () => {},
  })

  React.useEffect(() => {
    if (customer?.possibleAvailabilityChannels.length === 1) {
      setSelectedChannel(customer?.possibleAvailabilityChannels[0])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.possibleAvailabilityChannels.length])

  const companyUserReferenceToSwitch = getCompanyUserReferenceToSwitch(
    companyUser!,
    manufacturerUser
  )

  const companyUserReference = getCompanyUserReference(
    companyUser!,
    manufacturerUser
  )

  React.useEffect(() => {
    if (!!companyUserReferenceToSwitch) {
      mutate(companyUserReferenceToSwitch)
    }
  }, [companyUserReferenceToSwitch, mutate])

  const onClose = () => {
    if (!!companyUserReferenceToSwitch) {
      mutate(companyUser?.companyUserReference!)
    }
  }

  const [selectedProduct, setSelectedProduct] =
    React.useState<ProductInterface | null>(null)

  return (
    <Dialog onClose={onClose}>
      <DialogTrigger asChild>
        <Button
          className="fixed bottom-5 right-5 flex items-center space-x-2"
          variant="purple"
          size="xl"
        >
          <CircularProgressbar
            value={75}
            strokeWidth={20}
            className="icon h-6 w-6"
          />
          <span className="whitespace-nowrap">
            <Trans>Check Availability</Trans>
          </span>
        </Button>
      </DialogTrigger>

      <DialogContainer>
        <DialogXButton />
        <DialogHeading className="flex items-center space-x-2">
          <CircularProgressbar
            value={75}
            strokeWidth={20}
            className="default h-6 w-6"
          />
          <span>
            <Trans>Check Availability</Trans>
          </span>
        </DialogHeading>
        <DialogContent>
          <Fragment>
            {!!manufacturerUser &&
              !!customer &&
              customer.possibleAvailabilityChannels.length > 1 && (
                <div>
                  <label>
                    <div className="mb-2 text-sm leading-snug">
                      <Trans>Select a channel</Trans>
                    </div>
                    <Select
                      value={selectedChannel}
                      options={customer.possibleAvailabilityChannels.reduce(
                        (channels: object, channel: string) => ({
                          ...channels,
                          [channel]: channel,
                        }),
                        {} as Record<string, string>
                      )}
                      onChange={setSelectedChannel}
                    />
                  </label>
                </div>
              )}

            {!manufacturerUser ||
              (!!selectedChannel && (
                <InfiniteProductSelect
                  channel={selectedChannel}
                  onChange={setSelectedProduct}
                  companyUserReference={companyUserReference}
                />
              ))}

            {selectedProduct?.id && (
              <AvailabilityDetailsBlock
                selectedProduct={selectedProduct}
                selectedChannel={selectedChannel}
                companyUserReference={companyUserReference}
              />
            )}
          </Fragment>
        </DialogContent>
      </DialogContainer>
    </Dialog>
  )
}

const AvailabilityDetailsBlock = ({
  companyUserReference,
  selectedProduct,
  selectedChannel,
}: {
  companyUserReference: string
  selectedProduct: ProductInterface
  selectedChannel?: string
}) => {
  const { data: availabilityData, isLoading: isLoadingAvailability } =
    useAvailability(
      {
        productId: selectedProduct?.id,
        companyUserReference,
        channel: selectedChannel,
      },
      {
        select: (data) => {
          const availabilities =
            (data as Record<string, object>)?.[selectedProduct?.id] || []
          const availability = makeAvailability(availabilities)

          return availability
        },
        enabled: !!selectedProduct,
      }
    )

  if (isLoadingAvailability) {
    ;<LoadingPlaceholder />
  }

  const availability = availabilityData as AvailabilityInterface

  if (!availability) {
    //TODO: ADD Error Placeholder
    return null
  }

  return (
    <div
      className={cn(
        'overflow-y-auto rounded-md bg-white py-2 text-sm shadow-xl',
        {
          'bg-green-200': availability.state === AVAILABILITY_STATES.available,
          'bg-orange-200': availability.state === AVAILABILITY_STATES.later,
          'bg-red-200':
            availability.state === AVAILABILITY_STATES.unavailable ||
            availability.quantity <
              selectedProduct.allowedQuantity.quantityInterval,
        }
      )}
    >
      <div className="flex items-center">
        <CircularProgressbar
          value={availability.percentage}
          strokeWidth={14}
          className={cn(
            'dark ml-4 h-6 w-6',
            availability.quantity <
              selectedProduct.allowedQuantity.quantityInterval
              ? AVAILABILITY_STATES.unavailable
              : availability.state
          )}
        />
        <AvailabilityDetails
          availability={availability}
          allowedQuantity={selectedProduct.allowedQuantity}
        />
      </div>
      {availability.state !== AVAILABILITY_STATES.unavailable && (
        <AvailabilityPeriodVisualizer
          id={availability.id}
          periods={availability.availabilities}
        />
      )}
    </div>
  )
}

export default AvailabilityCheckDialog
