import React from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Trans, t } from '@lingui/macro'
import { useForm } from 'react-hook-form'
import Spinner from 'react-svg-spinner'
import * as Yup from 'yup'

import useUpdateCart from 'src/hooks/data/useUpdateCart'

import Button from 'src/components/Button'

import Input from 'src/forms/components/Input'

type MinimalCart = {
  id: string
  name: string
  companyUserReference: string
}

const schema = Yup.object({
  cartId: Yup.string().required(),
  companyUserId: Yup.string().required(),
  name: Yup.string()
    .required()
    .label(t`Name`),
}).required()

type RenameCartFormValues = Yup.InferType<typeof schema>

type RenameCartFormProps = {
  cart: MinimalCart
  onSuccess?: (formValues: RenameCartFormValues) => void
  onCancel?: () => void
  onError?: (error: any) => void
}

const RenameCartForm: React.FC<RenameCartFormProps> = ({
  cart,
  onSuccess,
  onCancel,
  onError,
}) => {
  const { mutateAsync: updateCart } = useUpdateCart()

  const { register, handleSubmit, formState } = useForm<RenameCartFormValues>({
    resolver: yupResolver(schema),
    values: {
      cartId: cart.id,
      companyUserId: cart.companyUserReference,
      name: cart.name,
    },
    mode: 'all',
  })

  const onSubmit = async (formValues: RenameCartFormValues): Promise<void> => {
    try {
      await updateCart({
        companyUserId: formValues.companyUserId,
        cartId: formValues.cartId,
        updaterFn: (draft: any) => {
          draft.name = formValues.name
        },
      } as any)
      onSuccess?.(formValues)
    } catch (error) {
      onError?.(error)
    }
  }

  const { isSubmitting, isValid, errors } = formState

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
      <label className="block">
        <div className="mb-2 text-sm">
          <Trans>Cart name</Trans>
        </div>
        <Input
          {...register('name')}
          error={errors.name?.message}
          disabled={isSubmitting}
        />
      </label>
      <input type="hidden" {...register('cartId')} />
      <input type="hidden" {...register('companyUserId')} />
      <div className="mt-8 flex space-x-4">
        <Button
          type="reset"
          onClick={onCancel}
          className="flex-grow"
          size="xl"
          variant="none"
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          type="submit"
          disabled={isSubmitting || !isValid}
          className="flex w-full flex-grow items-center justify-center"
          size="xl"
          variant="purple"
        >
          {isSubmitting ? (
            <Spinner size="1.7rem" color="white" />
          ) : (
            <Trans>Rename cart</Trans>
          )}
        </Button>
      </div>
    </form>
  )
}

export default RenameCartForm
