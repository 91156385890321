import { Trans } from '@lingui/macro'

import useDefaultCompanyUserPermissions from 'src/hooks/utils/useDefaultCompanyUserPermissions'

import { H1 } from 'src/components/Header'

import ClaimCartDialogButton from 'src/pages/Carts/Overview/components/DialogButton/ClaimCartDialogButton'
import CreateNewCartDialogButton from 'src/pages/Carts/Overview/components/DialogButton/CreateNewCartDialogButton'
import UploadCartDialogButton from 'src/pages/Carts/Overview/components/DialogButton/UploadCartDialogButton'
import UploadRetailerCartDialogButton from 'src/pages/Carts/Overview/components/DialogButton/UploadRetailerCartDialogButton'

const PageHeader = () => {
  const { Can } = useDefaultCompanyUserPermissions()

  return (
    <div className="flex items-center space-y-4 lg:flex-row lg:items-start lg:space-y-0">
      <H1>
        <Trans>My Carts</Trans>
      </H1>
      <div className="flex flex-grow justify-center lg:justify-end">
        <div className="flex flex-col space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0">
          <Can I="upload" a="cart">
            <UploadCartDialogButton />
          </Can>
          <Can I="uploadAsRetailer" a="cart">
            <UploadRetailerCartDialogButton />
          </Can>
          <Can I="claim" a="cart">
            <ClaimCartDialogButton />
          </Can>

          <CreateNewCartDialogButton />
        </div>
      </div>
    </div>
  )
}

export default PageHeader
