import { useMutation } from 'react-query'
import queryClient from 'src/react-query-client'

import { postClaimCart } from 'src/api/carts'

const useClaimCart = () =>
  useMutation(postClaimCart, {
    onSuccess: () => {
      queryClient.invalidateQueries('carts-infinite')
      queryClient.invalidateQueries('carts')
    },
  })

export default useClaimCart
