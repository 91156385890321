import memoize from 'lodash.memoize'
import * as Yup from 'yup'

type SetValueOptions = {
  shouldValidate: boolean
  shouldDirty: boolean
  shouldTouch: boolean
}

export const defaultSetValueOptionsForRerendering: Readonly<SetValueOptions> = {
  shouldValidate: true,
  shouldDirty: true,
  shouldTouch: true,
}

export const getFieldsMetaBySchema = memoize(
  <T extends object>(schema: Yup.ObjectSchema<T>) =>
    Object.keys(schema.fields).reduce<{
      [_k in keyof T]?: Record<string, { required: boolean }>
    }>((fieldMeta, fieldKey) => {
      const field = schema.fields[fieldKey as keyof T]

      if (!(field instanceof Yup.Schema)) {
        return {
          ...fieldMeta,
          [fieldKey]: {
            required: false,
          },
        }
      }

      return {
        ...fieldMeta,
        [fieldKey]: {
          required:
            field
              .describe()
              .tests.findIndex(({ name }) => name === 'required') !== -1,
        },
      }
    }, {}),
  (schema) => Object.keys(schema.fields)
)

export type Address = {
  id: string
  name1: string
  name2?: string | null
  address1?: string
  address2?: string | null
  zipCode?: string
  city?: string
  country: string
}
