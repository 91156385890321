import React from 'react'

import cn from '@meltdownjs/cn'
import debounce from 'lodash.debounce'
import 'regenerator-runtime/runtime'

type TableSearchProps = {
  value?: string
  onChange: (value: string) => void
  placeholder?: string
  className?: string
}

const TableSearch: React.FC<TableSearchProps> = ({
  value = '',
  onChange,
  placeholder = '',
  className,
}) => {
  const [searchValue, setSearchValue] = React.useState(value)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onInputChange = React.useCallback(debounce(onChange, 500), [])

  return (
    <input
      className={cn(
        'input-search flex h-8 flex-auto items-center overflow-hidden rounded border border-transparent bg-white placeholder-gray-400 hover:border-gray-300',
        className
      )}
      value={searchValue}
      onChange={({ target }) => {
        setSearchValue(target.value)
        onInputChange(target.value)
      }}
      type="search"
      placeholder={placeholder}
    />
  )
}

export default TableSearch
