import React from 'react'
import { useParams } from 'react-router-dom'

import { t } from '@lingui/macro'
import cn from '@meltdownjs/cn'
import { debounce } from 'lodash'

import useCart from 'src/hooks/data/useCart'
import useDebouncedCartUpdate from 'src/hooks/data/useDebouncedCartUpdate'

import pushDataLayer from 'src/lib/pushDataLayer'

const SearchInput: React.FC<Partial<HTMLInputElement>> = ({ className }) => {
  const { cartId } = useParams()
  const updateCart = useDebouncedCartUpdate(cartId)
  const { data } = useCart(cartId, {
    notifyOnChangeProps: 'tracked',
    select: ({ filters }: any) => ({
      searchTerm: filters.searchOptions.searchTerm,
    }),
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pushDataLayerDebounced = React.useCallback(
    debounce(pushDataLayer, 500),
    []
  )

  React.useEffect(() => {
    if (!data?.searchTerm) {
      setSearchTerm('')
    }
  }, [data?.searchTerm])

  const [searchTerm, setSearchTerm] = React.useState(data.searchTerm)

  const updateSearchTerm = (searchTerm: string) =>
    updateCart({
      updaterFn: (draft: any) => {
        draft.filters.searchOptions.searchTerm = searchTerm
      },
    })

  const onSearchTermChange = React.useCallback(
    ({ target: { value } }: { target: { value: string } }) => {
      setSearchTerm(value)
      updateSearchTerm(value)
      pushDataLayerDebounced({
        event: 'product_search',
        search_term: value,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <input
      className={cn(
        'input-search flex h-10 min-w-[18.75rem] flex-auto items-center overflow-hidden rounded border bg-white placeholder-gray-800 hover:border-gray-300 lg:h-8 lg:max-w-[13rem]',
        className
      )}
      value={searchTerm || ''}
      onChange={onSearchTermChange}
      type="search"
      placeholder={t`Search...`}
    />
  )
}

export default SearchInput
