import React from 'react'

import debounce from 'debounce-async'

import useCart from './useCart'
import useUpdateCart from './useUpdateCart'

const useDebouncedCartUpdate = (cartId) => {
  const { data } = useCart(cartId, {
    notifyOnChangeProps: 'tracked',
    select: ({ companyUserReference }) => ({
      companyUserReference,
    }),
  })
  const { mutateAsync: mutateCart } = useUpdateCart(
    data.companyUserReference,
    cartId
  )

  const updateCartDebounced = debounce(
    (...props) => Promise.resolve(mutateCart(...props)),
    1000
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateCart = React.useCallback(updateCartDebounced, [])

  return updateCart
}

export default useDebouncedCartUpdate
