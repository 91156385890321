import React, { Fragment } from 'react'

import { t } from '@lingui/macro'

import {
  AVAILABILITY_STATES,
  makeAvailability,
} from 'src/hooks/data/usePaginatedAvailabilities'

import useObserver from 'src/hooks/utils/useObserver'

type AvailabilityProps = {
  product: any
  availabilityObserver: any
}

const Availability: React.FC<AvailabilityProps> = ({
  product,
  availabilityObserver,
}) => {
  const availability = useObserver(
    availabilityObserver,
    (data) => {
      if (data === undefined) {
        return false
      }
      const periods = data[product.id] || []
      const availability = makeAvailability(periods)
      return availability
    },
    [product.id]
  )

  if (availability.state === AVAILABILITY_STATES.unavailable) {
    return <Fragment></Fragment>
  }

  return (
    <Fragment>
      <div>
        <span className="font-medium">{t`Available from ${i18n.date(
          availability.earliestDeliveryDate,
          {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
          }
        )}`}</span>
      </div>
    </Fragment>
  )
}

export default Availability
