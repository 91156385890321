import { useQuery } from 'react-query'

import { companyUserSearch } from 'src/api/company-user-search'

import { UseDefaultCompanyUserProps } from 'src/hooks/data/useDefaultCompanyUser/types'

export const MAIN_QUERY_KEY = 'company-user-search'

export const getCompanyUserSearchQueryOptions = <TSelect>({
  args,
  options,
}: UseDefaultCompanyUserProps<TSelect>) => ({
  queryKey: [MAIN_QUERY_KEY, args],
  queryFn: () => companyUserSearch(args),
  staleTime: 1000 * 60 * 60 * 8,
  cacheTime: 1000 * 60 * 60 * 24,
  select: (data: any) => {
    return data
  },
  ...options,
})

const useCompanyUserSearch = <TSelect>(
  props: UseDefaultCompanyUserProps<TSelect> = {}
) =>
  useQuery<any, unknown, TSelect, string[]>(
    getCompanyUserSearchQueryOptions<TSelect>(props)
  )

export default useCompanyUserSearch
