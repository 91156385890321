import React from 'react'

import cn from '@meltdownjs/cn'

export enum SIZE {
  /* eslint-disable */
  xl = 'button-size-xl',
  md = 'button-size-md',
  sm = 'button-size-sm',
  /* eslint-enable */
}

export enum VARIANTS {
  /* eslint-disable */
  none = 'button-color-none',
  purple = 'button-color-purple',
  gray = 'button-color-gray',
  red = 'button-color-red',
  critical = 'button-color-critical',
  green = 'button-color-green',
  /* eslint-enable */
}
type TVariants = keyof typeof VARIANTS

type TSize = keyof typeof SIZE

interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  size?: TSize
  variant?: TVariants
}
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant = 'gray', size = 'md', className, children, ...props }, ref) => {
    const colorClass = VARIANTS[variant] || VARIANTS.gray
    const sizeClass = SIZE[size] || SIZE.md

    return (
      <button
        ref={ref}
        className={cn(colorClass, sizeClass, className)}
        {...props}
      >
        {children}
      </button>
    )
  }
)

export default Button
