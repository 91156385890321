import React from 'react'

export const DialogContext = React.createContext<ContextType>(null)
const useDialogContext = () => {
  const context = React.useContext(DialogContext)

  if (context == null) {
    throw new Error('Dialog components must be wrapped in <Dialog />')
  }

  return context
}

export default useDialogContext
