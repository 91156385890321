import { Trans } from '@lingui/macro'

const ExplanationBlock = () => (
  <div className="space-y-2 rounded bg-yellow-200 p-3 text-sm font-normal text-gray-700">
    <span className="font-semibold">
      <Trans>Explanations on formatting</Trans>
    </span>
    <ul className="m-0 list-inside list-disc pl-0">
      <li>
        <span className="font-medium">
          <Trans>debtorNumber</Trans>
        </span>
        {' = '}
        <span>
          <Trans>Debtor number</Trans>
        </span>
      </li>
      <li>
        <span className="font-medium">
          <Trans>budget</Trans>
        </span>
        {' = '}
        <span>
          <Trans>New initial weekly Budget (in cents)</Trans>
        </span>
      </li>
    </ul>
  </div>
)

export default ExplanationBlock
