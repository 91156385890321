import { Trans } from '@lingui/macro'

import { SearchIcon } from '@heroicons/react/solid'

import ResetFiltersButton from 'src/pages/Carts/Cart/ResetFiltersButton'

const NoResultWithActiveSearch = () => (
  <div className="flex h-full w-full flex-col items-center justify-center space-y-6 bg-white px-4 pb-12 pt-8">
    <SearchIcon className="w-16 text-gray-200" />
    <div className="text-center">
      <div className="text-lg font-medium">
        <Trans>Your search seems to have no matches.</Trans>
      </div>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col">
          <span className="text-gray-500">
            <Trans>
              With a successful search you will see results. You may have to
              change your search.
            </Trans>
          </span>
          <span className="text-gray-500">
            <Trans>
              Check whether filters are activated and reset them to get more
              results.
            </Trans>
          </span>
        </div>
        <div>
          <ResetFiltersButton
            resetSearchTerm={false}
            className="button-color-purple"
          />
        </div>
      </div>
    </div>
  </div>
)

export default NoResultWithActiveSearch
