import { Trans } from '@lingui/macro'

import { ErpOrderCancellation } from 'src/api/erp-order-cancellation/erpOrderCancellationResponse'

import {
  Dialog,
  DialogContainer,
  DialogContent,
  DialogContextType,
  DialogDescription,
  DialogHeading,
  DialogXButton,
} from 'src/components/Dialog'

import RejectCancellationRequestForm from 'src/forms/RejectCancellationRequestForm'

type RejectCancellationRequestDialogContainerProps = {
  cancellationRequest: ErpOrderCancellation
  onClose: () => void
}

const RejectCancellationRequestDialogContainer: React.FC<
  RejectCancellationRequestDialogContainerProps
> = ({ cancellationRequest, onClose }) => (
  <Dialog initialOpen onClose={onClose}>
    <DialogContainer>
      <DialogXButton />
      <DialogHeading>
        <Trans>Reject cancellation request</Trans>
      </DialogHeading>
      <DialogDescription>
        <Trans>
          Are you sure you want to reject this cancellation request?
        </Trans>
      </DialogDescription>
      <DialogContent>
        {
          ((context: DialogContextType) => {
            const dismiss = () => context.setOpen(false)
            return (
              <RejectCancellationRequestForm
                cancellationRequest={cancellationRequest}
                onCancel={dismiss}
              />
            )
          }) as any
        }
      </DialogContent>
    </DialogContainer>
  </Dialog>
)

export default RejectCancellationRequestDialogContainer
