import React from 'react'

import { useId } from '@floating-ui/react'
import cn from '@meltdownjs/cn'

import useDialogContext from 'src/components/Dialog/useDialogContext'
import { H3 } from 'src/components/Header'

export const DialogHeading = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLProps<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => {
  const { setLabelId } = useDialogContext()
  const id = useId()

  // Only sets `aria-labelledby` on the Dialog root element
  // if this component is mounted inside it.
  React.useLayoutEffect(() => {
    setLabelId(id)
    return () => setLabelId(undefined)
  }, [id, setLabelId])

  return (
    <H3 className={cn(className, 'my-4')} {...props} ref={ref} id={id}>
      {children}
    </H3>
  )
})
