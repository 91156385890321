export const PATH_PATTERN_ROOT = '/:companyId'
export const PATH_PATTERN_CARTS = '/:companyId/carts/*'
export const PATH_PATTERN_COMPANY_PROFILE = '/:companyId/company/profile/*'
export const PATH_PATTERN_USERS = '/:companyId/company/users/*'
export const PATH_PATTERN_ADDRESSES = '/:companyId/company/addresses/*'
export const PATH_PATTERN_ORDERS = '/:companyId/documents/orders/*'
export const PATH_PATTERN_DELIVERY_NOTES =
  '/:companyId/documents/delivery-notes/*'
export const PATH_PATTERN_INVOICES = '/:companyId/documents/invoices/*'
export const PATH_PATTERN_OUTSTANDING = '/:companyId/documents/outstanding/*'
export const PATH_PATTERN_SERVICE = '/:companyId/service/*'
export const PATH_PATTERN_DOWNLOADS = '/:companyId/downloads/*'
export const PATH_PATTERN_COMPANY_ASSIGNMENT =
  '/:companyId/tools/company-assignment/*'
export const PATH_PATTERN_PRODUCT_LISTS = '/:companyId/tools/product-lists/*'
export const PATH_PATTERN_REPRESENTATIONS =
  '/:companyId/tools/representations/*'
export const PATH_PATTERN_BUDGET = '/:companyId/tools/budget/*'
export const PATH_PATTERN_CANCELLATION_REQUESTS =
  '/:companyId/tools/cancellation-requests'

export const DEFAULT_PATH_PATTERN_ROOT = '/:companyId'
export const DEFAULT_PATH_PATTERN_CARTS = '/:companyId/carts'
export const DEFAULT_PATH_PATTERN_MEDIA = '/:companyId/downloads/media'
export const DEFAULT_PATH_PATTERN_SERVICE = '/:companyId/service'
export const DEFAULT_PATH_PATTERN_USERS = '/:companyId/company/users'
