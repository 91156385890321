import { privateClient } from './api-client'

export const postDefaultCompanyUser = async (companyUserReference: string) =>
  privateClient.post('business-on-behalf', {
    json: {
      data: {
        type: 'business-on-behalf',
        attributes: {
          companyUserReference,
        },
      },
    },
  })
