import React from 'react'
import { useParams } from 'react-router-dom'

import { i18n } from '@lingui/core'

import useCart from 'src/hooks/data/useCart'
import useDebouncedCartUpdate from 'src/hooks/data/useDebouncedCartUpdate'

import Filter from 'src/components/Filter'

const Filters = React.memo(({ filterDefinitions }) => {
  const { cartId } = useParams()
  const updateCart = useDebouncedCartUpdate(cartId)

  const { data } = useCart(cartId, {
    notifyOnChangeProps: 'tracked',
    select: ({ companyUserReference, filters }) => ({
      companyUserReference,
      filters: filters.searchOptions.filters || {},
    }),
  })

  const filterEntries = Object.entries(filterDefinitions).filter(
    ([_, { options }]) => options.length > 0
  )

  return filterEntries.map(([name, { label, options, config }], index) => {
    const filterValues = data.filters[name]

    return (
      <div className="mb-2 mr-4 mt-2">
        <Filter
          key={index}
          value={filterValues}
          label={label}
          options={options}
          config={config}
          onChange={(value) => {
            updateCart({
              updaterFn: (draft) => {
                draft.filters.searchOptions.filters = {
                  ...draft.filters.searchOptions.filters,
                  [name]: value,
                }
                draft.filters.locale = i18n.locale
              },
            })
          }}
        />
      </div>
    )
  })
})

export default Filters
