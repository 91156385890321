import { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Trans, t } from '@lingui/macro'
import { format, isAfter, parseISO } from 'date-fns'
import { useForm } from 'react-hook-form'
import Spinner from 'react-svg-spinner'
import * as Yup from 'yup'

import { CompanyUser } from 'src/api/company-user-search/types'

import useCreateCartWithItems from 'src/hooks/data/useCreateCartWithItems'
import useDefaultCompanyUser from 'src/hooks/data/useDefaultCompanyUser'

import useDefaultCompanyUserPermissions from 'src/hooks/utils/useDefaultCompanyUserPermissions'

import Button from 'src/components/Button'
import FormErrorMessage from 'src/components/FormErrorMessage'

import csvExampleFile from 'src/assets/examples/cart-example.csv'
import xlsxExampleFile from 'src/assets/examples/cart-example.xlsx'
import { defaultSetValueOptionsForRerendering } from 'src/forms'
import ExplanationBlock from 'src/forms/UploadRetailerCartForm/components/ExplentationBlock'
import DropZone from 'src/forms/components/DropZone'
import { ParsingError } from 'src/forms/components/DropZone/error'

type Row = {
  sku: string
  qty: number
  deliveryDate: Date
}

type CartItem = {
  quantity: number
}

type CartItems = Record<string, CartItem>

type Cart = {
  id?: string
  name: string
  lineItems: CartItems
}

const mapRowsToCartItems = (rows: Row[]): CartItems => {
  const cartItems: CartItems = {}

  for (const row of rows) {
    const key = `${row.sku}.${format(row.deliveryDate, 'yyyy-MM-dd')}`

    if (!cartItems[key]) {
      cartItems[key] = { quantity: row.qty }
      continue
    }

    cartItems[key].quantity += +row.qty
  }

  return cartItems
}

const schema = Yup.object({
  companyUserId: Yup.string().required(),
  name: Yup.string().required(),
  lineItems: Yup.object()
    .required(t`Please upload a excel file`)
    .nullable()
    .test({
      name: 'minItems',
      message: t`Something went wrong. Please check your excel file for errors`,
      test: (value: any) => value && Object.keys(value).length > 0,
    })
    .test({
      name: 'validQuantity',
      message: (params) => {
        const combinations: string[] = []

        for (const key of Object.keys(params.value)) {
          if (params.value[key].quantity >= 0) {
            continue
          }

          combinations.push(
            key.replace(/^(.+)\.(\d{4,4}-\d{2,2}-\d{2,2})$/, '$1 / $2')
          )
        }

        return t`The quantity for the following combinations are not valid: ${combinations.join(
          ', '
        )}`
      },
      test: (value: any) =>
        value &&
        Object.values(value).findIndex(
          (lineItem: any) => lineItem.quantity < 0
        ) === -1,
    })
    .default({}),
}).required()

type UploadRetailerCartFormValues = Yup.InferType<typeof schema>

type UploadRetailerCartFormProps = {
  onSuccess?: (cart: Cart) => void
  onError?: (error: any) => void
  onCancel?: () => void
}

const UploadRetailerCartForm: FC<UploadRetailerCartFormProps> = ({
  onSuccess,
  onError,
  onCancel,
}) => {
  const { data: defaultCompanyUser } = useDefaultCompanyUser<CompanyUser>()
  const { ability } = useDefaultCompanyUserPermissions()

  const { register, setValue, handleSubmit, formState } =
    useForm<UploadRetailerCartFormValues>({
      resolver: yupResolver(schema),
    })

  const {
    mutateAsync: createCart,
  }: { mutateAsync: (_variables: any) => Promise<any> } =
    useCreateCartWithItems()

  useEffect(() => {
    register?.('companyUserId')
    register?.('name')
    register?.('lineItems')
  }, [register])

  const canUploadCart = ability.can('uploadAsRetailer', 'cart')

  useEffect(() => {
    setValue(
      'companyUserId',
      defaultCompanyUser?.companyUserReference || '',
      defaultSetValueOptionsForRerendering
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCompanyUser?.companyUserReference])

  const onSubmit = async (
    formValues: UploadRetailerCartFormValues
  ): Promise<void> => {
    try {
      const cart: Cart = await createCart({
        name: formValues.name,
        companyUserId: formValues.companyUserId,
        lineItems: formValues.lineItems,
      })

      onSuccess?.(cart)
    } catch (error) {
      onError?.(error)
    }
  }

  const { isValid, isSubmitting } = formState

  const onDropSuccess = (rows: Row[], file: File): void => {
    const cartItems = mapRowsToCartItems(rows)
    setValue('lineItems', cartItems, defaultSetValueOptionsForRerendering)
    setValue('name', file.name, defaultSetValueOptionsForRerendering)
  }

  const onDropError = (_parsingErrors: ParsingError[]): void => {
    setValue('lineItems', {}, defaultSetValueOptionsForRerendering)
  }

  const onDeleteSuccess = (): void => {
    setValue('lineItems', {}, defaultSetValueOptionsForRerendering)
    setValue('name', '', defaultSetValueOptionsForRerendering)
  }

  const rowSchema = Yup.tuple([
    Yup.string()
      .label(t`SKU`)
      .required(),
    Yup.number()
      .label(t`Quantity`)
      .positive()
      .integer()
      .required(),
    Yup.string()
      .matches(
        /\d{4}-((0[1-9])|(1[0-2]))-((0[1-9])|(1|2[0-9])|(3[0-1]))/,
        t`Invalid date format. Please enter the delivery date in ISO 8601 format (YYYY-mm-dd).`
      )
      .test(
        'is-gteq-today',
        t`Delivery date must be greater then eqaul today.`,
        (value: string | undefined) =>
          value !== undefined && isAfter(parseISO(value), new Date())
      )
      .required(),
  ]).required()

  const parsedRowMapper = (row: any[]): Row => {
    const [sku, qty, deliveryDate] = rowSchema.validateSync(row)

    return { sku, qty, deliveryDate: parseISO(deliveryDate) }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
      <div className="space-y-2">
        <p>
          <Trans>
            Here you can upload an Excel- / CSV-File to quickly generate a cart.
            Please follow our example file when creating your file.
          </Trans>
        </p>
        <ExplanationBlock />
        <p className="flex space-x-2 text-sm text-gray-700">
          <span>
            <a
              className="underline"
              href={xlsxExampleFile}
              target="_blank"
              rel="noopener noreferrer"
              download="cart-example.xlsx"
            >
              <Trans>XLSX-Example</Trans>
            </a>
          </span>
          <span>|</span>
          <span>
            <a
              className="underline"
              href={csvExampleFile}
              target="_blank"
              rel="noopener noreferrer"
              download="cart-example.csv"
            >
              <Trans>CSV-Example</Trans>
            </a>
          </span>
        </p>
      </div>

      <input type="hidden" {...register('companyUserId')} />

      {!canUploadCart && (
        <FormErrorMessage
          errorMessage={
            <Trans>
              You do not have the permission to upload a cart for this company.
              For further questions, please contact our{' '}
              <Link
                to="/service/contact"
                className="font-medium underline hover:text-gray-600 active:text-gray-600"
              >
                customer service
              </Link>
            </Trans>
          }
        />
      )}

      {canUploadCart && (
        <DropZone
          onDeleteSuccess={onDeleteSuccess}
          onDropSuccess={onDropSuccess}
          onDropError={onDropError}
          rowMapper={parsedRowMapper}
        />
      )}
      <div className="mt-8 flex space-x-4">
        <Button
          type="reset"
          onClick={onCancel}
          className="flex-grow"
          size="xl"
          variant="none"
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          type="submit"
          disabled={isSubmitting || !isValid}
          className="flex w-full flex-grow items-center justify-center"
          size="xl"
          variant="purple"
        >
          {isSubmitting ? (
            <Spinner size="1.7rem" color="white" />
          ) : (
            <Trans>Create cart</Trans>
          )}
        </Button>
      </div>
    </form>
  )
}

export default UploadRetailerCartForm
