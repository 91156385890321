import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import { Trans, t } from '@lingui/macro'
import Spinner from 'react-svg-spinner'

import { ChevronRightIcon } from '@heroicons/react/outline'

import useCompany from 'src/hooks/data/useCompany'
import useCompanyUser from 'src/hooks/data/useCompanyUser'

import useDefaultCompanyUserPermissions from 'src/hooks/utils/useDefaultCompanyUserPermissions'

import Currency from 'src/components/Currency'

import ListItemContextMenu from 'src/pages/Carts/Overview/components/ListItemContextMenu'
import { Actions } from 'src/pages/Carts/Overview/components/types'

import randomColorForString from 'src/lib/randomColorForString'
import twoLetterIdentifier from 'src/lib/twoLetterIdentifier'

type ListItemProps = {
  cart: any
  actions: Actions
  showRelatedCompany?: boolean
}

const ListItem: React.FC<ListItemProps> = ({
  cart,
  cart: { id, name, totals, currency, originalCustomer },
  actions,
  showRelatedCompany = false,
}) => {
  const navigate = useNavigate()
  const { ability } = useDefaultCompanyUserPermissions()

  const { data: companyUser } = useCompanyUser(cart?.companyUserReference)
  const {
    data: company,
    isLoading: companyIsLoading,
    isSuccess: companyIsSuccess,
  } = useCompany(companyUser?.companyId, {
    enabled: showRelatedCompany && !!companyUser?.companyId,
  })

  const isClaimed = !!cart.originalCustomer
  const canCloneCart = ability.can('clone', 'cart')
  const canExportCart = ability.can('export', 'cart')

  return (
    <Fragment>
      <div
        onClick={() => {
          navigate(`/${companyUser?.companyId}/carts/${id}`)
        }}
        className="list-item-shadow max-h-26 group relative mb-4 flex min-h-[5rem] flex-grow transform cursor-pointer items-center space-x-4 space-x-4 rounded-md bg-white px-4 transition-all duration-150 hover:scale-[0.99] hover:rounded-lg"
      >
        <div
          className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full text-white"
          style={{
            backgroundColor: randomColorForString(name, id),
          }}
        >
          {companyIsLoading || !companyIsSuccess ? (
            <Spinner size="1.25rem" color="currentColor" />
          ) : (
            twoLetterIdentifier(name)
          )}
        </div>
        <div className="flex w-full flex-col py-2 xl:flex-row">
          <div className="text-base leading-snug">
            <div className="space-x-2 font-medium">
              <span>{name}</span>
              {isClaimed && (
                <span
                  className="rounded-sm bg-blue-400 px-1 py-px text-xs tracking-wide text-white"
                  title={t`Original owner: ${originalCustomer?.email}`}
                >
                  <Trans>Claimed</Trans>
                </span>
              )}
            </div>
            {showRelatedCompany && (
              <div className="text-sm text-gray-600">
                {companyIsLoading || !companyIsSuccess ? (
                  <Spinner size="1.25rem" color="currentColor" />
                ) : (
                  `${company?.debtorNumber} ${company?.name}`
                )}
              </div>
            )}
          </div>
          <div className="flex-grow" />
          <div className="flex items-center">
            <Currency
              cents={!!totals ? totals.subtotal : 0}
              currencyIsoCode={currency}
            />
          </div>
        </div>

        <ListItemContextMenu
          cart={cart}
          canCloneCart={canCloneCart}
          canExportCart={canExportCart}
          isClaimed={isClaimed}
          actions={actions}
        />

        <ChevronRightIcon className="w-8 text-gray-400 transition-colors duration-150 group-hover:text-gray-800" />
      </div>
    </Fragment>
  )
}

export default ListItem
