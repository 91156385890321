import React from 'react'

import { useVirtualizer } from '@tanstack/react-virtual'

import ListItem from 'src/pages/Carts/Overview/components/ListItem'
import { Actions } from 'src/pages/Carts/Overview/components/types'

type CartsTableProps = {
  itemActions: Actions
  hasNextPage?: boolean
  fetchNextPage: () => void
  isFetchingNextPage: boolean
  height: number
  items: any[]
  showRelatedCompany?: boolean
}

const CartsTable: React.FC<CartsTableProps> = ({
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  items,
  height,
  itemActions,
  showRelatedCompany,
}) => {
  const parentRef = React.useRef(null)
  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? items?.length + 1 : items?.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 40,
    overscan: 6,
  })

  const virtualItems = rowVirtualizer.getVirtualItems()

  React.useEffect(
    () => {
      const [lastItem] = [...virtualItems].reverse()

      if (!lastItem) {
        return
      }
      if (
        lastItem.index >= items?.length - 1 &&
        hasNextPage &&
        !isFetchingNextPage
      ) {
        fetchNextPage()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasNextPage, fetchNextPage, isFetchingNextPage, virtualItems]
  )

  return (
    <div className="overflow-y-auto" style={{ height }} ref={parentRef}>
      <ul
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
        }}
        className="relative w-full text-sm"
      >
        {virtualItems.map((virtualRow) => {
          const isLoaderRow = virtualRow.index > items?.length - 1
          const cart = items?.[virtualRow.index]

          return (
            <li
              key={virtualRow.index}
              data-index={virtualRow.index}
              ref={(node) => {
                if (!!node) {
                  rowVirtualizer.measureElement(node)
                }
              }}
              className="absolute left-0 top-0 flex w-full flex-grow cursor-pointer items-center overflow-y-visible focus:ring-0"
              style={{
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              {isLoaderRow ? (
                hasNextPage ? (
                  'Loading more...'
                ) : (
                  'Nothing more to load'
                )
              ) : (
                <ListItem
                  cart={cart}
                  actions={itemActions}
                  showRelatedCompany={showRelatedCompany}
                />
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default CartsTable
