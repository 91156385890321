import React from 'react'
import { InfiniteQueryObserver } from 'react-query'
import queryClient from 'src/react-query-client'

import { t } from '@lingui/macro'
import cn from '@meltdownjs/cn'
import debounce from 'lodash.debounce'
import { CircularProgressbar } from 'react-circular-progressbar'

import usePaginatedAvailabilities, {
  AVAILABILITY_STATES,
  makeAvailability,
} from 'src/hooks/data/usePaginatedAvailabilities'
import usePaginatedProducts from 'src/hooks/data/usePaginatedProducts'

import useObserver from 'src/hooks/utils/useObserver'

import Img from 'src/components/Img'
import InfiniteCombobox from 'src/components/InfiniteCombobox'
import { LoadingPlaceholder } from 'src/components/NoDataPlaceholder'

const SelectItem = React.memo(
  ({ item, itemProps, isHighlighted, availabilityObserver }) => {
    const availability = useObserver(
      availabilityObserver,
      (data) => {
        if (data === undefined) {
          return false
        }
        const periods = data[item.id] || []
        const availability = makeAvailability(periods)
        return availability
      },
      [item.id]
    )

    const colors = React.useMemo(
      () =>
        Array.isArray(item.colors) ? item.colors : item.colors.split?.(','),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    const details = React.useMemo(
      () =>
        [
          ...(item.style ? [item.style] : []),
          ...(item.model ? [item.model] : []),
          ...(item.size ? [item.style] : []),
          ...(colors.length > 0 ? [colors.join(' | ')] : []),
        ].join(' • '),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    return (
      <div
        className={cn(
          'flex h-12 cursor-pointer items-center space-x-4 px-4',
          isHighlighted && 'bg-gray-200'
        )}
        {...itemProps}
      >
        <div className="relative h-10 w-10">
          <CircularProgressbar
            value={availability.percentage}
            strokeWidth={10}
            className={cn(
              'absolute left-0 top-0 z-10 h-10 w-10',
              availability.quantity < item.allowedQuantity.quantityInterval
                ? AVAILABILITY_STATES.unavailable
                : availability.state
            )}
          />

          <div className="absolut left-0 top-0 z-0 h-10 w-10 p-1">
            <Img
              className=" rounded-full border bg-white object-cover"
              src={item.thumbnail}
              alt=""
              spinnerSize="50%"
              spinnerThickness={2}
              placeholderClassName="w-2/3"
            />
          </div>
        </div>
        <div className="flex flex-col justify-center truncate">
          <span className="truncate font-medium" title={item.abstractName}>
            <span>{item.id}</span>
            <span className="text-gray-500">{' • '}</span>
            <span className="font-light">{item.abstractName}</span>
          </span>
          <div className="truncate text-xs text-gray-500">{details}</div>
        </div>
      </div>
    )
  }
)

const InfiniteProductSelect = ({ companyUserReference, channel, onChange }) => {
  const [inputValue, setInputValue] = React.useState('')

  const setInputValueDebounced = debounce(setInputValue, 1000)

  const {
    data: availablityData,
    fetchNextPage: fetchNextAvailabilitiesPage,
    isLoading: isLoadingAvailabilities,
    isSuccess: isSuccessAvailabilities,
  } = usePaginatedAvailabilities({ companyUserReference, channel })

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isSuccess,
    isLoading: isLoadingProducts,
  } = usePaginatedProducts(
    {
      companyUserReference,
      searchTerm: inputValue,
    },
    {
      onSuccess: ({ pages }) => {
        const products = pages[pages.length - 1].abstractProducts
        fetchNextAvailabilitiesPage({
          pageParam: products.map(({ id }) => id),
        })
      },
    }
  )

  React.useEffect(() => {
    if (isSuccessAvailabilities) {
      const fetchedProducts = data?.items?.map(({ id }) => id) || []
      const fetchedAvailabilities = [
        ...new Set(
          [].concat(...availablityData.pageParams.filter((data) => !!data))
        ),
      ]
      const missingAvailabilities = fetchedProducts.filter(
        (sku) => !fetchedAvailabilities.includes(sku)
      )

      if (missingAvailabilities.length > 0) {
        fetchNextAvailabilitiesPage({
          pageParam: missingAvailabilities,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, isSuccessAvailabilities])

  const availabilityObserver = React.useMemo(
    () =>
      new InfiniteQueryObserver(queryClient, {
        refetchOnMount: false,
        queryKey: [
          'availability-periods-paginated',
          companyUserReference,
          ...(channel ? [channel] : []),
        ],
        select: ({ pages }) =>
          pages.reduce((collection, page) => ({ ...collection, ...page }), {}),
      }),
    [companyUserReference, channel]
  )

  if (!isSuccess) {
    return null
  }
  const initialSelectedItem = data?.items?.length === 1 ? data.items?.[0] : null

  if (isLoadingProducts || isLoadingAvailabilities) {
    return <LoadingPlaceholder />
  }

  return (
    <InfiniteCombobox
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      hidden={data?.items?.length === 1 && !inputValue}
      initialSelectedItem={initialSelectedItem}
      isFetchingNextPage={isFetchingNextPage}
      items={data?.items}
      label={t`Choose a product`}
      onInputValueChange={setInputValueDebounced}
      onChange={(selectedItem) => {
        if (onChange) {
          onChange(selectedItem)
        }
      }}
      itemToString={({ id, abstractName }) => `${id} - ${abstractName}`}
      itemRenderer={({ item, ...props }) => (
        <SelectItem
          availabilityObserver={availabilityObserver}
          item={item}
          {...props}
          key={item.id}
        />
      )}
    />
  )
}

export default InfiniteProductSelect
