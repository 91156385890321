import React from 'react'

import { Trans, t } from '@lingui/macro'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { ErpOrderCancellation } from 'src/api/erp-order-cancellation/erpOrderCancellationResponse'
import { ErpOrderCancellationItems } from 'src/api/erp-order-cancellation/types'

import useUpdateOrderCancelation from 'src/hooks/data/useUpdateOrderCancellation'

import Currency from 'src/components/Currency'
import DataTable from 'src/components/DataTable'

import Footer from 'src/pages/Tools/CancellationRequest/components/Footer'
import QuantityInput from 'src/pages/Tools/CancellationRequest/components/QuantityInput'

import { openSuccessToast } from 'src/lib/toast'

type GetColumnsType = (
  hasPrices: boolean,
  register: any,
  setValue: any,
  watch: any
) => any[]

const schema = Yup.object({
  cancellationQuantity: Yup.object().shape({}).required(),
})
  .when(([value]: any[], schema: any) => {
    const cancellationQuantities = Object.keys(value).filter(
      (key) =>
        key.includes('cancellationQuantity.') && !schema._nodes.includes(key)
    )

    let cancellationQuantitySchema
    if (cancellationQuantities.length > 0) {
      cancellationQuantitySchema = cancellationQuantities.reduce(
        (newSchema, key) => ({
          ...newSchema,
          [key]: Yup.number()
            .required()
            .label(t`Cancellation quantity`),
        }),
        {}
      )
    }

    if (cancellationQuantities.length > 0) {
      return schema.shape({
        ...cancellationQuantitySchema,
      })
    }
  })
  .required()

type FormValues = Yup.InferType<typeof schema> & {
  cancellationQuantity: Record<string, number>
}

const getColumns: GetColumnsType = (hasPrices, register, setValue, watch) => [
  {
    accessor: 'position',
    cellProps: {
      style: {
        minWidth: 100,
      },
    },
    Header: () => (
      <div>
        <Trans>Position</Trans>
      </div>
    ),
    Cell: ({ value }: { value: string }) => (
      <span className="truncate">{value}</span>
    ),
  },
  {
    accessor: 'name',
    cellProps: {
      style: {
        minWidth: 450,
      },
    },
    Header: () => (
      <div>
        <Trans>Description</Trans>
      </div>
    ),
    Cell: ({ value }) => <span className="truncate">{value}</span>,
  },
  {
    accessor: 'sku',
    Header: () => (
      <div>
        <Trans>Article number</Trans>
      </div>
    ),
    Cell: ({ value }) => <span className="font-semibold">{value}</span>,
  },
  {
    accessor: 'quantityBeforeCancellation',
    Header: () => (
      <div className="text-right">
        <Trans>Quantity</Trans>
      </div>
    ),
    Cell: ({ value }: { value: string }) => (
      <span className="truncate text-right">{value}</span>
    ),
  },
  {
    id: 'cancellationQuantity',
    Header: () => (
      <div className="whitespace-normal">
        <Trans>Canceled</Trans>
      </div>
    ),
    Cell: ({
      row: {
        original: { cancellationQuantity, quantityBeforeCancellation, sku },
      },
    }: {
      row: {
        original: {
          cancellationQuantity: number
          quantityBeforeCancellation: number
          sku: string
        }
      }
    }) => {
      const inputName = `cancellationQuantity.${sku}`
      const currentValue = watch(inputName) || cancellationQuantity

      return (
        <div>
          <QuantityInput
            {...register(inputName, {
              value: cancellationQuantity,
              min: 0,
              max: quantityBeforeCancellation,
            })}
            maxValue={quantityBeforeCancellation}
            currentValue={currentValue}
            setValue={(value) =>
              setValue(inputName, value, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              })
            }
          />
        </div>
      )
    },
  },
  {
    accessor: 'unitPrice',
    Header: () => (
      <div className="text-right">
        <Trans>Purchase price per item</Trans>
      </div>
    ),
    Cell: ({ value }) => (
      <span className="truncate text-right">
        {value && <Currency cents={value} currencyIsoCode="EUR" />}
      </span>
    ),
  },
]

const CancelationForm = ({
  cancellationRequest,
  sortOptionKey,
  discardCancelation,
}: {
  cancellationRequest: ErpOrderCancellation
  sortOptionKey: string
  discardCancelation: () => void
}) => {
  const [submitStatus, setSubmitStatus] = React.useState('submit')
  const { setValue, handleSubmit, register, watch, formState } =
    useForm<FormValues>({
      mode: 'all',
    })

  let total = { value: 0, quantity: 0 }

  cancellationRequest.cancellationItems.map(function (item) {
    total.value = total.value + item.cancellationValue
    total.quantity = total.quantity + item.cancellationQuantity
  })

  const cancellationQuantities = watch('cancellationQuantity')

  const cancellationKeyValueMap =
    cancellationRequest.cancellationItems.reduce<any>(
      (obj, item) => ({ ...obj, [item.sku]: item }),
      {}
    )

  if (cancellationQuantities) {
    total = {
      value: Object.entries(cancellationQuantities).reduce(
        (value, [sku, quantity]) => {
          return value + quantity * cancellationKeyValueMap[sku].unitPrice
        },
        0
      ),
      quantity: Object.entries(cancellationQuantities).reduce(
        (prevQuantity, quantity) => {
          return prevQuantity + quantity[1]
        },
        0
      ),
    }
  }

  const columns = getColumns(true, register, setValue, watch)

  const { mutateAsync: updateOrderCancellation } = useUpdateOrderCancelation({
    onSettled: discardCancelation,
    onSuccess: () => {
      if (submitStatus === 'submitAndSave') {
        openSuccessToast(t`Cancellation request changed and approved`)
      } else {
        openSuccessToast(t`Cancellation request changed`)
      }
    },
  })

  const onSubmit = (data: FormValues) => {
    const cancellationItems = Object.entries(
      data.cancellationQuantity
    ).map<ErpOrderCancellationItems>(([sku, cancellationQuantity]) => {
      const { lineId } = cancellationRequest?.cancellationItems.find(
        (item: any) => item.sku === sku
      ) as { lineId: string }

      return {
        sku,
        cancellationQuantity,
        lineId,
      }
    })

    if (submitStatus === 'submit') {
      return updateOrderCancellation({
        debitorNumber: cancellationRequest.debitorNumber,
        uuid: cancellationRequest.uuid,
        cancellationItems,
      })
    } else {
      return updateOrderCancellation({
        debitorNumber: cancellationRequest.debitorNumber,
        uuid: cancellationRequest.uuid,
        cancellationItems,
        state: 'approved',
      })
    }
  }

  return (
    <div className="relative">
      <form onSubmit={handleSubmit(onSubmit)} onReset={discardCancelation}>
        {cancellationRequest?.cancellationItems && (
          <DataTable
            data={[
              ...cancellationRequest.cancellationItems?.sort(
                (
                  { [sortOptionKey]: valA }: { [key: string]: any },
                  { [sortOptionKey]: valB }: { [key: string]: any }
                ) => {
                  if (typeof valA === 'string' && typeof valB === 'string') {
                    return valA?.localeCompare(valB)
                  }
                  return valA - valB
                }
              ),
            ]}
            columns={columns}
            userCellProps={
              !!cancellationRequest
                ? { currencyIsoCode: cancellationRequest?.currencyIsoCode }
                : { currencyIsoCode: 'EUR' }
            }
          />
        )}
        <Footer
          formState={formState}
          cancellationRequest={cancellationRequest}
          total={total.value}
          quantity={total.quantity}
          setSubmitStatus={setSubmitStatus}
          submitStatus={submitStatus}
        />
      </form>
    </div>
  )
}

export default CancelationForm
