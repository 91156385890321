import React, { Fragment, useState } from 'react'

import {
  FloatingPortal,
  flip,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
} from '@floating-ui/react'
import { Trans } from '@lingui/macro'
import cn from '@meltdownjs/cn'

import { DotsVerticalIcon } from '@heroicons/react/outline'

import { Actions } from 'src/pages/Carts/Overview/components/types'

type ListItemContextMenuProps = {
  cart: any
  canCloneCart: boolean
  canExportCart: boolean
  isClaimed: boolean
  actions: Actions
}

const ListItemContextMenu: React.FC<ListItemContextMenuProps> = ({
  cart,
  canCloneCart,
  canExportCart,
  isClaimed = false,
  actions,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom',
    middleware: [flip()],
  })

  const listRef = React.useRef<(HTMLElement | null)[]>([])

  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    focusItemOnHover: true,
  })

  const click = useClick(context)
  const dismiss = useDismiss(context, { ancestorScroll: true })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [listNavigation, click, dismiss]
  )

  const hasAdvancedCartFunctions =
    (canCloneCart || canExportCart) && !!actions?.showRenameCartDialog

  return (
    <Fragment>
      <button
        ref={refs.setReference}
        className="flex h-8 w-8 items-center justify-center rounded hover:bg-gradient-gray-light active:bg-gradient-gray-dark"
        {...getReferenceProps({
          onClick: (event) => {
            event.stopPropagation()
          },
        })}
      >
        <DotsVerticalIcon className="w-5" />
      </button>

      {isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className="outline-none rounded-lg bg-white py-2 shadow-xl focus-within:ring-0 focus:ring-0"
            {...getFloatingProps()}
          >
            <div>
              {!!actions?.showRenameCartDialog && (
                <div
                  className={cn(
                    'flex h-8 cursor-pointer items-center whitespace-nowrap px-4 focus:ring-0',
                    {
                      'bg-gray-300': activeIndex === 0,
                    }
                  )}
                  tabIndex={activeIndex === 0 ? 0 : -1}
                  {...getItemProps({
                    onClick: (event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      actions?.showRenameCartDialog?.(cart)
                    },
                    onKeyDown: (event) => {
                      if (event.key === 'Enter') {
                        event.stopPropagation()
                        event.preventDefault()
                        actions?.showRenameCartDialog?.(cart)
                      }
                    },
                  })}
                  ref={(node) => {
                    listRef.current[0] = node
                  }}
                >
                  <Trans>Rename cart...</Trans>
                </div>
              )}
              {hasAdvancedCartFunctions && <hr className="my-2" />}
              {canCloneCart && !!actions?.showCloneCartDialog && (
                <div
                  className={cn(
                    'flex h-8 cursor-pointer items-center whitespace-nowrap px-4 focus:ring-0',
                    {
                      'bg-gray-300': activeIndex === 1,
                    }
                  )}
                  tabIndex={activeIndex === 1 ? 0 : -1}
                  {...getItemProps({
                    onClick: (event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      actions?.showCloneCartDialog?.(cart)
                    },
                    onKeyDown: (event) => {
                      if (event.key === 'Enter') {
                        event.stopPropagation()
                        event.preventDefault()
                        actions?.showCloneCartDialog?.(cart)
                      }
                    },
                  })}
                  ref={(node) => {
                    listRef.current[1] = node
                  }}
                >
                  <Trans>Clone cart</Trans>
                </div>
              )}
              {canExportCart && !!actions?.showExportCartDialog && (
                <div
                  className={cn(
                    'flex h-8 cursor-pointer items-center whitespace-nowrap px-4 focus:ring-0',
                    {
                      'bg-gray-300': activeIndex === 2,
                    }
                  )}
                  tabIndex={activeIndex === 2 ? 0 : -1}
                  {...getItemProps({
                    onClick: (event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      actions?.showExportCartDialog?.(cart)
                    },
                    onKeyDown: (event) => {
                      if (event.key === 'Enter') {
                        event.stopPropagation()
                        event.preventDefault()
                        actions?.showExportCartDialog?.(cart)
                      }
                    },
                  })}
                  ref={(node) => {
                    listRef.current[2] = node
                  }}
                >
                  <Trans>Export cart</Trans>
                </div>
              )}
              {isClaimed && !!actions?.showReleaseCartDialog && (
                <Fragment>
                  <div
                    className={cn(
                      'flex h-8 cursor-pointer items-center whitespace-nowrap px-4 focus:ring-0',
                      {
                        'bg-gray-300': activeIndex === 3,
                      }
                    )}
                    tabIndex={activeIndex === 3 ? 0 : -1}
                    {...getItemProps({
                      onClick: (event) => {
                        event.stopPropagation()
                        event.preventDefault()
                        actions?.showReleaseCartDialog?.(cart)
                      },
                      onKeyDown: (event) => {
                        if (event.key === 'Enter') {
                          event.stopPropagation()
                          event.preventDefault()
                          actions?.showReleaseCartDialog?.(cart)
                        }
                      },
                    })}
                    ref={(node) => {
                      listRef.current[3] = node
                    }}
                  >
                    <Trans>Release cart</Trans>
                  </div>
                </Fragment>
              )}

              {!!actions?.showDeleteCartDialog && (
                <Fragment>
                  <hr className="my-2" />
                  <div
                    className={cn(
                      'flex h-8 cursor-pointer items-center whitespace-nowrap px-4 focus:ring-0',
                      {
                        'bg-gray-300': activeIndex === 4,
                      }
                    )}
                    tabIndex={activeIndex === 4 ? 0 : -1}
                    {...getItemProps({
                      onClick: (event) => {
                        event.stopPropagation()
                        event.preventDefault()
                        actions?.showDeleteCartDialog?.(cart)
                      },
                      onKeyDown: (event) => {
                        if (event.key === 'Enter') {
                          event.stopPropagation()
                          event.preventDefault()
                          actions?.showDeleteCartDialog?.(cart)
                        }
                      },
                    })}
                    ref={(node) => {
                      listRef.current[4] = node
                    }}
                  >
                    <Trans>Delete cart</Trans>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </FloatingPortal>
      )}
    </Fragment>
  )
}

export default ListItemContextMenu
