import { useInfiniteQuery } from 'react-query'

import { cartSearch } from 'src/api/cart-search'

import { UseInfiniteCartsProps } from 'src/hooks/data/useInfiniteCarts/types'

const selectorFn = (data: any) => {
  const [{ pagination }] = data.pages

  const items = data.pages.flatMap((page: any) => page.carts)

  return { ...data, items, pagination }
}

const getNextPageParam = (lastPage: any) => {
  if (lastPage.pagination.currentPage === lastPage.pagination.maxPage) {
    return undefined
  }

  return lastPage.pagination.currentPage
}

const useInfiniteCarts = <TSelect>({
  args,
  options,
}: UseInfiniteCartsProps<TSelect> = {}) => {
  const enrichedArgs = args || {}
  enrichedArgs.limit = enrichedArgs?.limit ?? 12

  return useInfiniteQuery<any, unknown, TSelect, any[]>(
    ['carts-infinite', enrichedArgs],
    ({ pageParam = 0 }) =>
      cartSearch({
        ...enrichedArgs,
        offset: pageParam * enrichedArgs.limit!,
      }),
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 60 * 8,
      cacheTime: 1000 * 60 * 60 * 24,
      select: selectorFn,
      getNextPageParam,
      ...options,
    }
  )
}

export default useInfiniteCarts
