import React from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Trans } from '@lingui/macro'
import { useForm } from 'react-hook-form'
import Spinner from 'react-svg-spinner'
import * as Yup from 'yup'

import useDeleteCart from 'src/hooks/data/useDeleteCart'

import Button from 'src/components/Button'

const schema = Yup.object({
  companyUserReference: Yup.string().required(),
  cartId: Yup.string().required(),
}).required()

type DeleteCartFormValues = Yup.InferType<typeof schema>

type DeleteCartFormProps = {
  companyUserReference: string
  cartId: string
  onSuccess?: () => void
  onError?: (error: any) => void
  onCancel: () => void
}

const DeleteCartForm: React.FC<DeleteCartFormProps> = ({
  companyUserReference,
  cartId,
  onSuccess,
  onError,
  onCancel,
}) => {
  const { register, handleSubmit, formState } = useForm<DeleteCartFormValues>({
    resolver: yupResolver(schema),
    values: { companyUserReference, cartId },
  })
  const { mutateAsync: deleteCart, isLoading } = useDeleteCart()

  const onSubmit = async (formValues: DeleteCartFormValues) => {
    try {
      await deleteCart(formValues)
      onSuccess?.()
    } catch (error) {
      onError?.(error)
    }
  }

  const { isValid, isSubmitting } = formState

  return (
    <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register('companyUserReference')} />
      <input type="hidden" {...register('cartId')} />

      <div className="mt-8 flex space-x-4">
        <Button
          type="reset"
          disabled={isSubmitting}
          className="flex-grow"
          variant="none"
          size="xl"
          onClick={onCancel}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          type="submit"
          disabled={isSubmitting || !isValid}
          className="flex w-full flex-grow items-center justify-center"
          variant="purple"
          size="xl"
        >
          {isSubmitting || isLoading ? (
            <Spinner size="1.7rem" color="white" />
          ) : (
            <Trans>Delete Cart</Trans>
          )}
        </Button>
      </div>
    </form>
  )
}

export default DeleteCartForm
