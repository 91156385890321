import React from 'react'

import { t } from '@lingui/macro'

type FormErrorMessageProps = {
  errorMessage: React.ReactNode
}

const FormErrorMessage: React.FC<FormErrorMessageProps> = ({
  errorMessage = t`An error has occurred, please try again later.`,
}) => {
  return (
    <div className="!mt-4 flex items-center space-x-2 rounded bg-yellow-300 px-4 py-2 text-yellow-900">
      <span className="font-medium">{errorMessage}</span>
    </div>
  )
}

export default FormErrorMessage
