import React from 'react'
import queryClient from 'src/react-query-client'

const useObserver = (observer, selectorFn, dependencies = []) => {
  // eslint-disable-next-line
  const memoizedSelectorFn = React.useCallback(selectorFn, dependencies)

  const [state, set] = React.useState(() =>
    memoizedSelectorFn(observer.currentResult.data)
  )

  React.useEffect(() => {
    const query = queryClient.getQueryData(observer.options.queryKey)

    if (!!query) {
      set(memoizedSelectorFn(observer.currentResult.data))

      return observer.subscribe(({ data }) => {
        set(memoizedSelectorFn(data))
      })
    }
  }, [observer, memoizedSelectorFn])

  return state
}

export default useObserver
