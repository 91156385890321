import PERMISSIONS from 'src/hooks/utils/usePermissionsByCompanyUser/permissions'

const retailer = {
  super_administration: [
    PERMISSIONS.claimCart,
    PERMISSIONS.cloneCart,
    PERMISSIONS.createCompanyUser,
    PERMISSIONS.createShippingAddress,
    PERMISSIONS.deleteCompany,
    PERMISSIONS.deleteCompanyUser,
    PERMISSIONS.deleteShippingAddress,
    PERMISSIONS.exportCart,
    PERMISSIONS.mergeDeliveryDates,
    PERMISSIONS.seeAvailability,
    PERMISSIONS.seeBetaMode,
    PERMISSIONS.seeGlobalSortOrder,
    PERMISSIONS.updateCompanyUser,
    PERMISSIONS.updateDistributionProductLists,
    PERMISSIONS.updateOrderBudget,
    PERMISSIONS.updateShippingAddress,
    PERMISSIONS.uploadCart,
    PERMISSIONS.preventCustomerOrderConfirmationMail,
    PERMISSIONS.setOrderType,
  ],
  sales_coordination: [
    PERMISSIONS.claimCart,
    PERMISSIONS.cloneCart,
    PERMISSIONS.createCompanyUser,
    PERMISSIONS.createShippingAddress,
    PERMISSIONS.deleteCompany,
    PERMISSIONS.deleteCompanyUser,
    PERMISSIONS.deleteShippingAddress,
    PERMISSIONS.exportCart,
    PERMISSIONS.mergeDeliveryDates,
    PERMISSIONS.seeAvailability,
    PERMISSIONS.seeBetaMode,
    PERMISSIONS.seeGlobalSortOrder,
    PERMISSIONS.updateCompanyUser,
    PERMISSIONS.updateDistributionProductLists,
    PERMISSIONS.updateOrderBudget,
    PERMISSIONS.updateShippingAddress,
    PERMISSIONS.uploadCart,
    PERMISSIONS.preventCustomerOrderConfirmationMail,
    PERMISSIONS.setOrderType,
  ],
  customer_service: [
    PERMISSIONS.claimCart,
    PERMISSIONS.cloneCart,
    PERMISSIONS.createCompanyUser,
    PERMISSIONS.createShippingAddress,
    PERMISSIONS.deleteCompanyUserWithRole('administration'),
    PERMISSIONS.deleteCompanyUserWithRole('customer_service'),
    PERMISSIONS.deleteCompanyUserWithRole('marketing'),
    PERMISSIONS.deleteCompanyUserWithRole('purchase'),
    PERMISSIONS.deleteShippingAddress,
    PERMISSIONS.exportCart,
    PERMISSIONS.mergeDeliveryDates,
    PERMISSIONS.postDefaultCompanyUser,
    PERMISSIONS.seeAvailability,
    PERMISSIONS.seeBetaMode,
    PERMISSIONS.seeGlobalSortOrder,
    PERMISSIONS.updateCompanyUserWithRole('administration'),
    PERMISSIONS.updateCompanyUserWithRole('customer_service'),
    PERMISSIONS.updateCompanyUserWithRole('marketing'),
    PERMISSIONS.updateCompanyUserWithRole('purchase'),
    PERMISSIONS.updateShippingAddress,
    PERMISSIONS.uploadCart,
    PERMISSIONS.preventCustomerOrderConfirmationMail,
    PERMISSIONS.setOrderType,
  ],
  order_management: [
    PERMISSIONS.claimCart,
    PERMISSIONS.cloneCart,
    PERMISSIONS.createCompanyUser,
    PERMISSIONS.createShippingAddress,
    PERMISSIONS.deleteCompanyUserWithRole('administration'),
    PERMISSIONS.deleteCompanyUserWithRole('marketing'),
    PERMISSIONS.deleteCompanyUserWithRole('order_management'),
    PERMISSIONS.deleteCompanyUserWithRole('purchase'),
    PERMISSIONS.deleteShippingAddress,
    PERMISSIONS.exportCart,
    PERMISSIONS.mergeDeliveryDates,
    PERMISSIONS.postDefaultCompanyUser,
    PERMISSIONS.seeAvailability,
    PERMISSIONS.seeBetaMode,
    PERMISSIONS.seeGlobalSortOrder,
    PERMISSIONS.updateCompanyUserWithRole('administration'),
    PERMISSIONS.updateCompanyUserWithRole('marketing'),
    PERMISSIONS.updateCompanyUserWithRole('order_management'),
    PERMISSIONS.updateCompanyUserWithRole('purchase'),
    PERMISSIONS.updateShippingAddress,
    PERMISSIONS.uploadCart,
    PERMISSIONS.preventCustomerOrderConfirmationMail,
    PERMISSIONS.setOrderType,
  ],
  distribution: [
    PERMISSIONS.claimCart,
    PERMISSIONS.cloneCart,
    PERMISSIONS.createCompanyUser,
    PERMISSIONS.createShippingAddress,
    PERMISSIONS.deleteCompanyUserWithRole('administration'),
    PERMISSIONS.deleteCompanyUserWithRole('marketing'),
    PERMISSIONS.deleteCompanyUserWithRole('purchase'),
    PERMISSIONS.deleteShippingAddress,
    PERMISSIONS.exportCart,
    PERMISSIONS.mergeDeliveryDates,
    PERMISSIONS.seeAvailability,
    PERMISSIONS.seeBetaMode,
    PERMISSIONS.updateCompanyUserWithRole('administration'),
    PERMISSIONS.updateCompanyUserWithRole('marketing'),
    PERMISSIONS.updateCompanyUserWithRole('purchase'),
    PERMISSIONS.updateShippingAddress,
    PERMISSIONS.uploadCart,
    PERMISSIONS.preventCustomerOrderConfirmationMail,
    PERMISSIONS.setOrderType,
  ],
  distribution_management: [
    PERMISSIONS.claimCart,
    PERMISSIONS.cloneCart,
    PERMISSIONS.createCompanyUser,
    PERMISSIONS.createShippingAddress,
    PERMISSIONS.deleteCompanyUserWithRole('administration'),
    PERMISSIONS.deleteCompanyUserWithRole('marketing'),
    PERMISSIONS.deleteCompanyUserWithRole('purchase'),
    PERMISSIONS.deleteShippingAddress,
    PERMISSIONS.exportCart,
    PERMISSIONS.mergeDeliveryDates,
    PERMISSIONS.seeAvailability,
    PERMISSIONS.seeBetaMode,
    PERMISSIONS.updateCompanyUserWithRole('administration'),
    PERMISSIONS.updateCompanyUserWithRole('marketing'),
    PERMISSIONS.updateCompanyUserWithRole('purchase'),
    PERMISSIONS.updateShippingAddress,
    PERMISSIONS.uploadCart,
    PERMISSIONS.preventCustomerOrderConfirmationMail,
    PERMISSIONS.setOrderType,
  ],
  administration: [
    PERMISSIONS.cloneCart,
    PERMISSIONS.createCompanyUser,
    PERMISSIONS.createShippingAddress,
    PERMISSIONS.deleteCompanyUserWithRole('administration'),
    PERMISSIONS.deleteCompanyUserWithRole('marketing'),
    PERMISSIONS.deleteCompanyUserWithRole('purchase'),
    PERMISSIONS.deleteShippingAddress,
    PERMISSIONS.exportCart,
    PERMISSIONS.mergeDeliveryDates,
    PERMISSIONS.postDefaultCompanyUser,
    PERMISSIONS.seeOrderBudgetOnCart,
    PERMISSIONS.selectCompanyForAvailabilitySearch,
    PERMISSIONS.updateCompanyUserWithRole('administration'),
    PERMISSIONS.updateCompanyUserWithRole('marketing'),
    PERMISSIONS.updateCompanyUserWithRole('purchase'),
    PERMISSIONS.updateShippingAddress,
    PERMISSIONS.uploadCartAsRetailer,
  ],
  purchase: [
    PERMISSIONS.createShippingAddress,
    PERMISSIONS.deleteShippingAddress,
    PERMISSIONS.mergeDeliveryDates,
    PERMISSIONS.postDefaultCompanyUser,
    PERMISSIONS.seeOrderBudgetOnCart,
    PERMISSIONS.selectCompanyForAvailabilitySearch,
    PERMISSIONS.updateShippingAddress,
    PERMISSIONS.uploadCartAsRetailer,
  ],
  marketing: [PERMISSIONS.postDefaultCompanyUser],
}

export default retailer
