import React from 'react'

import { t } from '@lingui/macro'

import useInfiniteCompanies from 'src/hooks/data/useInfiniteCompanies'

import InfiniteCombobox from 'src/components/InfiniteCombobox'

type MinimalCompany = {
  id: string
  name: string
  debtorNumber: string
}

export type InfiniteSelectProperties = {
  label?: string
  onChange: (item?: MinimalCompany | null) => void
  itemToString?: (item: MinimalCompany | null) => string
  error?: string
}

const defaultLabel = t`Choose a customer`

const defaultItemToString = (item: MinimalCompany | null): string =>
  !item ? '' : `${item.name} • ${item.debtorNumber}`

const InfiniteCompanySelect: React.FC<InfiniteSelectProperties> = ({
  label = defaultLabel,
  onChange,
  error,
  itemToString = defaultItemToString,
}) => {
  const [inputValue, setInputValue] = React.useState<string>('')
  const {
    data: queryData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isSuccess,
  } = useInfiniteCompanies({ searchTerm: inputValue })

  const data = queryData as { items?: MinimalCompany[] }

  const initialSelectedItem = React.useMemo(
    () => (!inputValue && data?.items?.length === 1 ? data.items?.[0] : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.items?.length, inputValue]
  )

  React.useEffect(() => {
    if (!!initialSelectedItem) {
      onChange(initialSelectedItem)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSelectedItem])

  if (!isSuccess) {
    return null
  }

  return (
    <div className="space-y-2">
      <InfiniteCombobox
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        hidden={
          data?.items?.length === 1 && !!initialSelectedItem && !inputValue
        }
        initialSelectedItem={initialSelectedItem}
        isFetchingNextPage={isFetchingNextPage}
        items={data?.items}
        label={label}
        onInputValueChange={(inputValue?: string | undefined) =>
          setInputValue(inputValue || '')
        }
        onChange={(selectedItem) => {
          if (onChange) {
            onChange(selectedItem)
          }
        }}
        itemToString={itemToString}
        error={error}
      />
      {!!error && (
        <div className="text-sm font-light text-red-500">{error}</div>
      )}
    </div>
  )
}

export default InfiniteCompanySelect
