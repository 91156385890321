import React from 'react'
import { useParams } from 'react-router-dom'

import { FloatingPortal } from '@floating-ui/react'
import cn from '@meltdownjs/cn'
import {
  Item,
  Option,
  isEqual,
  transformRecordToItems,
  useSelect,
} from '@meltdownjs/droppy'
import { VirtualItem } from '@tanstack/react-virtual'

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'

import useCart from 'src/hooks/data/useCart'
import useDebouncedCartUpdate from 'src/hooks/data/useDebouncedCartUpdate'

type Filters = {
  searchOptions: {
    sort: string
  }
}

type Cart = {
  filters: Filters
}

type SortSelectProps = {
  sortOptions: Record<string, string>
  referenceProps?: React.HTMLAttributes<HTMLDivElement>
}

const SortSelect = (props: SortSelectProps) => {
  const { cartId } = useParams()
  const updateCart = useDebouncedCartUpdate(cartId)

  const { data } = useCart(cartId, {
    notifyOnChangeProps: 'tracked',
    select: ({ filters }: { filters: Filters }) => ({
      sort: filters.searchOptions.sort,
    }),
  })

  const options = transformRecordToItems<string>(props.sortOptions)

  const initialSelectedOption = options.find(
    (item: Item<string>) => item.key === data.sort
  )

  const onSelectedOptionChange = (selectedOption: Item<string> | undefined) => {
    if (
      selectedOption === undefined ||
      isEqual(selectedOption, initialSelectedOption)
    ) {
      return
    }
    updateCart({
      updaterFn: (draft: Cart) => {
        draft.filters.searchOptions.sort = selectedOption.key
      },
    })
  }

  const {
    dropdownList: { isOpen, activeIndex, listRef },
    virtualizer: {
      scrollElementRef,
      virtualItems,
      getTotalSize,
      measureElement,
    },
    getReferenceProps,
    getFloatingProps,
    getItemProps,
    selectedOption,
  } = useSelect<HTMLDivElement, Item<string>>({
    options,
    initialSelectedOption,
    virtualizerOptions: {
      estimateSize: 40,
    },
    onSelectedOptionChange,
  })

  return (
    <div className="min-w- mb-2 mr-4 mt-2">
      <div
        {...getReferenceProps({
          ...props.referenceProps,
          className: cn(
            'flex h-8 flex-auto items-center overflow-hidden rounded border border-transparent bg-white px-2 hover:border-gray-300',
            props.referenceProps?.className
          ),
        })}
      >
        <div className="pr-2">{initialSelectedOption?.value}</div>
        <div className="ml-auto">
          {isOpen ? (
            <ChevronUpIcon className="w-5" />
          ) : (
            <ChevronDownIcon className="w-5" />
          )}
        </div>
      </div>
      {isOpen && (
        <FloatingPortal>
          <div
            {...getFloatingProps({
              className:
                'bg-white border border-gray-300 text-gray-900 text-sm rounded-md outline-none',
            })}
          >
            <div ref={scrollElementRef} className="h-52 overflow-y-auto">
              <ul
                className="relative"
                style={{
                  height: `${getTotalSize()}px`,
                }}
              >
                {virtualItems.length > 0 &&
                  virtualItems.map((virtualItem: VirtualItem) => {
                    const isSelectedOption =
                      options[virtualItem.index] &&
                      isEqual(options[virtualItem.index], selectedOption)

                    return (
                      <Option
                        {...getItemProps(undefined, virtualItem)}
                        key={virtualItem.index}
                        data-index={virtualItem.index}
                        listRef={listRef}
                        index={virtualItem.index + 1}
                        activeIndex={activeIndex}
                        measureElement={measureElement}
                        className={cn(
                          'absolute left-0 top-0 flex w-full cursor-pointer items-center px-3 py-2',
                          {
                            'cursor-default bg-gray-400': isSelectedOption,
                            'hover:bg-gray-300 focus:bg-gray-300':
                              !isSelectedOption,
                          }
                        )}
                        style={{
                          transform: `translateY(${virtualItem.start}px)`,
                        }}
                      >
                        {options[virtualItem.index]?.value}
                      </Option>
                    )
                  })}
              </ul>
            </div>
          </div>
        </FloatingPortal>
      )}
    </div>
  )
}

export default SortSelect
