import React from 'react'
import { Navigate, Outlet, RouteObject } from 'react-router-dom'

import DataLayerPushWrapper from 'src/components/DataLayerPushWrapper'

import lazyRetry from 'src/lib/lazyRetry'

const Media = React.lazy(() =>
  lazyRetry(() => import('src/pages/Downloads/Media/Overview'))
)

// const MasterData = React.lazy(() =>
//   lazyRetry(() => import('src/pages/Downloads/MasterData'))
// )
const MasterData = React.lazy(() =>
  lazyRetry(() => import('src/pages/Downloads/MasterData/Overview'))
)

const BrandMasterData = React.lazy(() =>
  lazyRetry(() => import('src/pages/Downloads/MasterData/BrandMasterData'))
)

const BrandMedia = React.lazy(() =>
  lazyRetry(() => import('src/pages/Downloads/Media/BrandMedia'))
)

const downloadsRoute: RouteObject = {
  path: 'downloads',
  element: (
    <div className="container mx-auto flex flex-grow flex-col px-8 py-10 md:px-12 md:py-11 lg:px-16 lg:py-12">
      <Outlet />
    </div>
  ),
  children: [
    {
      path: 'media',
      children: [
        {
          path: '*',
          index: true,
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'media_overview',
              }}
            >
              <Media />
            </DataLayerPushWrapper>
          ),
        },
        {
          path: ':brand',
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'media',
              }}
            >
              <BrandMedia />
            </DataLayerPushWrapper>
          ),
        },
      ],
    },
    {
      path: 'master-data',
      children: [
        {
          path: '*',
          index: true,
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'media_overview',
              }}
            >
              <MasterData />
            </DataLayerPushWrapper>
          ),
        },
        {
          path: ':brand',
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'media',
              }}
            >
              <BrandMasterData />
            </DataLayerPushWrapper>
          ),
        },
      ],
    },
    // {
    //   path: 'master-data',
    //   element: (
    //     <DataLayerPushWrapper
    //       layer={{
    //         event: 'vpv_general',
    //         page_type: 'master-data',
    //       }}
    //     >
    //       <MasterData />
    //     </DataLayerPushWrapper>
    //   ),
    // },
    { path: '*', element: <Navigate to="media" /> },
  ],
}

export default downloadsRoute
