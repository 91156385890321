import React, { Fragment } from 'react'

import Currency from 'src/components/Currency'

type TotalProps = {
  currencyIsoCode: string
  price: any
  quantity: number
}

const Total: React.FC<TotalProps> = ({ currencyIsoCode, price, quantity }) => {
  return (
    <Fragment>
      <div>
        <span>
          <Currency
            cents={price * quantity}
            currencyIsoCode={!!currencyIsoCode ? currencyIsoCode : 'EUR'}
          />
        </span>
      </div>
    </Fragment>
  )
}

export default Total
