import React from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Trans, t } from '@lingui/macro'
import { useForm } from 'react-hook-form'
import Spinner from 'react-svg-spinner'
import * as Yup from 'yup'

import useCart from 'src/hooks/data/useCart'
import useCompanyUserByCompanyId from 'src/hooks/data/useCompanyUserByCompanyId'
import useCreateCartWithItems from 'src/hooks/data/useCreateCartWithItems'

import Button from 'src/components/Button'

import { defaultSetValueOptionsForRerendering } from 'src/forms'
import InfiniteCompanySelect from 'src/forms/components/InfiniteCompanySelect'
import Input from 'src/forms/components/Input'

type MinimalCart = {
  id: string
  name: string
  items: unknown[]
}

const schema = Yup.object({
  companyUserId: Yup.string().required().default(''),
  companyId: Yup.string()
    .required(({ label }) => t`Please select a ${label}`)
    .label(t`customer`)
    .default(''),
  name: Yup.string()
    .required()
    .label(t`name`)
    .default(''),
}).required()

type CloneCartFormValues = Yup.InferType<typeof schema>

type CloneCartFormProps = {
  cart: MinimalCart
  values?: CloneCartFormValues
  onSuccess?: (formValues: CloneCartFormValues) => void
  onError?: (error: any) => void
  onCancel?: () => void
}

const CloneCartForm: React.FC<CloneCartFormProps> = ({
  cart,
  values,
  onSuccess,
  onError,
  onCancel,
}) => {
  const { register, watch, setValue, formState, handleSubmit } =
    useForm<CloneCartFormValues>({
      mode: 'all',
      resolver: yupResolver(schema),
      values,
    })

  const cartQuery = useCart(cart?.id)
  const {
    mutateAsync: createCart,
  }: { mutateAsync: (_variables: any) => Promise<any> } =
    useCreateCartWithItems()
  const onSubmit = async (formValues: CloneCartFormValues) => {
    try {
      await createCart({
        lineItems: cartQuery?.data?.items,
        name: formValues.name,
        companyUserId: formValues.companyUserId,
      })
      onSuccess?.(formValues)
    } catch (error) {
      onError?.(error)
    }
  }

  const { isValid, isSubmitting, errors } = formState

  React.useEffect(() => {
    register?.('companyUserId')
    register?.('companyId')
    register?.('name')
  }, [register])

  const { companyId } = watch()

  const { data: companyUser } = useCompanyUserByCompanyId(companyId)
  React.useEffect(() => {
    setValue(
      'companyUserId',
      !!companyUser?.companyUserReference
        ? companyUser?.companyUserReference
        : '',
      defaultSetValueOptionsForRerendering
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyUser?.companyUserReference])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
      <InfiniteCompanySelect
        onChange={(item) => {
          setValue(
            'companyId',
            !!item?.id ? item.id : '',
            defaultSetValueOptionsForRerendering
          )
        }}
        error={errors?.companyId?.message}
      />

      <input type="hidden" {...register('companyUserId')} />

      <label className="block">
        <div className="mb-2 text-sm">
          <Trans>Cart name</Trans>
        </div>
        <Input {...register('name')} error={errors?.name?.message} />
      </label>

      <div className="mt-8 flex space-x-4">
        <Button
          type="reset"
          onClick={onCancel}
          className="flex-grow"
          size="xl"
          variant="none"
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          type="submit"
          disabled={isSubmitting || !isValid}
          className="flex w-full flex-grow items-center justify-center"
          size="xl"
          variant="purple"
        >
          {isSubmitting ? (
            <Spinner size="1.7rem" color="white" />
          ) : (
            <Trans>Clone cart</Trans>
          )}
        </Button>
      </div>
    </form>
  )
}

export default CloneCartForm
