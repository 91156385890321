import { privateClient } from './api-client'

export type TOrderBudget = {
  id: string
  budget: number
  initialBudget: number
  nextInitialBudget: number
}

export type TOrderBudgetSearchResponse = {
  data: [
    {
      type: string
      attributes: {
        orderBudgets: TOrderBudget[]
      }
    }
  ]
}

export const getOrderBudget = async (
  companyUserReference: string
): Promise<TOrderBudgetSearchResponse> => {
  return await privateClient('order-budget-search', {
    searchParams: {
      'company-user-reference': companyUserReference,
    },
  }).json()
}

export type OrderBudgetBulkAttributes = {
  orderBudgets: {
    company: { debtorNumber: string }
    nextInitialBudget: number
  }[]
}

export const postOrderBudgetsBulk = async (
  attributes: OrderBudgetBulkAttributes
) =>
  await privateClient
    .post('order-budgets-bulk', {
      json: {
        data: {
          type: 'order-budgets-bulk',
          attributes,
        },
      },
    })
    .json()
