import React, { ButtonHTMLAttributes, ForwardedRef, ReactNode } from 'react'

import useDialogContext from 'src/components/Dialog/useDialogContext'

type DialogCloseProps = {
  children?: ReactNode | ((context: DialogContextType) => ReactNode)
  asChild?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>

export type DialogContextType = {
  open: boolean
  setOpen: (isOpen: boolean) => void
}

export const DialogClose: React.ForwardRefExoticComponent<DialogCloseProps> =
  React.forwardRef(
    (
      { children, asChild = false, ...props }: DialogCloseProps,
      ref: ForwardedRef<HTMLButtonElement>
    ): JSX.Element => {
      const context = useDialogContext()
      const onClick = () => context.setOpen(false)
      const openState = context.open ? 'open' : 'closed'

      if (typeof children === 'function') {
        return (children as (context: DialogContextType) => ReactNode)(
          context
        ) as JSX.Element
      }

      if (asChild && React.isValidElement(children)) {
        return React.cloneElement(children, {
          ref,
          ...props,
          ...children.props,
          'data-state': openState,
          onClick,
        })
      }

      return (
        <button
          type="button"
          {...props}
          ref={ref}
          data-state={openState}
          onClick={onClick}
        />
      )
    }
  )
