import { useQuery } from 'react-query'

import { getCompanyBusinessUnitByCompanyId } from 'src/api'

const useCompanyBusinessUnitByCompanyId = (companyId, options) =>
  useQuery(
    ['companyBusinessUnit', companyId],
    () => getCompanyBusinessUnitByCompanyId(companyId),
    { ...options }
  )

export default useCompanyBusinessUnitByCompanyId
