import { useQuery } from 'react-query'

import { getManufacturer } from 'src/api'

const useManufacturer = (options) =>
  useQuery(['manufacturer'], () => getManufacturer(), {
    ...options,
  })

export default useManufacturer
