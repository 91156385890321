import { UseQueryOptions, useQueries, useQuery } from 'react-query'
import queryClient from 'src/react-query-client'

import { getAvailabilityPeriods } from 'src/api'
import isAnyQueryLoading from 'src/lib/isAnyQueryLoading'
import isEveryQuerySuccess from 'src/lib/isEveryQuerySuccess'

export const getAvailabilityQuery = (
  {
    productId,
    companyUserReference,
    channel,
  }: { productId: string; companyUserReference: string; channel?: string },
  options?: UseQueryOptions
) => ({
  queryKey: [
    'availability-periods',
    companyUserReference,
    productId,
    ...(channel ? [channel] : []),
  ],
  queryFn: async () => {
    const availabilities = await getAvailabilityPeriods([productId], channel)
    return availabilities
  },
  staleTime: 5 * (60 * 1000), //5min
  cacheTime: 15 * (60 * 1000), //10min
  initialData: () => {
    const availabilityQuery = queryClient.getQueryData([
      'availability-periods-paginated',
      companyUserReference,
      ...(channel ? [channel] : []),
    ]) as { pageParams: string[]; pages: Object[] }

    if (availabilityQuery) {
      const availabilities = availabilityQuery?.pages?.reduce(
        (collection: Object, page: Object) => ({ ...collection, ...page }),
        {}
      ) as Record<string, Object>
      if (availabilities?.[productId]) {
        return { [productId]: availabilities[productId] }
      }
    }
  },
  enabled: !!companyUserReference && !!productId,
  ...options,
})

const useAvailability = (
  {
    productId,
    companyUserReference,
    channel,
  }: { productId: string; companyUserReference: string; channel?: string },
  options?: UseQueryOptions
) =>
  useQuery(
    getAvailabilityQuery({ productId, companyUserReference, channel }, options)
  )

export const useAvailabilityQueries = (
  {
    productIds,
    companyUserReference,
  }: { productIds: string[]; companyUserReference: string },
  options?: UseQueryOptions
) => {
  const queries = productIds.map((productId) =>
    getAvailabilityQuery({ productId, companyUserReference }, options)
  )
  return useQueries(queries)
}

export const useAvailabilities = ({
  productIds,
  companyUserReference,
}: {
  productIds: string[]
  companyUserReference: string
}) => {
  const queries = useAvailabilityQueries({ productIds, companyUserReference })
  const isLoading = isAnyQueryLoading(...queries)
  const isSuccess = isEveryQuerySuccess(...queries)

  if (isLoading) {
    return { isLoading }
  }

  let data = {}
  queries.forEach(({ data: queryData }) => {
    data = { ...data, ...(queryData as Object) }
  })

  return { isLoading, isSuccess, data }
}

export default useAvailability
