import { Trans } from '@lingui/macro'

import useBusinessUnitCarts from 'src/hooks/data/useBusinessUnitCarts'
import useCompanyBusinessUnitByCompanyId from 'src/hooks/data/useCompanyBusinessUnitByCompanyId'

import { LoadingPlaceholder } from 'src/components/NoDataPlaceholder'

import Select from 'src/forms/components/Select'

type CartSelectProperties = {
  companyId: string
  disabled: boolean
  value?: string
  onChange: (value: string | undefined) => void | Promise<void>
}

const CartSelect: React.FC<CartSelectProperties> = ({
  companyId,
  disabled,
  value,
  onChange,
}) => {
  const { data: companyBusinessUnitId } = useCompanyBusinessUnitByCompanyId(
    companyId,
    {
      select: ({ uuid }: { uuid: string }) => uuid,
    }
  )
  const { data: businessUnitsCarts = [], isLoading } = useBusinessUnitCarts(
    companyBusinessUnitId,
    { enabled: !!companyBusinessUnitId }
  )

  if (isLoading) {
    return <LoadingPlaceholder />
  }

  const claimableCarts = businessUnitsCarts.filter(
    ({
      originalCustomer,
    }: {
      originalCustomer: { customerReference: string }
    }) => originalCustomer == null
  )

  if (claimableCarts.length === 0) {
    return (
      <div>
        <div className="mb-2 text-sm">
          <Trans>Choose a cart</Trans>
        </div>
        <div className="flex h-10 items-center rounded border border-yellow-400 bg-yellow-200 px-4 text-yellow-800">
          <Trans>This customer does not have any carts</Trans>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="mb-2 text-sm">
        <Trans>Choose a cart</Trans>
      </div>
      <Select
        value={value}
        onChange={onChange}
        options={claimableCarts.reduce(
          (
            collection: unknown[],
            { id, name }: { id: string; name: string }
          ) => ({
            ...collection,
            [id]: name,
          }),
          {}
        )}
        disabled={disabled}
      />
    </div>
  )
}

export default CartSelect
