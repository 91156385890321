import { Trans, t } from '@lingui/macro'

import {
  Dialog,
  DialogContainer,
  DialogContent,
  DialogContextType,
  DialogDescription,
  DialogHeading,
  DialogXButton,
} from 'src/components/Dialog'

import ExportCartForm from 'src/forms/ExportCartForm'
import { openFailureToast, openSuccessToast } from 'src/lib/toast'

type ExportCartDialogContainerProps = {
  cartId: string
  onClose: () => void
}

const ExportCartDialogContainer: React.FC<ExportCartDialogContainerProps> = ({
  cartId,
  onClose,
}) => (
  <Dialog initialOpen onClose={onClose}>
    <DialogContainer>
      <DialogXButton />
      <DialogHeading>
        <Trans>Export cart</Trans>
      </DialogHeading>
      <DialogDescription>
        <Trans>Would you like to export the cart?</Trans>
      </DialogDescription>
      <DialogContent>
        {
          ((context: DialogContextType) => {
            const dismiss = () => context.setOpen(false)
            return (
              <ExportCartForm
                cartId={cartId}
                onSuccess={() => {
                  dismiss()
                  openSuccessToast(t`Cart exported successfully`)
                }}
                onCancel={dismiss}
                onError={() => {
                  openFailureToast(t`Cart could not be exported`)
                  dismiss()
                }}
              />
            )
          }) as any
        }
      </DialogContent>
    </DialogContainer>
  </Dialog>
)

export default ExportCartDialogContainer
