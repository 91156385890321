import React from 'react'

import { i18n } from '@lingui/core'
import cn from '@meltdownjs/cn'

type CurrencyProps = {
  cents: number
  currencyIsoCode?: string
  className?: string
}

const Currency: React.FC<CurrencyProps> = ({
  cents,
  currencyIsoCode,
  className,
  ...props
}) => (
  <span
    className={cn('font-bold', className)}
    title={'tabular-nums'}
    {...props}
  >
    {currencyIsoCode
      ? i18n.number(cents / 100, {
          style: 'currency',
          currency: currencyIsoCode,
        })
      : `${i18n.number(cents / 100, {
          style: 'decimal',
          minimumFractionDigits: 2,
        })} ?`}
  </span>
)

export default Currency
