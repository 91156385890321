import React from 'react'
import { useParams } from 'react-router-dom'

import { i18n } from '@lingui/core'

import useCart from 'src/hooks/data/useCart'
import useDebouncedCartUpdate from 'src/hooks/data/useDebouncedCartUpdate'

import Filter from 'src/components/Filter'

const CartFilter = ({ filterDefinition, hideSearch }) => {
  const { cartId } = useParams()
  const updateCart = useDebouncedCartUpdate(cartId)
  const { data } = useCart(cartId, {
    notifyOnChangeProps: 'tracked',
    select: ({ companyUserReference, filters }) => ({
      companyUserReference,
      filters: filters.searchOptions.filters || {},
      sort: filters.searchOptions.sort,
      hiddenColumns: filters.cartOptions?.hiddenColumns || [],
      searchTerm: filters.searchOptions.searchTerm,
    }),
  })

  return (
    <Filter
      hideSearch={hideSearch}
      className="min-w-[6rem]"
      key={filterDefinition.label}
      value={data.filters[filterDefinition.name]}
      label={filterDefinition.label}
      options={filterDefinition.options}
      config={filterDefinition.config}
      onChange={(value) => {
        updateCart({
          updaterFn: (draft) => {
            draft.filters.searchOptions.filters = {
              ...draft.filters.searchOptions.filters,
              [filterDefinition.name]: value,
            }
            draft.filters.locale = i18n.locale
          },
        })
      }}
    />
  )
}

export default CartFilter
