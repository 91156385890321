import { AllLocaleData, i18n } from '@lingui/core'
import * as detectLocale from '@lingui/detect-locale'
import { setDefaultOptions } from 'date-fns'
import * as plurals from 'make-plural/plurals'

import useCustomerLocale from 'src/hooks/data/useCustomerLocale'

import { TLocaleId, getLDICById, getLocaleIds } from 'src/lib/userLocaleHelper'

declare global {
  interface Window {
    UC_UI: any
  }
}

const DEFAULT_FALLBACK = 'en'
const localeIds: TLocaleId[] = getLocaleIds()

const fromNavigator = () => {
  const navigatorLocale = detectLocale.fromNavigator()
  return localeIds.find((locale) => navigatorLocale.includes(locale))
}

const allLocaleData: AllLocaleData = localeIds.reduce(
  (allLocaleData, id) => ({ ...allLocaleData, [id]: { plurals: plurals[id] } }),
  {}
)

i18n.loadLocaleData(allLocaleData)

const dynamicLoad = async (locale: TLocaleId) => {
  const { messages } = await import(`./locales/${locale}.ts`)
  i18n.load(locale, messages)
  i18n.activate(locale)
}

const setDefaultLocaleForDateFns = async (localeName?: string) => {
  const locales: any = await import('date-fns/locale')
  const languageAndTerritory = localeName ? localeName.replace('_', '') : 'enUS'
  const language = languageAndTerritory.substring(0, 2)

  const locale = locales[languageAndTerritory] || locales[language]
  setDefaultOptions({ locale })
}

export const useLocaleHandler = () => {
  const { data: customerLocale } = useCustomerLocale()

  const locale = detectLocale.detect(
    getLDICById(customerLocale?.idLocale),
    fromNavigator(),
    DEFAULT_FALLBACK
  )! as TLocaleId

  dynamicLoad(locale)
  setDefaultLocaleForDateFns(customerLocale?.localeName)

  window.UC_UI?.updateLanguage?.(locale)
}
