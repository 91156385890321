import { privateClient } from './api-client'

export const getPrices = async (productIds, priceList) => {
  const searchParams = new URLSearchParams({
    'page[offset]': 0,
    'page[limit]': 10000,
    sort: 'sku_asc',
    name: priceList,
  })

  for (const productId of productIds) {
    searchParams.append('sku[]', productId)
  }

  const response = await privateClient(
    'price-product-abstract-price-list-search',
    {
      searchParams,
    }
  ).json()

  const pricesCollection = {}

  for (const { sku, prices } of response.data[0].attributes.prices) {
    const [value] = Object.values(prices)
    pricesCollection[sku.replace('Abstract-', '')] = value.NET_MODE.DEFAULT
  }

  return pricesCollection
}
