import React from 'react'
import { Navigate, Outlet, RouteObject } from 'react-router-dom'

import DataLayerPushWrapper from 'src/components/DataLayerPushWrapper'

import lazyRetry from 'src/lib/lazyRetry'

const Orders = React.lazy(() =>
  lazyRetry(() => import('src/pages/Documents/Orders'))
)

const Order = React.lazy(() =>
  lazyRetry(() => import('src/pages/Documents/Order'))
)

const Invoices = React.lazy(() =>
  lazyRetry(() => import('src/pages/Documents/Invoices'))
)

const Invoice = React.lazy(() =>
  lazyRetry(() => import('src/pages/Documents/Invoice'))
)

const DeliveryNotes = React.lazy(() =>
  lazyRetry(() => import('src/pages/Documents/DeliveryNotes'))
)

const DeliveryNote = React.lazy(() =>
  lazyRetry(() => import('src/pages/Documents/DeliveryNote'))
)

const Outstanding = React.lazy(() =>
  lazyRetry(() => import('src/pages/Documents/OutstandingItems'))
)

const documentsRoute: RouteObject = {
  path: 'documents',
  element: (
    <div className="container mx-auto flex flex-grow flex-col px-8 py-10 md:px-12 md:py-11 lg:px-16 lg:py-12">
      <Outlet />
    </div>
  ),
  children: [
    { path: '*', index: true, element: <Navigate to="orders" /> },
    {
      path: 'orders',
      children: [
        {
          index: true,
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'orders',
              }}
            >
              <Orders />
            </DataLayerPushWrapper>
          ),
        },
        {
          path: ':orderId',
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'order_detail',
              }}
            >
              <Order />
            </DataLayerPushWrapper>
          ),
        },
      ],
    },
    {
      path: 'invoices',
      children: [
        {
          index: true,
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'invoices',
              }}
            >
              <Invoices />
            </DataLayerPushWrapper>
          ),
        },
        {
          path: ':invoiceId',
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'invoice_detail',
              }}
            >
              <Invoice />
            </DataLayerPushWrapper>
          ),
        },
      ],
    },
    {
      path: 'delivery-notes',
      children: [
        {
          index: true,
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'delivery-notes',
              }}
            >
              <DeliveryNotes />
            </DataLayerPushWrapper>
          ),
        },
        {
          path: ':deliveryNoteId',
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'delivery-note_detail',
              }}
            >
              <DeliveryNote />
            </DataLayerPushWrapper>
          ),
        },
      ],
    },
    {
      path: 'outstanding',
      element: (
        <DataLayerPushWrapper
          layer={{
            event: 'vpv_general',
            page_type: 'outstanding',
          }}
        >
          <Outstanding />
        </DataLayerPushWrapper>
      ),
    },
  ],
}

export default documentsRoute
