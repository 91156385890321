import { useNavigate } from 'react-router-dom'

import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { format } from 'date-fns'
import Spinner from 'react-svg-spinner'

import { CompanyUser } from 'src/api/company-user-search/types'

import useCompany from 'src/hooks/data/useCompany'
import useCreateCart from 'src/hooks/data/useCreateCart'
import useDefaultCompanyUser from 'src/hooks/data/useDefaultCompanyUser'

import Button from 'src/components/Button'

import { openInfoToast } from 'src/lib/toast'

const CreateNewCartButton = () => {
  const { data: defaultCompanyUser } = useDefaultCompanyUser<CompanyUser>()
  const { data: company } = useCompany(defaultCompanyUser?.companyId, {
    enabled: !!defaultCompanyUser?.companyId,
  })

  const { mutateAsync: createCart, isLoading } = useCreateCart()
  const navigate = useNavigate()

  const createNewCart = async () => {
    try {
      openInfoToast(t`One moment please. The shopping cart is being created`)
      const cart = await createCart({
        companyUserId: defaultCompanyUser?.companyUserReference,
        name: `${company?.debtorNumber} - ${format(
          new Date(),
          'yyyy-MM-dd hh:mm:ss'
        )}`,
        filters: {
          searchOptions: {
            sort: 'global_sort_order_asc',
          },
          locale: i18n.locale,
          cartOptions: { hiddenColumns: [] },
        },
        items: undefined,
      })
      navigate(`${cart.id}`)
    } catch (error) {}
  }

  return (
    <Button
      size="md"
      variant="purple"
      className="min-w-[5rem] text-sm"
      onClick={createNewCart}
    >
      {isLoading ? (
        <div className="flex h-8 items-center justify-center px-4">
          <Spinner size="1.7rem" color="white" />
        </div>
      ) : (
        <Trans>New cart</Trans>
      )}
    </Button>
  )
}

export default CreateNewCartButton
