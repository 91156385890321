import * as React from 'react'

import { DialogOptions } from 'src/components/Dialog/types'
import useDialog from 'src/components/Dialog/useDialog'
import { DialogContext } from 'src/components/Dialog/useDialogContext'

export * from 'src/components/Dialog'

export const Dialog = ({
  children,
  ...options
}: {
  children: React.ReactNode
} & DialogOptions) => {
  const dialog = useDialog(options)
  return (
    <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>
  )
}
