import React from 'react'
import { Navigate, RouteObject } from 'react-router-dom'

import DataLayerPushWrapper from 'src/components/DataLayerPushWrapper'

import lazyRetry from 'src/lib/lazyRetry'

const Profile = React.lazy(() =>
  lazyRetry(() => import('src/pages/Company/Profile'))
)
const Users = React.lazy(() =>
  lazyRetry(() => import('src/pages/Company/Users'))
)
const Addresses = React.lazy(() =>
  lazyRetry(() => import('src/pages/Company/Addresses'))
)

const companyRoute: RouteObject = {
  path: 'company',
  children: [
    { path: '*', index: true, element: <Navigate to="users" /> },
    {
      path: 'profile',
      element: (
        <DataLayerPushWrapper
          layer={{
            event: 'vpv_general',
            page_type: 'profile',
          }}
        >
          <Profile />
        </DataLayerPushWrapper>
      ),
    },
    {
      path: 'users',
      element: (
        <DataLayerPushWrapper
          layer={{
            event: 'vpv_general',
            page_type: 'users',
          }}
        >
          <Users />
        </DataLayerPushWrapper>
      ),
    },
    {
      path: 'addresses',
      element: (
        <DataLayerPushWrapper
          layer={{
            event: 'vpv_general',
            page_type: 'addresses',
          }}
        >
          <Addresses />
        </DataLayerPushWrapper>
      ),
    },
  ],
}

export default companyRoute
