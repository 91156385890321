import {
  defaultDistributorPathPatterns,
  possibleDistributorPathPatterns,
} from 'src/routes/permissions/distributorPathPattern'
import {
  defaultManufacturerPathPatterns,
  possibleManufacturerPathPatterns,
} from 'src/routes/permissions/manufacturerPathPattern'
import {
  defaultRetailerPathPatterns,
  possibleRetailerPathPatterns,
} from 'src/routes/permissions/retailerPathPattern'

export const possiblePathPatterns: Record<string, Record<string, string[]>> = {
  manufacturer: possibleManufacturerPathPatterns,
  distributor: possibleDistributorPathPatterns,
  retailer: possibleRetailerPathPatterns,
}

export const defaultPathPatterns: Record<string, Record<string, string>> = {
  manufacturer: defaultManufacturerPathPatterns,
  distributor: defaultDistributorPathPatterns,
  retailer: defaultRetailerPathPatterns,
}
