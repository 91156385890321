import { privateClient } from 'src/api/api-client'
import { CartSearchArgs, CartSearchResult } from 'src/api/cart-search/types'
import { normalizePayloadForType } from 'src/api/utils'

export const cartSearch = async (
  args?: CartSearchArgs
): Promise<CartSearchResult> => {
  const searchParams = new URLSearchParams()

  if (args?.companyUserReference !== undefined) {
    searchParams.append('company-user-reference', args?.companyUserReference)
  }

  if (args?.ids !== undefined) {
    for (const id of args?.ids) {
      searchParams.append('id[]', id)
    }
  }

  if (args?.companyId !== undefined) {
    searchParams.append('company-id', args?.companyId)
  }

  if (args?.companyBusinessUnitId !== undefined) {
    searchParams.append('company-business-unit-id', args?.companyBusinessUnitId)
  }

  if (args?.onlyMine !== undefined) {
    searchParams.append('only-mine', args?.onlyMine ? 'true' : 'false')
  }

  if (args?.onlyClaimed !== undefined) {
    searchParams.append('only-claimed', args?.onlyClaimed ? 'true' : 'false')
  }

  if (args?.q !== undefined) {
    searchParams.append('q', args?.q)
  }

  searchParams.append('page[limit]', String(args?.limit || 12))
  searchParams.append('page[offset]', String(args?.offset || 0))

  if (args?.sort !== undefined) {
    searchParams.append('sort', args?.sort)
  }

  const payload = await privateClient('cart-search', {
    searchParams,
  }).json()

  const [carts] = normalizePayloadForType(payload, 'cartSearch')
  return carts
}
