import React from 'react'

import { Trans, t } from '@lingui/macro'
import Spinner from 'react-svg-spinner'

import { ErpOrderCancellation } from 'src/api/erp-order-cancellation/erpOrderCancellationResponse'

import useUpdateOrderCancelation from 'src/hooks/data/useUpdateOrderCancellation'

import Button from 'src/components/Button'

import { openSuccessToast } from 'src/lib/toast'

type RejectCancellationRequestFormProps = {
  cancellationRequest: ErpOrderCancellation
  onCancel: () => void
}

const RejectCancellationRequestForm: React.FC<
  RejectCancellationRequestFormProps
> = ({ cancellationRequest, onCancel }) => {
  const { isLoading, mutate: rejectCancellationRequest } =
    useUpdateOrderCancelation({
      onSuccess: () => {
        onCancel()
        openSuccessToast(t`Cancellation request rejected`)
      },
    })

  return (
    <form className="space-y-8">
      <div className="mt-8 flex space-x-4">
        <Button
          type="reset"
          className="flex-grow"
          variant="gray"
          size="xl"
          onClick={onCancel}
        >
          <Trans>Cancel</Trans>
        </Button>
        <Button
          type="submit"
          className="flex w-full flex-grow items-center justify-center"
          variant="critical"
          size="xl"
          onClick={(e) => {
            e.preventDefault()
            rejectCancellationRequest({
              uuid: cancellationRequest?.uuid,
              debitorNumber: cancellationRequest?.debitorNumber,
              state: 'rejected',
            })
          }}
        >
          {isLoading ? (
            <Spinner size="1.7rem" color="white" />
          ) : (
            <Trans>Reject</Trans>
          )}
        </Button>
      </div>
    </form>
  )
}

export default RejectCancellationRequestForm
