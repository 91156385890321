import queryClient from 'src/react-query-client'

import { CartSearchResult } from 'src/api/cart-search/types'
import {
  CompanyUser,
  CompanyUserSearchResult,
} from 'src/api/company-user-search/types'

import { getCartSearchQueryOptions } from 'src/hooks/data/useCarts'
import { getCompanyUserSearchQueryOptions } from 'src/hooks/data/useCompanyUserSearch'
import { getCustomerQueryOptions } from 'src/hooks/data/useCustomer'

import { postDefaultCompanyUser } from 'src/api'

export const getCompanyCartById = async (companyId: string, id: string) => {
  const cartSearchQueryOptions = getCartSearchQueryOptions({
    args: { companyId, ids: [id], onlyMine: true },
    options: {
      select: (data: any) => {
        const [cart] = [...data?.carts]
        return cart
      },
    },
  })

  const cartSearchResult =
    queryClient.getQueryData<CartSearchResult>(
      cartSearchQueryOptions.queryKey
    ) ?? (await queryClient.fetchQuery(cartSearchQueryOptions))

  const cart = cartSearchResult?.carts?.[0]

  return cart
}

const companyUsersQueryOptions = getCompanyUserSearchQueryOptions({
  args: { showAll: false },
  options: {
    select: (data: any) => {
      const [companyUser] = [...data?.companyUser]
      return companyUser
    },
  },
})

export const getCompanyUsers = async () => {
  const companyUserSearchResult =
    queryClient.getQueryData<CompanyUserSearchResult>(
      companyUsersQueryOptions.queryKey
    ) ?? (await queryClient.fetchQuery(companyUsersQueryOptions))

  const companyUsers = companyUserSearchResult?.companyUser || []

  return companyUsers
}

const defaultCompanyUserQueryOptions = getCompanyUserSearchQueryOptions({
  args: { isDefault: true },
  options: {
    select: (data: any) => {
      const [companyUser] = [...data?.companyUser]
      return companyUser
    },
  },
})

export const getDefaultCompanyUser = async () => {
  const defaultCompanyUserSearchResult =
    queryClient.getQueryData<CompanyUserSearchResult>(
      defaultCompanyUserQueryOptions.queryKey
    ) ?? (await queryClient.fetchQuery(defaultCompanyUserQueryOptions))

  const defaultCompanyUser = defaultCompanyUserSearchResult?.companyUser?.[0]

  return defaultCompanyUser
}

export const getCompanyUserByCompanyId = async (companyId: string) => {
  const companyUserQueryOptions = getCompanyUserSearchQueryOptions({
    args: { companyId, showAll: false },
    options: {
      select: (data: any) => {
        const [companyUser] = [...data?.companyUser]
        return companyUser
      },
    },
  })

  const companyUserSearchResult =
    queryClient.getQueryData<CompanyUserSearchResult>(
      companyUserQueryOptions.queryKey
    ) ?? (await queryClient.fetchQuery(companyUserQueryOptions))

  const companyUser = companyUserSearchResult?.companyUser?.[0]

  return companyUser
}

export const setDefaultCompanyUser = async (companyUser: CompanyUser) => {
  await postDefaultCompanyUser(companyUser?.companyUserReference)
  queryClient.invalidateQueries(['company-user-search', { isDefault: true }])
}

export const getCurrentCustomer = async () => {
  try {
    const customerQueryOptions = getCustomerQueryOptions()

    const currentCustomer =
      queryClient.getQueryData<any>(customerQueryOptions.queryKey) ??
      (await queryClient.fetchQuery(customerQueryOptions))

    return currentCustomer
  } catch (error) {
    return undefined
  }
}

export const getIsLoggedIn = async () => {
  const currentCustomer = await getCurrentCustomer()

  return !!currentCustomer && !!currentCustomer.email
}

export const getIsInitialLogin = async () => {
  const isLoggedIn = await getIsLoggedIn()
  const currentCustomer = await getCurrentCustomer()

  return (
    isLoggedIn &&
    currentCustomer?.firstName === 'Unknown' &&
    currentCustomer?.lastName === 'Unknown'
  )
}
