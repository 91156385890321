import React from 'react'
import { useMutation } from 'react-query'
import { onlineManager } from 'react-query'
import queryClient from 'src/react-query-client'

import debounce from 'debounce-async'
import produce from 'immer'
import useLocalStorageState from 'use-local-storage-state'

import { patchCart, transformCartItemsToApiShape } from 'src/api'
import {
  getGa4Item,
  getUniversalAnalyticsItem,
} from 'src/lib/analyticsDataMapper'
import pushDataLayer from 'src/lib/pushDataLayer'

const getProduct = (sku) => {
  const queries = queryClient.getQueriesData('products-paginated')

  return {
    ...queries
      .flatMap(([_, query]) =>
        query?.pages?.flatMap((page) => page.abstractProducts)
      )
      .find((product) => product?.id === sku),
    id: sku,
  }
}

const eventuallySyncCart = debounce(async ({ companyUserId, cartId }) => {
  const { items: itemsObject } = queryClient.getQueryData(['cart', cartId])
  const itemsArray = transformCartItemsToApiShape(itemsObject)

  const { items, totals, updatedAt, validationMessages } = await patchCart({
    companyUserId,
    cartId,
    attributes: { items: itemsArray },
  })

  // we don't want the new items to replace local items,
  // instead we want to merge them so local items are preserved
  // note that it's not necessary to transform these items here,
  // patchCart already transformed them for us
  queryClient.setQueryData(['cart', cartId], (oldCart) => {
    const newCart = produce(oldCart, (draft) => {
      draft.totals = totals
      draft.updatedAt = updatedAt
      draft.validationMessages = validationMessages

      for (const id in draft.items) {
        // only update if
        if (
          // the item exists in both local and remote collections
          draft.items[id] &&
          items[id] &&
          // and the item has a quantity
          draft.items[id].quantity &&
          items[id].quantity
        ) {
          draft.items[id].messages = items[id].messages
          draft.items[id].deliveryDate = items[id].deliveryDate
          draft.items[id].concreteDeliveryDate = items[id].concreteDeliveryDate
          draft.items[id].calculations = items[id].calculations
          draft.items[id].name = items[id].name
        }
      }
    })

    return newCart
  })
}, 1000)

const UPDATE_EVENT = {
  ADD: { ga4: 'add_to_cart', universalAnalytics: 'addToCart' },
  REMOVE: { ga4: 'remove_from_cart', universalAnalytics: 'removeFromCart' },
}

const updateCartDataLayerPush = (updateEvent, items) => {
  pushDataLayer({ ecommerce: null })
  pushDataLayer({
    event: updateEvent.universalAnalytics,
    ecommerce: {
      currencyCode: 'EUR',
      add: {
        products: items.universalAnalytics,
      },
    },
  })
  // GA4
  pushDataLayer({ ecommerce_ga4: null })
  pushDataLayer({
    event: updateEvent.ga4,
    ecommerce_ga4: {
      items: items.ga4,
    },
  })
}

const pushCartItemsUpdateLayer = (draft, items) => {
  const addedItems = { universalAnalytics: [], ga4: [] }
  const deletedItems = { universalAnalytics: [], ga4: [] }
  for (const id in items) {
    const [sku] = id.split('.')
    const product = getProduct(sku)
    const quantity = draft.items[id]?.quantity
    const newQuantity = items[id]

    if (!quantity || quantity < newQuantity) {
      const quantityChange = newQuantity - quantity || newQuantity
      addedItems.universalAnalytics.push(
        getUniversalAnalyticsItem(product, quantityChange)
      )
      addedItems.ga4.push(getGa4Item(product, quantityChange))
    } else if (quantity > newQuantity) {
      const quantityChange = quantity - newQuantity
      deletedItems.universalAnalytics.push(
        getUniversalAnalyticsItem(product, quantityChange)
      )
      deletedItems.ga4.push(getGa4Item(product, quantityChange))
    }
  }
  if (addedItems.universalAnalytics.length > 0) {
    updateCartDataLayerPush(UPDATE_EVENT.ADD, addedItems)
  }

  if (deletedItems.universalAnalytics.length > 0) {
    updateCartDataLayerPush(UPDATE_EVENT.REMOVE, deletedItems)
  }
}

const mutateCartItems = produce((draft, items) => {
  pushCartItemsUpdateLayer(draft, items)
  for (const id in items) {
    const [sku, deliveryDate] = id.split('.')
    const quantity = items[id]

    if (draft.items[id]) {
      // updating existing item
      draft.items[id].quantity = quantity
    } else {
      // creating new item
      draft.items[id] = {
        id,
        sku,
        quantity,
        deliveryDate,
        concreteDeliveryDate: null,
        calculations: {},
      }
    }
  }
})

// write mutation to local state, then trigger `eventuallySyncCart`
let onReconnectSyncPromise = null
const useUpdateCartItems = ({ companyUserId, cartId }, options) => {
  const [_, setPreviousCartData] = useLocalStorageState(
    JSON.stringify(['previousCart', cartId])
  )
  const cachePreviousCartData = React.useCallback(
    (previousItems, newItems) => {
      const previousCartData = Object.values(previousItems).reduce(
        (cartItems, { id, quantity }) => ({
          ...cartItems,
          [id]: quantity,
        }),
        {}
      )
      Object.keys(newItems).forEach((id) => {
        if (!previousCartData[id]) {
          previousCartData[id] = 0
        }
      })

      setPreviousCartData(previousCartData)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cartId]
  )

  return useMutation(
    ['cart', cartId],
    // this runs _after_ onMutate
    () => {
      if (onlineManager.isOnline()) {
        return eventuallySyncCart({ companyUserId, cartId }).catch((error) => {
          if (error === 'canceled') {
            // debounce-async cancels debounced calls, but we don't want that to leak out of here
            // so we're catching that case and are doing nothing.
          } else {
            throw error
          }
        })
      }

      // don't start a parallel sync if one is already in progress
      if (onReconnectSyncPromise !== null) {
        return onReconnectSyncPromise
      }

      onReconnectSyncPromise = new Promise((resolve) => {
        // only sync when we're online, this will wait until we are
        const unsubscribe = onlineManager.subscribe(async () => {
          if (onlineManager.isOnline()) {
            await eventuallySyncCart({ companyUserId, cartId })
            unsubscribe()
            resolve()
            onReconnectSyncPromise = null
          }
        })
      })

      return onReconnectSyncPromise
    },
    {
      ...options,
      // onMutate runs before the mutationFn
      // async because RQ expects onMutate to return a promise
      onMutate: async (items) => {
        queryClient.setQueryData(['cart', cartId], (previousCart) => {
          cachePreviousCartData(previousCart.items, items)
          const result = mutateCartItems(previousCart, items)

          return result
        })
        options?.onMutate?.(items)
      },
      onSettled: () => {
        queryClient.invalidateQueries('split-cart-totals')
        options?.onSettled?.()
      },
      enabled: !!options?.enabled || (!!companyUserId && !!cartId),
    }
  )
}

export default useUpdateCartItems
