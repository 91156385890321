import { privateClient } from './api-client'
import { normalizePayloadForType } from './utils'

export const getBusinessUnitCarts = async (businessUnitId) => {
  const payload = await privateClient
    .get(`company-business-units/${businessUnitId}/company-business-unit-carts`)
    .json()

  payload.data = payload.data.map((cart) => {
    if (cart.id && !cart.attributes.id) {
      cart.attributes.id = cart.id
    }
    return cart
  })

  return normalizePayloadForType(payload, 'companyBusinessUnitCarts')
}
