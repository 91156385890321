import React from 'react'

import cn from '@meltdownjs/cn'

const tailwindComponent =
  (Component, ...utilities) =>
  ({ className, ...props }) =>
    (
      <Component
        className={cn(
          ...utilities.map((utility) =>
            typeof utility === 'function' ? utility(props) : utility
          ),
          className
        )}
        {...props}
      />
    )

export default tailwindComponent
