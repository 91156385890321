import React, { Fragment } from 'react'

import { defineMessage, t } from '@lingui/macro'
import cn from '@meltdownjs/cn'

defineMessage({ id: 'stockStatus_0', message: 'Sold out' })
defineMessage({ id: 'stockStatus_1', message: 'Later available' })

type AvailabilityLabelProps = {
  product: any
}

const AvailabilityLabel: React.FC<AvailabilityLabelProps> = ({ product }) => {
  const stockStatus = product?.stockStatus
  const stockStatusLabel =
    stockStatus === 0 || stockStatus === undefined || stockStatus === null
      ? t({ id: `stockStatus_0` })
      : stockStatus === 1
      ? t({ id: `stockStatus_${stockStatus}` })
      : ''

  return (
    <Fragment>
      {stockStatusLabel && (
        <div
          className={cn('flex h-8 items-center md:h-9', {
            'bg-red-100':
              stockStatus === 0 ||
              stockStatus === undefined ||
              stockStatus === null,
            'bg-yellow-100': stockStatus === 1,
          })}
        >
          <span
            className={cn('block w-full py-0.5 text-center font-medium', {
              'text-red-600':
                stockStatus === 0 ||
                stockStatus === undefined ||
                stockStatus === null,
              'text-yellow-700': stockStatus === 1,
            })}
          >
            {stockStatusLabel}
          </span>
        </div>
      )}
    </Fragment>
  )
}

export default AvailabilityLabel
