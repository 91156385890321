import {
  DEFAULT_PATH_PATTERN_CARTS,
  DEFAULT_PATH_PATTERN_ROOT,
  PATH_PATTERN_ADDRESSES,
  PATH_PATTERN_BUDGET,
  PATH_PATTERN_CARTS,
  PATH_PATTERN_COMPANY_ASSIGNMENT,
  PATH_PATTERN_COMPANY_PROFILE,
  PATH_PATTERN_DELIVERY_NOTES,
  PATH_PATTERN_DOWNLOADS,
  PATH_PATTERN_INVOICES,
  PATH_PATTERN_ORDERS,
  PATH_PATTERN_OUTSTANDING,
  PATH_PATTERN_PRODUCT_LISTS,
  PATH_PATTERN_REPRESENTATIONS,
  PATH_PATTERN_ROOT,
  PATH_PATTERN_SERVICE,
  PATH_PATTERN_USERS,
} from 'src/routes/permissions/constants'

export const possibleDistributorPathPatterns: Record<string, string[]> = {
  super_administration: [
    PATH_PATTERN_ADDRESSES,
    PATH_PATTERN_BUDGET,
    PATH_PATTERN_CARTS,
    PATH_PATTERN_COMPANY_ASSIGNMENT,
    PATH_PATTERN_COMPANY_PROFILE,
    PATH_PATTERN_DELIVERY_NOTES,
    PATH_PATTERN_DOWNLOADS,
    PATH_PATTERN_INVOICES,
    PATH_PATTERN_ORDERS,
    PATH_PATTERN_OUTSTANDING,
    PATH_PATTERN_PRODUCT_LISTS,
    PATH_PATTERN_ROOT,
    PATH_PATTERN_SERVICE,
    PATH_PATTERN_USERS,
  ],
  sales_coordination: [
    PATH_PATTERN_ADDRESSES,
    PATH_PATTERN_BUDGET,
    PATH_PATTERN_CARTS,
    PATH_PATTERN_COMPANY_ASSIGNMENT,
    PATH_PATTERN_COMPANY_PROFILE,
    PATH_PATTERN_DELIVERY_NOTES,
    PATH_PATTERN_DOWNLOADS,
    PATH_PATTERN_INVOICES,
    PATH_PATTERN_ORDERS,
    PATH_PATTERN_OUTSTANDING,
    PATH_PATTERN_PRODUCT_LISTS,
    PATH_PATTERN_ROOT,
    PATH_PATTERN_SERVICE,
    PATH_PATTERN_USERS,
  ],
  customer_service: [
    PATH_PATTERN_ADDRESSES,
    PATH_PATTERN_CARTS,
    PATH_PATTERN_COMPANY_PROFILE,
    PATH_PATTERN_DELIVERY_NOTES,
    PATH_PATTERN_DOWNLOADS,
    PATH_PATTERN_INVOICES,
    PATH_PATTERN_ORDERS,
    PATH_PATTERN_OUTSTANDING,
    PATH_PATTERN_ROOT,
    PATH_PATTERN_SERVICE,
    PATH_PATTERN_USERS,
  ],
  order_management: [
    PATH_PATTERN_ADDRESSES,
    PATH_PATTERN_CARTS,
    PATH_PATTERN_COMPANY_PROFILE,
    PATH_PATTERN_DELIVERY_NOTES,
    PATH_PATTERN_DOWNLOADS,
    PATH_PATTERN_INVOICES,
    PATH_PATTERN_ORDERS,
    PATH_PATTERN_OUTSTANDING,
    PATH_PATTERN_ROOT,
    PATH_PATTERN_SERVICE,
    PATH_PATTERN_USERS,
  ],
  distribution: [
    PATH_PATTERN_ADDRESSES,
    PATH_PATTERN_CARTS,
    PATH_PATTERN_COMPANY_PROFILE,
    PATH_PATTERN_DELIVERY_NOTES,
    PATH_PATTERN_DOWNLOADS,
    PATH_PATTERN_INVOICES,
    PATH_PATTERN_ORDERS,
    PATH_PATTERN_OUTSTANDING,
    PATH_PATTERN_REPRESENTATIONS,
    PATH_PATTERN_ROOT,
    PATH_PATTERN_SERVICE,
    PATH_PATTERN_USERS,
  ],
  distribution_management: [
    PATH_PATTERN_ADDRESSES,
    PATH_PATTERN_CARTS,
    PATH_PATTERN_COMPANY_PROFILE,
    PATH_PATTERN_DELIVERY_NOTES,
    PATH_PATTERN_DOWNLOADS,
    PATH_PATTERN_INVOICES,
    PATH_PATTERN_ORDERS,
    PATH_PATTERN_OUTSTANDING,
    PATH_PATTERN_ROOT,
    PATH_PATTERN_SERVICE,
    PATH_PATTERN_USERS,
  ],
  administration: [
    PATH_PATTERN_ADDRESSES,
    PATH_PATTERN_CARTS,
    PATH_PATTERN_DELIVERY_NOTES,
    PATH_PATTERN_DOWNLOADS,
    PATH_PATTERN_INVOICES,
    PATH_PATTERN_ORDERS,
    PATH_PATTERN_OUTSTANDING,
    PATH_PATTERN_SERVICE,
    PATH_PATTERN_USERS,
  ],
  purchase: [
    PATH_PATTERN_ADDRESSES,
    PATH_PATTERN_CARTS,
    PATH_PATTERN_DELIVERY_NOTES,
    PATH_PATTERN_DOWNLOADS,
    PATH_PATTERN_INVOICES,
    PATH_PATTERN_ORDERS,
    PATH_PATTERN_OUTSTANDING,
    PATH_PATTERN_SERVICE,
    PATH_PATTERN_USERS,
  ],
}

export const defaultDistributorPathPatterns: Record<string, string> = {
  super_administration: DEFAULT_PATH_PATTERN_ROOT,
  sales_coordination: DEFAULT_PATH_PATTERN_ROOT,
  customer_service: DEFAULT_PATH_PATTERN_ROOT,
  order_management: DEFAULT_PATH_PATTERN_ROOT,
  distribution_management: DEFAULT_PATH_PATTERN_ROOT,
  distribution: DEFAULT_PATH_PATTERN_ROOT,
  administration: DEFAULT_PATH_PATTERN_CARTS,
  purchase: DEFAULT_PATH_PATTERN_CARTS,
}
