import { UseMutationOptions, useMutation } from 'react-query'
import queryClient from 'src/react-query-client'

import { postDefaultCompanyUser } from 'src/api'

export const useMutateSetDefaultCompanyUser = (
  options?: UseMutationOptions<Response, unknown, string, unknown>
) =>
  useMutation(
    ['company-user-search', { isDefault: true }],
    (companyUserReference: string) =>
      postDefaultCompanyUser(companyUserReference),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'company-user-search',
          { isDefault: true },
        ])
      },
      ...options,
    }
  )

export default useMutateSetDefaultCompanyUser
