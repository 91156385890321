import queryClient from 'src/react-query-client'

import isBefore from 'date-fns/isBefore'
import decodeToken from 'jwt-decode'
import ky from 'ky'

import logout from 'src/actions/logout'
import { ApiError } from 'src/errors'

import { GLUE_API_URL } from './config'
import { postRefreshToken } from './customers'
import {
  extractTokens,
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from './utils'

const getAcceptLanguageHeader = () => {
  const customer = queryClient.getQueryData('customers')
  if (customer) {
    const [language, region] = customer.locale.localeName.split('_')
    return `${language}-${region},${language};q=0.9`
  }
}

export const publicClient = ky.extend({
  timeout: false,
  retry: 0,
  prefixUrl: GLUE_API_URL,
  headers: {
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json;version=1.1',
  },
  hooks: {
    afterResponse: [
      async (request, options, response) => {
        if (!response.ok) {
          const {
            errors,
            errors: [error],
          } = await response.json()

          throw new ApiError(`${error.detail} (${error.code})`, error, errors)
        }
      },
    ],
  },
})

export const privateClient = publicClient.extend({
  hooks: {
    beforeRequest: [
      async () => {
        const accessToken = getAccessToken()
        if (accessToken) {
          const { exp } = decodeToken(accessToken)
          const isAccessTokenExpired = isBefore(
            // exp is formatted as seconds
            new Date(exp * 1000),
            new Date()
          )

          if (isAccessTokenExpired) {
            const oldRefreshToken = getRefreshToken()

            // TODO speculative code
            // reevaluate this after a while
            if (!oldRefreshToken) {
              logout()

              throw new ApiError('Missing refresh token')
            }

            try {
              const tokenData = await postRefreshToken(oldRefreshToken)
              const { accessToken, refreshToken } = extractTokens(tokenData)

              setAccessToken(accessToken)
              setRefreshToken(refreshToken)
            } catch {
              logout()
              throw new ApiError('Token refresh failed')
            }
          }
        }
      },
      (request) => {
        const accessToken = getAccessToken()
        const acceptLanguageHeader = getAcceptLanguageHeader()

        if (accessToken) {
          request.headers.set('Authorization', `Bearer ${accessToken}`)
        }

        if (acceptLanguageHeader) {
          request.headers.set('Accept-Language', acceptLanguageHeader)
        }
      },
    ],
  },
})
