import { privateClient } from 'src/api/api-client'
import {
  CompanyUserSearchArgs,
  CompanyUserSearchResult,
} from 'src/api/company-user-search/types'
import { normalizePayloadForType } from 'src/api/utils'

export const companyUserSearch = async (
  args?: CompanyUserSearchArgs
): Promise<CompanyUserSearchResult> => {
  const searchParams = new URLSearchParams()

  if (args?.companyId !== undefined) {
    searchParams.append('company-id', args?.companyId)
  }

  if (args?.companyRoleNames !== undefined) {
    for (const companyRoleName of args?.companyRoleNames) {
      searchParams.append('company-role-name[]', companyRoleName)
    }
  }

  if (args?.companyType !== undefined) {
    searchParams.append('company-type', args?.companyType)
  }

  if (args?.companyUserReference !== undefined) {
    searchParams.append('company-user-reference', args?.companyUserReference)
  }

  if (args?.emails !== undefined) {
    for (const companyRoleName of args?.emails) {
      searchParams.append('email[]', companyRoleName)
    }
  }

  if (args?.limit !== undefined) {
    searchParams.append('page[limit]', String(args?.limit))
  }

  if (args?.offset !== undefined) {
    searchParams.append('page[offset]', String(args?.offset))
  }

  if (args?.onlyOnePerCustomer !== undefined) {
    searchParams.append(
      'only-one-per-customer',
      String(args?.onlyOnePerCustomer)
    )
  }

  if (args?.isDefault !== undefined) {
    searchParams.append('is-default', String(args?.isDefault))
  }

  if (args?.q !== undefined) {
    searchParams.append('q', args?.q)
  }

  if (args?.showAll !== undefined) {
    searchParams.append('show-all', String(args?.showAll))
  }

  if (args?.sort !== undefined) {
    searchParams.append('sort', args?.sort)
  }

  const payload = await privateClient('company-user-search', {
    searchParams,
  }).json()

  const [companyUsers] = normalizePayloadForType(payload, 'companyUserSearch')

  return companyUsers
}
