import { useQuery } from 'react-query'

import { getCustomer } from 'src/api'

export const MAIN_QUERY_KEY = 'customers'

export const getCustomerQueryOptions = () => ({
  queryKey: [MAIN_QUERY_KEY],
  queryFn: () => getCustomer(),
  retry: 0,
  refetchOnWindowFocus: false,
  // should be refetched in the background every 8 hours
  staleTime: 1000 * 60 * 60 * 8,
})

const useCustomer = () => useQuery(getCustomerQueryOptions())

export default useCustomer
