import React from 'react'

import useResizeObserver from '@react-hook/resize-observer'

const useHeight = () => {
  const [element, setElement] = React.useState<HTMLElement | null>(null)
  const [height, setHeight] = React.useState(1)
  const ref = React.useCallback((element: HTMLElement | null) => {
    if (!!element) {
      setElement(element)
      setHeight(element.offsetHeight)
    }
  }, [])
  const resizeObserver = useResizeObserver(element, (entry) =>
    setHeight(entry.contentRect.height)
  )

  React.useEffect(() => {
    return () => {
      resizeObserver.disconnect()
    }
  }, [resizeObserver])

  return { ref, height }
}

export default useHeight
