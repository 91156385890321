import React from 'react'
import { Link, useParams } from 'react-router-dom'

import { Trans } from '@lingui/macro'
import cn from '@meltdownjs/cn'
import colors from 'tailwindcss/colors'

import {
  ArrowSmRightIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
} from '@heroicons/react/outline'

import useCart from 'src/hooks/data/useCart'
import useOrderBudget from 'src/hooks/data/useOrderBudget'

import useDefaultCompanyUserPermissions from 'src/hooks/utils/useDefaultCompanyUserPermissions'

import Currency from 'src/components/Currency'
import {
  FloatingArrow,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from 'src/components/Tooltip'

const CheckoutButton = ({
  companyUserReference,
}: {
  companyUserReference: string
}) => {
  const { cartId, companyId } = useParams()
  const { data: cart } = useCart(cartId, { enabled: !!cartId })
  const { ability } = useDefaultCompanyUserPermissions()
  const { data: orderBudget, isSuccess } = useOrderBudget(
    companyUserReference!,
    {
      enabled: !!companyUserReference,
    }
  )

  const hasMultipleDeliveryDates = cart?.deliveryDates.length > 1

  const hasOrderBudget =
    isSuccess &&
    !!orderBudget &&
    cart?.companyUserReference === companyUserReference &&
    ability.can('see', 'orderBudget')

  const availableBudget = hasOrderBudget
    ? orderBudget?.budget - cart.totals.subtotal
    : 0

  const hasNegativeBudget = availableBudget < 0

  return (
    <Link
      to={`/${companyId}/carts/${cartId}/checkout`}
      replace
      state={{ from: 'grid' }}
    >
      <div
        className={cn(
          'flex h-16 rounded-md bg-green-400 text-gray-900 shadow-lg drop-shadow-lg',
          {
            'bg-red-600 text-gray-50': hasNegativeBudget,
          }
        )}
      >
        {hasNegativeBudget && (
          <Tooltip open={hasNegativeBudget}>
            <TooltipTrigger>
              <div className="flex h-full items-center justify-center rounded-l-md bg-red-200 p-3 text-red-700">
                <span className="h-8 w-8">
                  <ExclamationCircleIcon />
                </span>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <div className="py- rounded-md bg-red-600 px-4 text-gray-50 shadow-lg drop-shadow-lg">
                <Trans>
                  Weekly budget exceeded by{' '}
                  <Currency cents={availableBudget} currencyIsoCode={'EUR'} />
                </Trans>
              </div>
              <FloatingArrow fill={colors.red[600]} />
            </TooltipContent>
          </Tooltip>
        )}

        {hasMultipleDeliveryDates && !hasNegativeBudget && (
          <Tooltip initialOpen={true}>
            <TooltipTrigger>
              <div className="flex h-full items-center justify-center rounded-l-md bg-green-100 p-3 text-green-800">
                <span className="h-8 w-8">
                  <ExclamationIcon />
                </span>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <div className="flex space-x-2 rounded-md bg-green-100 px-4 py-3 text-gray-900 shadow-lg drop-shadow-lg">
                <div>
                  <ExclamationIcon className="h-8 w-8 text-green-800" />
                </div>
                <div className="max-w-[220px]">
                  <Trans>
                    This shopping cart has several delivery dates. You can edit
                    these in the checkout.
                  </Trans>
                </div>
              </div>
              <FloatingArrow fill={colors.green[100]} />
            </TooltipContent>
          </Tooltip>
        )}

        <div
          className={cn('flex flex-col items-center justify-center px-6 py-4', {
            'space-x-3': hasOrderBudget,
          })}
        >
          <div className="flex h-10 items-center space-x-2">
            <span className="text-base font-semibold">
              <Trans>To checkout</Trans>
            </span>
            <span className="w-6">
              <ArrowSmRightIcon />
            </span>
          </div>
          {hasOrderBudget && (
            <Currency
              cents={availableBudget}
              currencyIsoCode={'EUR'}
              className="text-xs font-semibold"
            />
          )}
        </div>
      </div>
    </Link>
  )
}

export default CheckoutButton
