import useCompanyUserSearch from 'src/hooks/data/useCompanyUserSearch'

const companyUserSelector = ({ companyUser: [companyUser] }) => ({
  ...companyUser,
  type: 'companyUsers',
})

const useCompanyUserByCompanyId = (companyId, options) =>
  useCompanyUserSearch({
    args: { companyId, showAll: false },
    options: {
      staleTime: 1000 * 60 * 60 * 8,
      cacheTime: 1000 * 60 * 60 * 24,
      select: companyUserSelector,
      ...options,
    },
  })

export default useCompanyUserByCompanyId
