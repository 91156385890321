import { Trans, t } from '@lingui/macro'

import {
  Dialog,
  DialogContainer,
  DialogContent,
  DialogContextType,
  DialogHeading,
  DialogXButton,
} from 'src/components/Dialog'

import RenameCartForm from 'src/forms/RenameCartForm'
import { openFailureToast, openSuccessToast } from 'src/lib/toast'

type RenameCartDialogContainerProps = {
  cart: any
  onClose: () => void
}

const RenameCartDialogContainer: React.FC<RenameCartDialogContainerProps> = ({
  cart,
  onClose,
}) => (
  <Dialog initialOpen onClose={onClose}>
    <DialogContainer>
      <DialogXButton />
      <DialogHeading>
        <Trans>Rename cart</Trans>
      </DialogHeading>
      <DialogContent>
        {
          ((context: DialogContextType) => {
            const dismiss = () => context.setOpen(false)
            return (
              <RenameCartForm
                cart={cart}
                onSuccess={() => {
                  openSuccessToast(t`Cart renamed successfully`)
                  dismiss()
                }}
                onError={() => {
                  openFailureToast(t`Cart could not be renamed`)
                  dismiss()
                }}
                onCancel={dismiss}
              />
            )
          }) as any
        }
      </DialogContent>
    </DialogContainer>
  </Dialog>
)

export default RenameCartDialogContainer
