import { useQuery } from 'react-query'

import { getErpOrderCancellations } from 'src/api/erp-order-cancellation'
import {
  ErpOrderCancellation,
  ErpOrderCancellationArgs,
} from 'src/api/erp-order-cancellation/types'

export const ERP_ORDER_CANCELLATION_STATES = {
  new: 'new',
  ready: 'ready',
  approved: 'approved',
  rejected: 'rejected',
  processed: 'processed',
  error: 'error',
}

const useErpOrderCancellations = ({
  uuid,
  args,
}: {
  uuid?: string
  args?: ErpOrderCancellationArgs
}) => {
  return useQuery(
    ['orderCancellations', uuid],
    async (): Promise<ErpOrderCancellation> => {
      const { erpOrderCancellations } = await getErpOrderCancellations({
        uuid,
        args,
      })

      return erpOrderCancellations
    },
    {
      staleTime: 1000 * 60 * 60 * 8,
      cacheTime: 1000 * 60 * 60 * 24,
    }
  )
}

export default useErpOrderCancellations
