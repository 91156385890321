import React, { Suspense } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'

import { useLocaleHandler } from 'src/i18n'
import { getRoutes } from 'src/routes'

const Root = () => {
  useLocaleHandler()

  const router = createBrowserRouter(getRoutes())

  return (
    <Suspense fallback={null}>
      <I18nProvider i18n={i18n}>
        <RouterProvider router={router} />
      </I18nProvider>
    </Suspense>
  )
}

export default Root
