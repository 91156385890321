import { Trans } from '@lingui/macro'

import { ErpOrderCancellation } from 'src/api/erp-order-cancellation/types'

import Currency from 'src/components/Currency'
import DataTable from 'src/components/DataTable'

type ItemsTableProps = {
  cancellationRequest: ErpOrderCancellation
}

const ItemsTable: React.FC<ItemsTableProps> = ({ cancellationRequest }) => {
  const getColumns = () => [
    {
      accessor: 'position',
      cellProps: {
        style: {
          minWidth: 100,
        },
      },
      Header: () => (
        <div>
          <Trans>Position</Trans>
        </div>
      ),
      Cell: ({ value }) => <span className="truncate">{value}</span>,
    },
    {
      accessor: 'name',
      cellProps: {
        style: {
          minWidth: 450,
        },
      },
      Header: () => (
        <div>
          <Trans>Description</Trans>
        </div>
      ),
      Cell: ({ value }) => <span className="truncate">{value}</span>,
    },
    {
      accessor: 'sku',
      Header: () => (
        <div>
          <Trans>Article number</Trans>
        </div>
      ),
      Cell: ({ value }) => <span className="font-semibold">{value}</span>,
    },
    {
      accessor: 'quantityBeforeCancellation',
      Header: () => (
        <div className="text-right">
          <Trans>Quantity</Trans>
        </div>
      ),
      Cell: ({ value }) => <span className="truncate text-right">{value}</span>,
    },
    {
      accessor: 'cancellationQuantity',
      Header: () => (
        <div className="whitespace-normal">
          <Trans>Canceled</Trans>
        </div>
      ),
      Cell: ({ value }) => <span className="truncate">{value}</span>,
    },
    {
      accessor: 'unitPrice',
      Header: () => (
        <div className="text-right">
          <Trans>Purchase price per item</Trans>
        </div>
      ),
      Cell: ({ value }) => (
        <span className="truncate text-right">
          {value && <Currency cents={value} currencyIsoCode="EUR" />}
        </span>
      ),
    },
  ]

  const columns = getColumns()
  const sortOption = { key: 'line_id' }
  const sortedCancellationItems = cancellationRequest?.cancellationItems?.sort(
    ({ [sortOption.key]: valA }, { [sortOption.key]: valB }) => {
      if (typeof valA === 'string' && typeof valB === 'string') {
        return valA?.localeCompare(valB)
      }
      return valA - valB
    }
  )

  return (
    <DataTable
      data={sortedCancellationItems}
      columns={columns}
      userCellProps={{ currencyIsoCode: 'EUR' }}
    />
  )
}

export default ItemsTable
