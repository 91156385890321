import queryClient from 'src/react-query-client'

import { i18n } from '@lingui/core'

declare global {
  interface Window {
    dataLayer: any
  }
}

const BLACKLIST_DOMAIN = 'fondof.de'

const getUserType = (email: string) =>
  email.includes(BLACKLIST_DOMAIN) ? 'internal' : 'external'

type Customer = { email: string; id: string } | undefined | null

const pushDataLayer = (layer: unknown) => {
  const customer = queryClient.getQueryData<Customer>('customers')

  const defaultLayerAttributes = {
    country_code: i18n.locale,
    ...(customer
      ? { user_type: getUserType(customer.email), user_id: customer.id }
      : {}),
  }
  window.dataLayer?.push({
    ...defaultLayerAttributes,
    ...(!!layer ? layer : {}),
  })
}

export default pushDataLayer
