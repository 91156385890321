import { useMutation } from 'react-query'
import queryClient from 'src/react-query-client'

import { postOrderBudgetsBulk } from 'src/api'

const useOrderBudgetsBulk = () =>
  useMutation(postOrderBudgetsBulk, {
    onSettled: () => {
      queryClient.invalidateQueries('orderBudget')
    },
  })

export default useOrderBudgetsBulk
