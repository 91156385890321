import { useQuery } from 'react-query'

import { getCompany } from 'src/api'

const companySelector = ({ companies: [company] }: { companies: any[] }) =>
  company

const useCompany = (companyId?: string, options?: any) =>
  useQuery(['company', companyId], () => getCompany(companyId), {
    enabled: !!companyId,
    select: companySelector,
    ...options,
  })

export default useCompany
