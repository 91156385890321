import React from 'react'

import cn from '@meltdownjs/cn'
import {
  ListboxArrow,
  ListboxButton,
  ListboxInput,
  ListboxList,
  ListboxOption,
  ListboxPopover,
} from '@reach/listbox'

import { ChevronDownIcon } from '@heroicons/react/solid'

import positionMatchMinWidth from 'src/lib/positionMatchMinWidth'

type SelectProps = {
  value?: string
  options: Record<string, string>
  disabled?: boolean
  error?: string
  onChange?: (value: string | undefined) => Promise<void> | void
  badge?: React.FC
}

const Select: React.FC<SelectProps> = ({
  options,
  onChange,
  disabled,
  error,
  value = '',
  badge: Badge,
}) => {
  return (
    <div className="space-y-2">
      <ListboxInput
        value={value}
        disabled={disabled}
        onChange={(value) => {
          if (!onChange) {
            return
          }

          onChange(value)
        }}
      >
        <ListboxButton
          className={cn(
            'flex h-10 flex-auto items-center overflow-hidden rounded border border-gray-400 bg-white px-3 hover:border-gray-600',
            {
              'hover:border-gray-600 focus:border-aubergine': !disabled,
              'border-red-500 hover:border-red-500 focus:border-red-500':
                !!error,
              'bg-gray-100 text-gray-600': disabled,
            }
          )}
        >
          <div className="truncate">{value ? options[value] : ''}</div>

          <ListboxArrow className="ml-auto">
            {Badge ? <Badge /> : <ChevronDownIcon className="w-5" />}
          </ListboxArrow>
        </ListboxButton>

        <ListboxPopover
          className="z-30 max-h-[calc(2rem*14.8)] overflow-y-auto rounded-lg bg-white text-sm shadow-xl"
          position={positionMatchMinWidth}
          portal={false}
        >
          <ListboxList className="py-2">
            <option disabled value=""></option>
            {Object.keys(options).map((key: string) => (
              <ListboxOption
                key={key}
                value={key}
                label={options[key]}
                className="flex h-8 cursor-pointer items-center px-4"
              >
                <span className="truncate">{options[key]}</span>
              </ListboxOption>
            ))}
          </ListboxList>
        </ListboxPopover>
      </ListboxInput>
      {!!error && (
        <div className="text-sm font-light text-red-500">{`${error}`}</div>
      )}
    </div>
  )
}

export default Select
