import {
  DEFAULT_PATH_PATTERN_ROOT,
  PATH_PATTERN_BUDGET,
  PATH_PATTERN_CANCELLATION_REQUESTS,
  PATH_PATTERN_COMPANY_ASSIGNMENT,
  PATH_PATTERN_COMPANY_PROFILE,
  PATH_PATTERN_DOWNLOADS,
  PATH_PATTERN_PRODUCT_LISTS,
  PATH_PATTERN_REPRESENTATIONS,
  PATH_PATTERN_ROOT,
  PATH_PATTERN_SERVICE,
  PATH_PATTERN_USERS,
} from 'src/routes/permissions/constants'

export const possibleManufacturerPathPatterns: Record<string, string[]> = {
  sales_coordination: [
    PATH_PATTERN_BUDGET,
    PATH_PATTERN_COMPANY_ASSIGNMENT,
    PATH_PATTERN_COMPANY_PROFILE,
    PATH_PATTERN_DOWNLOADS,
    PATH_PATTERN_PRODUCT_LISTS,
    PATH_PATTERN_ROOT,
    PATH_PATTERN_SERVICE,
    PATH_PATTERN_USERS,
    PATH_PATTERN_CANCELLATION_REQUESTS,
  ],
  customer_service: [
    PATH_PATTERN_COMPANY_PROFILE,
    PATH_PATTERN_DOWNLOADS,
    PATH_PATTERN_ROOT,
    PATH_PATTERN_SERVICE,
    PATH_PATTERN_USERS,
  ],
  order_management: [
    PATH_PATTERN_COMPANY_PROFILE,
    PATH_PATTERN_DOWNLOADS,
    PATH_PATTERN_ROOT,
    PATH_PATTERN_SERVICE,
    PATH_PATTERN_USERS,
    PATH_PATTERN_CANCELLATION_REQUESTS,
  ],
  distribution_management: [
    PATH_PATTERN_COMPANY_PROFILE,
    PATH_PATTERN_DOWNLOADS,
    PATH_PATTERN_ROOT,
    PATH_PATTERN_SERVICE,
    PATH_PATTERN_CANCELLATION_REQUESTS,
  ],
  super_distribution: [
    PATH_PATTERN_COMPANY_PROFILE,
    PATH_PATTERN_DOWNLOADS,
    PATH_PATTERN_REPRESENTATIONS,
    PATH_PATTERN_ROOT,
    PATH_PATTERN_SERVICE,
    PATH_PATTERN_CANCELLATION_REQUESTS,
  ],
  distribution: [
    PATH_PATTERN_COMPANY_PROFILE,
    PATH_PATTERN_DOWNLOADS,
    PATH_PATTERN_REPRESENTATIONS,
    PATH_PATTERN_ROOT,
    PATH_PATTERN_SERVICE,
    PATH_PATTERN_CANCELLATION_REQUESTS,
  ],
  administration: [
    PATH_PATTERN_BUDGET,
    PATH_PATTERN_COMPANY_ASSIGNMENT,
    PATH_PATTERN_COMPANY_PROFILE,
    PATH_PATTERN_DOWNLOADS,
    PATH_PATTERN_PRODUCT_LISTS,
    PATH_PATTERN_ROOT,
    PATH_PATTERN_SERVICE,
    PATH_PATTERN_USERS,
    PATH_PATTERN_CANCELLATION_REQUESTS,
  ],
  purchase: [PATH_PATTERN_SERVICE, PATH_PATTERN_DOWNLOADS],
  marketing: [PATH_PATTERN_SERVICE, PATH_PATTERN_DOWNLOADS],
}

export const defaultManufacturerPathPatterns: Record<string, string> = {
  sales_coordination: DEFAULT_PATH_PATTERN_ROOT,
  customer_service: DEFAULT_PATH_PATTERN_ROOT,
  order_management: DEFAULT_PATH_PATTERN_ROOT,
  distribution_management: DEFAULT_PATH_PATTERN_ROOT,
  super_distribution: DEFAULT_PATH_PATTERN_ROOT,
  distribution: DEFAULT_PATH_PATTERN_ROOT,
  administration: DEFAULT_PATH_PATTERN_ROOT,
}
