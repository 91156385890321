import create from 'zustand'
import { persist } from 'zustand/middleware'

const useBetaMode = create(
  persist(
    (set) => ({
      active: false,
      activateBeta: () => set({ active: true }),
      deactivateBeta: () => set({ active: false }),
      toggleBeta: () => set(({ active }) => ({ active: !active })),
    }),
    {
      name: 'beta-mode',
    }
  )
)

export default useBetaMode
