import { UseMutationOptions, useMutation } from 'react-query'
import queryClient from 'src/react-query-client'

import { patchERPOrderCancelation } from 'src/api/erp-order-cancellation'
import { ErpOrderCancellationResponse } from 'src/api/erp-order-cancellation/erpOrderCancellationResponse'
import {
  ErpOrderCancellation,
  ErpOrderCancellationItems,
} from 'src/api/erp-order-cancellation/types'

const useUpdateOrderCancelation = (
  options?: UseMutationOptions<ErpOrderCancellation, unknown, any, unknown>
) =>
  useMutation<
    ErpOrderCancellationResponse,
    unknown,
    { uuid: string; attributes; cancellationItems: ErpOrderCancellationItems[] }
  >(({ uuid, ...attributes }) => patchERPOrderCancelation(uuid, attributes), {
    ...options,
    onSettled: (...props) => {
      queryClient.invalidateQueries(['orderCancellations'])
      options?.onSettled?.(...props)
    },
  })

export default useUpdateOrderCancelation
