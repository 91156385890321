import { addDays, isFriday, isSaturday } from 'date-fns'

export const DEFAULT_DELIVERY_DAY_OFFSET = 4
export const FRIDAY_OFFSET = 2
export const SATURDAY_OFFSET = 1

export const getValidDeliveryDateOffset = (today = new Date()) => {
  let deliveryDayOffset = DEFAULT_DELIVERY_DAY_OFFSET

  if (isFriday(today)) {
    deliveryDayOffset += FRIDAY_OFFSET
  } else if (isSaturday(today)) {
    deliveryDayOffset += SATURDAY_OFFSET
  }

  return deliveryDayOffset
}

const getValidDeliveryDateFromToday = (today = new Date()) => {
  const deliveryDayOffset = getValidDeliveryDateOffset(today)

  return addDays(today, deliveryDayOffset)
}

export default getValidDeliveryDateFromToday
