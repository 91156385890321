import useCompanyUserSearch from 'src/hooks/data/useCompanyUserSearch'
import { UseDefaultCompanyUserProps } from 'src/hooks/data/useDefaultCompanyUser/types'

const useDefaultCompanyUser = <TSelect>({
  args,
  options,
}: UseDefaultCompanyUserProps<TSelect> = {}) =>
  useCompanyUserSearch<TSelect>({
    args: { isDefault: true, ...args },
    options: {
      staleTime: 1000 * 60 * 60 * 8,
      cacheTime: 1000 * 60 * 60 * 24,
      select: (data: any) => {
        const [companyUser] = [...data?.companyUser]
        return companyUser
      },
      ...options,
    },
  })

export default useDefaultCompanyUser
