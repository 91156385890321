import { useQuery } from 'react-query'

import { UseQueryOptions, UseQueryResult } from 'react-query/types/react/types'

import { getPriceList } from 'src/api'

type HookProps = {
  companyId: string
  companyUserReference: string
}

interface UsePriceListFn<HookProps, UseQueryOptions> {
  //TODO: Add ESLint Typescript Rules
  // eslint-disable-next-line no-unused-vars
  (args: HookProps, options: UseQueryOptions): UseQueryResult
}

const usePriceList: UsePriceListFn<
  HookProps,
  UseQueryOptions<unknown, unknown, unknown, string[]>
> = ({ companyId, companyUserReference }: HookProps, options) =>
  useQuery(
    ['price-list', companyUserReference, companyId],
    () => getPriceList(companyId),
    {
      enabled: !!companyId && !!companyUserReference,
      ...options,
    }
  )

export default usePriceList
