import {
  GetNextPageParamFunction,
  InfiniteData,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from 'react-query'

import { getProducts } from 'src/api'

const selectorFn = (data: InfiniteData<any>) => {
  const [
    {
      valueFacets,
      pagination,
      sort: { sortParamLocalizedNames },
    },
  ] = data.pages

  const items = data.pages.flatMap((page: any) => page.abstractProducts)

  const filters = valueFacets.reduce(
    (collection: object, facet: any) => ({
      ...collection,
      [facet.name]: {
        label: facet.localizedName,
        options: facet.values,
        config: facet.config,
        name: facet.name,
      },
    }),
    {}
  )

  return { ...data, items, filters, sort: sortParamLocalizedNames, pagination }
}

const getNextPageParam: GetNextPageParamFunction<any> = (lastPage) => {
  if (lastPage?.pagination?.currentPage === lastPage?.pagination?.maxPage) {
    return undefined
  }

  return lastPage.pagination.currentPage
}

const DEFAULT_PAGE_SIZE = 24
const DEFAULT_SORT = 'name_asc'
const DEFAULT_FILTERS = {}
const DEFAULT_GROUP_HASH: string[] = []
const DEFAULT_ONE_PER_GROUP_HASH = false

export type UsePaginatedProductsArgs = {
  companyUserReference?: string
  sort?: string
  searchTerm?: string
  filters?: object
  limit?: number
  onePerGroupHash?: boolean
  groupHash?: string[]
  modelKey?: string
}

interface UsePaginatedProductsFn<
  UsePaginatedProductsArgs,
  UseInfiniteQueryOptions
> {
  (
    args: UsePaginatedProductsArgs,
    options: UseInfiniteQueryOptions
  ): UseInfiniteQueryResult
}

const usePaginatedProducts: UsePaginatedProductsFn<
  UsePaginatedProductsArgs,
  UseInfiniteQueryOptions<unknown, unknown, unknown, unknown, string[]>
> = (
  {
    companyUserReference,
    sort = DEFAULT_SORT,
    searchTerm,
    filters = DEFAULT_FILTERS,
    limit = DEFAULT_PAGE_SIZE,
    onePerGroupHash = DEFAULT_ONE_PER_GROUP_HASH,
    groupHash = DEFAULT_GROUP_HASH,
    modelKey,
  },
  options
) =>
  useInfiniteQuery(
    [
      'products-paginated',
      ...(companyUserReference ? [companyUserReference] : []),
      ...(sort ? [sort] : []),
      ...(searchTerm ? [searchTerm] : []),
      ...(Object.keys(filters).length > 0 ? [JSON.stringify(filters)] : []),
      ...(limit ? [JSON.stringify(limit)] : []),
      ...(onePerGroupHash ? ['onePerGroupHash'] : []),
      ...(groupHash.length > 0 ? [JSON.stringify(groupHash)] : []),
      ...(modelKey ? [modelKey] : []),
    ],
    ({ pageParam = 0 }) =>
      getProducts({
        offset: pageParam * limit,
        limit,
        sort,
        searchTerm,
        filters,
        onePerGroupHash,
        groupHash,
        modelKey,
      }),
    {
      enabled: [sort, filters].every(Boolean),
      keepPreviousData: true,
      staleTime: 1000 * 60 * 60 * 8,
      cacheTime: 1000 * 60 * 60 * 24,
      select: selectorFn,
      getNextPageParam,
      ...options,
    }
  )

export default usePaginatedProducts
