import cn from '@meltdownjs/cn'

type ListItemSkeletonProps = {
  className?: string
}

const ListItemSkeleton: React.FC<ListItemSkeletonProps> = ({ className }) => {
  return (
    <div
      className={cn(
        'flex h-16 items-center space-x-4 bg-white px-4',
        className
      )}
    >
      <div className="h-10 w-10 rounded-full bg-gray-100" />
      <div className="w-1/3 space-y-2">
        <div className="flex items-center space-x-2">
          <div className="h-3 w-10 rounded-full bg-gray-100" />
          <div className="h-3 w-5 rounded-full bg-gray-100" />
          <div className="h-3 w-16 rounded-full bg-gray-100" />
          <div className="h-3 w-10 rounded-full bg-gray-100" />
        </div>

        <div className="flex items-center space-x-1">
          <div className="h-2 w-4 rounded-full bg-gray-100" />
          <div className="h-2 w-8 rounded-full bg-gray-100" />
          <div className="h-2 w-2 rounded-full bg-gray-100" />
          <div className="h-2 w-10 rounded-full bg-gray-100" />
          <div className="h-2 w-4 rounded-full bg-gray-100" />
        </div>
      </div>
    </div>
  )
}

export default ListItemSkeleton
