import { useQuery } from 'react-query'
import queryClient from 'src/react-query-client'

import { getCart } from 'src/api'

let previousCart = {}
const useCart = (cartId, options) =>
  useQuery(
    ['cart', cartId],
    () => {
      previousCart = queryClient.getQueryData(['cart', cartId])
      return getCart(cartId)
    },
    {
      staleTime: 1000 * 60 * 60 * 8,
      cacheTime: 1000 * 60 * 60 * 24,
      ...options,
      onSuccess: (data) => {
        const cart = queryClient.getQueryData(['cart', cartId])
        if (previousCart?.deliveryDates?.length > cart?.deliveryDates?.length) {
          queryClient.setQueryData(['cart', cartId], () => ({
            ...cart,
            deliveryDates: previousCart.deliveryDates,
          }))
        }
        options?.onSuccess?.(data)
      },
    }
  )

export default useCart
