import React from 'react'
import { Outlet, RouteObject, redirectDocument } from 'react-router-dom'

import DataLayerPushWrapper from 'src/components/DataLayerPushWrapper'

import CartGrid from 'src/pages/Carts/CartGrid'
import Carts from 'src/pages/Carts/Overview'

import lazyRetry from 'src/lib/lazyRetry'
import { getCompanyCartById } from 'src/routes/utils'

const Cart = React.lazy(() => lazyRetry(() => import('src/pages/Carts/Cart')))

const Checkout = React.lazy(() =>
  lazyRetry(() => import('src/pages/Carts/Checkout'))
)

const AfterCheckout = React.lazy(() =>
  lazyRetry(() => import('src/pages/Carts/AfterCheckout'))
)

const loader = async ({ params }: any) => {
  if (!params.companyId || !params.cartId) {
    return redirectDocument('/')
  }

  const cart = await getCompanyCartById(params.companyId, params.cartId)

  if (cart === undefined) {
    return redirectDocument('/')
  }

  return null
}

const cartsRoute: RouteObject = {
  path: 'carts',
  element: <Outlet />,
  children: [
    {
      path: '*',
      index: true,
      element: (
        <DataLayerPushWrapper
          layer={{
            event: 'vpv_general',
            page_type: 'cart_overview',
          }}
        >
          <Carts />
        </DataLayerPushWrapper>
      ),
    },
    {
      path: ':cartId',
      loader,
      element: <Outlet />,
      children: [
        {
          index: true,
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'cart',
              }}
            >
              <Cart />
            </DataLayerPushWrapper>
          ),
        },
        {
          path: 'checkout',
          element: (
            <DataLayerPushWrapper
              layer={{
                event: 'vpv_general',
                page_type: 'checkout',
              }}
            >
              <Checkout />
            </DataLayerPushWrapper>
          ),
        },
      ],
    },
    {
      path: ':cartId/checkout-confirmation',
      element: (
        <DataLayerPushWrapper
          layer={{
            event: 'vpv_general',
            page_type: 'checkout-confirmation',
            timestamp: new Date().getHours(),
          }}
        >
          <AfterCheckout />
        </DataLayerPushWrapper>
      ),
    },
    {
      path: ':cartId/grid',
      element: (
        <DataLayerPushWrapper
          layer={{
            event: 'vpv_general',
            page_type: 'cart-grid',
          }}
        >
          <CartGrid />
        </DataLayerPushWrapper>
      ),
    },
  ],
}

export default cartsRoute
