import { CompanyUser } from 'src/api/company-user-search/types'

import useDefaultCompanyUser from 'src/hooks/data/useDefaultCompanyUser'

import usePermissionsByCompanyUser from 'src/hooks/utils/usePermissionsByCompanyUser'

const useDefaultCompanyUserPermissions = () => {
  const { data: companyUser } = useDefaultCompanyUser<CompanyUser>()
  return usePermissionsByCompanyUser(companyUser)
}

export default useDefaultCompanyUserPermissions
