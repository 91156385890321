import { t } from '@lingui/macro'
import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'

import { getCart } from 'src/api'

const convertToArrayBuffer = (s) => {
  const buf = new ArrayBuffer(s.length)
  const view = new Uint8Array(buf)
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff
  }
  return buf
}

const generateBinary = (cartName, wsData) => {
  const wb = XLSX.utils.book_new()

  wb.Props = {
    Title: t`Cart`,
    Subject: cartName,
    Author: t`FOND OF GmbH`,
    CreatedDate: new Date(),
  }

  wb.SheetNames.push(t`Cart`)

  const workSheet = XLSX.utils.aoa_to_sheet(wsData)
  wb.Sheets[t`Cart`] = workSheet

  const wbOut = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
  return wbOut
}

const prepareWorksheetData = (deliveryDates, items) => {
  const itemGroups = Object.values(items).reduce(
    (group, item) => ({
      ...group,
      [item.sku]: { ...group[item.sku], [item.deliveryDate]: item.quantity },
    }),
    {}
  )

  return [
    [
      'Article number',
      ...deliveryDates.map(
        (date, index) =>
          `Quantity DD${index + 1}\r\n${
            date === 'earliest-date'
              ? date
              : format(new Date(date), 'dd.MM.yyy')
          }`
      ),
    ],
    ...Object.entries(itemGroups).map(([sku, item]) => [
      sku,
      ...deliveryDates.map((deliveryDate) => {
        const date =
          deliveryDate === 'earliest-date'
            ? deliveryDate
            : format(new Date(deliveryDate), 'yyyy-MM-dd')

        return item[date] || 0
      }),
    ]),
  ]
}

// TODO convert into hook (to fetch cart via hook)
const exportCartXLS = async (cartId) => {
  // TODO: fetch via hook
  const cart = await getCart(cartId)

  const wsData = prepareWorksheetData(cart.deliveryDates, cart.items)
  const binary = generateBinary(cart.name, wsData)

  saveAs(
    new Blob([convertToArrayBuffer(binary)], {
      type: 'application/octet-stream',
    }),
    t`${cart.name}.xlsx`
  )
}

export default exportCartXLS
