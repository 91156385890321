import { privateClient } from 'src/api/api-client'
import {
  ErpOrderSearchArgs,
  ErpOrderSearchResult,
} from 'src/api/erp-order-page-search/types'
import { fetchInBatches, normalizePayloadForType } from 'src/api/utils'

export const erpOrderSearch = async (
  args?: ErpOrderSearchArgs
): Promise<ErpOrderSearchResult> => {
  const searchParams = new URLSearchParams()
  args?.externalReferences?.forEach((externalReference) => {
    searchParams.append('external-reference[]', externalReference)
  })

  if (args?.externalReferences === undefined) {
    args?.companyBusinessUnitIds?.forEach((companyBusinessUnitId) => {
      searchParams.append('company-business-unit-uuid[]', companyBusinessUnitId)
    })

    if (args?.q !== undefined) {
      searchParams.append('q', args?.q)
    }

    if (args?.limit !== undefined) {
      searchParams.append('page[limit]', String(args?.limit || 12))
    }

    if (args?.offset !== undefined) {
      searchParams.append('page[offset]', String(args?.offset || 0))
    }

    if (args?.sort !== undefined) {
      searchParams.append('sort', args?.sort)
    }

    if (args?.isCanceled !== undefined) {
      searchParams.append('is-canceled', String(args?.isCanceled))
    }

    if (args?.minOutstandingQuantity !== undefined) {
      searchParams.append(
        'min-outstanding-quantity',
        String(args?.minOutstandingQuantity)
      )
    }
  }

  const payload = await privateClient('erp-order-page-search', {
    searchParams,
  }).json()
  const [erpOrders] = normalizePayloadForType(payload, 'erpOrders')

  const sort = (payload as any)?.data?.[0]?.attributes?.sort

  return {
    ...erpOrders,
    ...(!!sort ? { sort } : {}),
  }
}

export const getOutstandingErpOrdersInBatches = async (
  companyBusinessUnitId: string
) => {
  const erpOrders = await fetchInBatches({
    url: 'erp-order-page-search',
    propertyName: 'erpOrders',
    pageSize: 1000,
    getSearchParams: (index: number, pageSize: number) => ({
      'is-canceled': false,
      'min-outstanding-quantity': 1,
      'page[offset]': pageSize * index,
      'page[limit]': pageSize,
      'company-business-unit-uuid[]': companyBusinessUnitId,
      sort: 'concrete-delivery-date_desc',
    }),
  })
  return erpOrders
}
