import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from 'react-query'

import { getPrices } from 'src/api'

interface UsePaginatedPricesProps {
  priceListName: string
  companyUserReference: string
}

interface UsePaginatedPricesFn<
  UsePaginatedPricesProps,
  UseInfiniteQueryOptions
> {
  (
    //TODO: Add ESLint Typescript Rules
    // eslint-disable-next-line no-unused-vars
    args: UsePaginatedPricesProps,
    // eslint-disable-next-line no-unused-vars
    options: UseInfiniteQueryOptions
  ): UseInfiniteQueryResult
}

const usePaginatedPrices: UsePaginatedPricesFn<
  UsePaginatedPricesProps,
  UseInfiniteQueryOptions<unknown, unknown, unknown, unknown, string[]>
> = ({ priceListName, companyUserReference }, options) =>
  useInfiniteQuery(
    [
      'prices-paginated',
      priceListName,
      ...(companyUserReference ? [companyUserReference] : []),
    ],
    ({ pageParam }) => {
      if (!pageParam) {
        return Promise.resolve({})
      }
      return getPrices(pageParam, priceListName)
    },
    {
      staleTime: 1000 * 60 * 60 * 8,
      cacheTime: 1000 * 60 * 60 * 24,
      enabled: !!priceListName && !!companyUserReference,
      ...options,
    }
  )

export default usePaginatedPrices
