import React from 'react'
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query'

import { Trans } from '@lingui/macro'
import cn from '@meltdownjs/cn'
import Popover from '@reach/popover'
import { useCombobox } from 'downshift'
import Spinner from 'react-svg-spinner'

import { ChevronDownIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'

import useIntersectionObserver from 'src/hooks/data/useIntersectionObserver'

import positionMatchMinWidth from 'src/lib/positionMatchMinWidth'

type ItemRendererProps<T> = {
  item: T
  index: number
  itemProps: any
  isHighlighted: boolean
}

type TItemRenderer<T> = (args: ItemRendererProps<T>) => JSX.Element

type TItemToString<T> = (item: T | null) => string

type InfiniteComboboxProps<T> = {
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<any, unknown>>
  hasNextPage?: boolean
  isFetchingNextPage?: boolean
  hidden?: boolean
  initialSelectedItem?: T | null
  itemRenderer?: TItemRenderer<T>
  items?: T[]
  itemToString: TItemToString<T>
  label?: string
  onChange?: (item?: T | null) => void
  onInputValueChange?: (inputValue?: string) => void
  error?: string
  isFetching?: boolean
  value?: string
}

const InfiniteCombobox = <T,>({
  value,
  error,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  hidden = false,
  initialSelectedItem = null,
  itemRenderer,
  items = [],
  itemToString,
  label,
  onChange,
  onInputValueChange,
  isFetching,
}: InfiniteComboboxProps<T>) => {
  const [selectedItem, setSelectedItem] = React.useState<T | null | undefined>(
    null
  )
  const popoverRef = React.useRef<HTMLDivElement>(null)
  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const intersectionRef = React.useRef<HTMLElement | null>(null)
  const loadMoreRef = React.useRef<HTMLLIElement>(null)

  //TODO: This is a workaround to reset the component
  //This should be handled in a better way
  React.useEffect(() => {
    if (value === undefined) {
      setSelectedItem(null)
    }
  }, [value])

  const {
    getComboboxProps,
    getInputProps,
    getItemProps,
    getLabelProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
    openMenu,
    inputValue,
  } = useCombobox<T>({
    items,
    initialSelectedItem,
    onInputValueChange: ({ inputValue }) => {
      onInputValueChange?.(inputValue)
    },
    itemToString: (item) => {
      if (!item || item === null) {
        return ''
      }

      return itemToString(item)
    },
    selectedItem,
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      setSelectedItem(newSelectedItem)
      onChange?.(newSelectedItem)
    },
  })

  const clearSelection = () => {
    setSelectedItem(null)
    onChange?.(null)
    openMenu()
    setTimeout(() => inputRef.current?.focus())
  }

  useIntersectionObserver({
    root: intersectionRef,
    target: loadMoreRef,
    onIntersect: fetchNextPage,
    enabled: !!hasNextPage,
  })

  const noSearchResult = React.useMemo(
    () => inputValue && items.length === 0,
    [inputValue, items.length]
  )

  return (
    <div className={cn(hidden && 'hidden')}>
      {label && (
        <div {...getLabelProps({ className: 'mb-2 text-sm' })}>{label}</div>
      )}
      <div
        {...getComboboxProps({
          ref: popoverRef,
          onClick: clearSelection,
        })}
        className={cn(
          'flex h-10 w-full items-center rounded border border-gray-400 bg-white hover:border-gray-600',
          {
            'border-red-500 hover:border-red-500 focus:border-red-500': !!error,
          }
        )}
      >
        <input
          {...getInputProps({
            ref: (e) => {
              inputRef.current = e
            },
            disabled: !!selectedItem,
            className:
              'input-search flex flex-auto h-full rounded items-center overflow-hidden',
            type: 'search',
          })}
        />
        {selectedItem ? (
          <button
            tabIndex={-1}
            onClick={clearSelection}
            aria-label="clear selection"
            className="flex w-10 items-center justify-center"
          >
            <XIcon className="h-4 w-4" />
          </button>
        ) : (
          <button
            type="button"
            {...getToggleButtonProps({
              className: 'w-10 flex items-center justify-center',
            })}
            aria-label="toggle menu"
          >
            <ChevronDownIcon className="h-4 w-4" />
          </button>
        )}
      </div>
      <Popover
        hidden={!isOpen}
        targetRef={popoverRef}
        position={positionMatchMinWidth}
        className="z-30 bg-white py-2 text-sm shadow-xl"
      >
        <div
          {...getMenuProps(
            {
              className: 'max-h-[calc(2rem*14.8)] overflow-y-auto',
              ref: (e) => {
                intersectionRef.current = e
              },
            },
            { suppressRefError: true }
          )}
        >
          {isFetching ? (
            <div className="h-5 text-gray-400">
              <Spinner size="100%" color="currentColor" />
            </div>
          ) : (
            items.map((item, index) => {
              const itemProps = getItemProps({ item, index, key: index })

              if (itemRenderer) {
                return itemRenderer({
                  item,
                  index,
                  itemProps,
                  isHighlighted: highlightedIndex === index,
                })
              }
              return (
                <div
                  className={cn(
                    'flex h-8 cursor-pointer items-center px-4',
                    highlightedIndex === index && 'bg-gray-200'
                  )}
                  {...itemProps}
                >
                  {itemToString ? itemToString(item) : item}
                </div>
              )
            })
          )}
          {!noSearchResult && hasNextPage && (
            <li
              className="flex h-8 cursor-pointer items-center px-4"
              ref={loadMoreRef}
              onClick={() => fetchNextPage()}
            >
              {isFetchingNextPage ? (
                <div className="flex h-5 w-full items-center justify-center text-gray-400">
                  <Spinner size="100%" color="currentColor" />
                </div>
              ) : (
                'Load more'
              )}
            </li>
          )}
          {noSearchResult && (
            <div className="flex flex-col items-center justify-center space-y-3 p-4 text-center">
              <span className="text-base font-medium">
                <Trans>Your search seems to have no matches.</Trans>
              </span>
              <span className="max-w-lg text-gray-500">
                <Trans>
                  With a successful search you will see results. You may have to
                  change your search.
                </Trans>
              </span>
            </div>
          )}
        </div>
      </Popover>
    </div>
  )
}

export default InfiniteCombobox
