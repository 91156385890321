import { useQuery } from 'react-query'

import { cartSearch } from 'src/api/cart-search'

import { UseCartsProps } from 'src/hooks/data/useCarts/types'

export const MAIN_QUERY_KEY = 'carts'

export const getCartSearchQueryOptions = <TSelect>({
  args,
  options,
}: UseCartsProps<TSelect>) => {
  const enrichedArgs = args || {}
  enrichedArgs.limit = args?.limit ?? 12
  enrichedArgs.offset = args?.offset ?? 12

  return {
    queryKey: [MAIN_QUERY_KEY, enrichedArgs],
    queryFn: () => cartSearch({ ...enrichedArgs }),
    staleTime: 1000 * 60 * 60 * 8,
    cacheTime: 1000 * 60 * 60 * 24,
    ...options,
  }
}

const useCarts = <TSelect>(props: UseCartsProps<TSelect> = {}) => {
  useQuery<any, unknown, TSelect, string[]>(
    getCartSearchQueryOptions<TSelect>(props)
  )
}

export default useCarts
