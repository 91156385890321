import { useQuery } from 'react-query'
import queryClient from 'src/react-query-client'

import { getBusinessUnitCarts } from 'src/api'

const businessUnitCartsSelector = (businessUnitCarts) => {
  const customerId = queryClient.getQueryData('customers').id
  businessUnitCarts = businessUnitCarts
    .filter(({ customerReference }) => customerReference !== customerId)
    .sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB))
  return businessUnitCarts
}

const useBusinessUnitCarts = (businessUnitId, options) =>
  useQuery(
    ['business-unit-carts', businessUnitId],
    () => getBusinessUnitCarts(businessUnitId),
    {
      select: businessUnitCartsSelector,
      ...options,
    }
  )

export default useBusinessUnitCarts
