import { useMutation } from 'react-query'
import queryClient from 'src/react-query-client'

import { postCart } from 'src/api'

const useCreateCart = () =>
  useMutation(postCart, {
    onSettled: () => {
      return new Promise((resolve, reject) => {
        queryClient.invalidateQueries('carts-infinite')
        setTimeout(() => {
          queryClient.invalidateQueries('carts').then(resolve, reject)
        }, 1000)
      })
    },
  })

export default useCreateCart
