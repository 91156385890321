import {
  GroupedImage,
  GroupedImages,
  Image,
  ImageView,
} from 'src/api/products/types.d'

const makeSizedImageUrl = (url: string, size: number = 48) => {
  const thumbnailUrl = new URL(url)
  thumbnailUrl.searchParams.set('w', String(size))
  thumbnailUrl.searchParams.set('h', String(size))
  thumbnailUrl.searchParams.set('crop', String(true))
  return thumbnailUrl.toString()
}

const prepareGroupedImages = (groupedImages: GroupedImages) => {
  const sortedGroupedImages: GroupedImage[] = []

  for (const imageGroupKey of Object.values(ImageView)) {
    if (!groupedImages[imageGroupKey as ImageView]) {
      continue
    }

    sortedGroupedImages.push(...groupedImages[imageGroupKey as ImageView]!)
  }
  return sortedGroupedImages
}

const transformImage = (imageUrl: string) => {
  const large = makeSizedImageUrl(imageUrl, 1000)
  const medium = makeSizedImageUrl(imageUrl, 400)
  const thumbnail = makeSizedImageUrl(imageUrl, 48)

  return {
    large,
    medium,
    thumbnail,
  }
}

export const transformProducts = (products: any[]) =>
  products.map(({ images, groupedImages, attributes, ...product }) => {
    const sortedGroupedImages = prepareGroupedImages(
      groupedImages as GroupedImages
    )

    const hasImages = images.length > 0
    const hasGroupedImages = sortedGroupedImages.length > 0

    if (hasGroupedImages) {
      images = sortedGroupedImages
    }

    images = images.map(
      ({ externalUrlLarge }: { externalUrlLarge: string }) => externalUrlLarge
    )
    images = [...new Set(images)]
    images = hasImages ? (images.map(transformImage) as Image) : null
    return {
      id: product.abstractSku.replace('Abstract-', ''),
      name: product.abstractName.replace('Abstract-', ''),
      images,
      thumbnail: images && images[0].thumbnail,
      ...product,
      ...attributes,
      allowedQuantity: {
        quantityMax: product.allowedQuantity.quantityMax || Infinity,
        quantityInterval: product.allowedQuantity.quantityInterval || 1,
        quantityMin: product.allowedQuantity.quantityMin || 0,
      },
    }
  })
