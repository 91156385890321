import { generatePath, matchPath } from 'react-router-dom'

import { CompanyUser } from 'src/api/company-user-search/types'

import {
  defaultPathPatterns,
  possiblePathPatterns,
} from 'src/routes/permissions/pathPatterns'

export const canVisitUrl = (url: string, companyUser: CompanyUser): boolean => {
  const path = new URL(url).pathname

  return canVisitPath(path, companyUser)
}

export const canVisitPath = (
  path: string,
  companyUser: CompanyUser
): boolean => {
  const companyRoleName = companyUser.companyRoles[0]?.name
  const companyType =
    companyUser?.companyTypeName === undefined
      ? 'retailer'
      : companyUser?.companyTypeName
  const pathPatterns =
    possiblePathPatterns?.[companyType]?.[companyRoleName] || []

  for (const pathPattern of pathPatterns) {
    if (!!matchPath(pathPattern, path)) {
      return true
    }
  }

  return false
}

export const getDefaultPathByCompanyUser = (
  companyUser: CompanyUser
): string => {
  const companyRoleName = companyUser.companyRoles[0]?.name
  const companyType =
    companyUser?.companyTypeName === undefined
      ? 'retailer'
      : companyUser?.companyTypeName
  const defaultPathPattern =
    defaultPathPatterns?.[companyType]?.[companyRoleName] || undefined

  if (defaultPathPattern === undefined) {
    throw Error('Whats going on here')
  }

  return generatePath(defaultPathPattern, {
    companyId: companyUser.companyId,
  })
}
