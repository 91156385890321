import { privateClient } from './api-client'
import { fetchInBatches, normalizePayloadForType } from './utils'

export const getCompaniesPaginated = async ({
  searchTerm,
  offset,
  limit,
  sort = '',
}) => {
  const payload = await privateClient('company-search', {
    searchParams: {
      q: searchTerm,
      sort,
      'page[offset]': offset,
      'page[limit]': limit,
    },
  }).json()

  const [companies] = normalizePayloadForType(payload, 'companySearch')

  return companies
}

export const getCompany = async (companyId) => {
  const payload = await privateClient('company-search', {
    searchParams: {
      id: companyId,
    },
  }).json()
  const [companies] = normalizePayloadForType(payload, 'companySearch')

  return companies
}

export const deleteCompany = async (companyId) =>
  privateClient.delete(`companies/${companyId}`)

export const getUsersPaginated = async ({
  searchTerm = '',
  companyId,
  offset,
  limit,
  companyRoleNames,
  onlyOnePerCustomer = false,
  sort,
}) => {
  const searchParams = new URLSearchParams({
    q: searchTerm,
    'page[offset]': offset,
    'page[limit]': limit,
    ...(companyId && { 'company-id': companyId }),
    sort: sort || 'last_name_asc',
    'show-all': true,
    'only-one-per-customer': onlyOnePerCustomer,
  })

  if (companyRoleNames) {
    for (const companyRoleName of companyRoleNames) {
      searchParams.append('company-role-name[]', companyRoleName)
    }
  }

  const payload = await privateClient('company-user-search', {
    searchParams,
  }).json()

  const [companyUsers] = normalizePayloadForType(payload, 'companyUserSearch')

  return companyUsers
}

export const getCompanyUsersByCompanyId = async ({ companyId }) => {
  const payload = await privateClient('company-user-search', {
    searchParams: {
      'company-id': companyId,
      'show-all': false,
    },
  }).json()

  const [companyUsers] = normalizePayloadForType(payload, 'companyUserSearch')

  return companyUsers
}

export const getCompanyUser = async (companyUserId) => {
  const payload = await privateClient('company-user-search', {
    searchParams: {
      'company-user-reference': companyUserId,
    },
  }).json()

  const [companyUser] = normalizePayloadForType(payload, 'companyUserSearch')

  return companyUser
}

export const getPriceList = async (companyId) => {
  const payload = await privateClient('price-lists', {
    searchParams: {
      'company-id': companyId,
    },
  }).json()
  const [priceList] = normalizePayloadForType(payload, 'priceLists')

  return priceList
}

export const getCompanyRoles = async (companyId) => {
  const companyRoles = await fetchInBatches({
    url: 'company-role-search',
    propertyName: 'companyRole',
    pageSize: 36,
    getSearchParams: (index, pageSize) => ({
      'page[offset]': pageSize * index,
      'page[limit]': pageSize,
      'company-id': companyId,
      'show-all': true,
      sort: 'name_asc',
    }),
  })

  return companyRoles
}

export const getCompanyShippingAddresses = async ({ companyId }) => {
  const shippingAddresses = await fetchInBatches({
    url: 'company-business-unit-address-search',
    propertyName: 'companyBusinessUnitAddresses',
    pageSize: 36,
    getSearchParams: (index, pageSize) => ({
      'page[offset]': pageSize * index,
      'page[limit]': pageSize,
      'company-id': companyId,
      'default-billing': false,
      sort: 'name1_asc',
    }),
  })

  return shippingAddresses
}

export const getCompanyShippingAddressesPaginated = async ({
  companyId,
  searchTerm = '',
  offset,
  limit,
}) => {
  const payload = await privateClient
    .get('company-business-unit-address-search', {
      searchParams: {
        q: searchTerm,
        'page[offset]': offset,
        'page[limit]': limit,
        'company-id': companyId,
        'default-billing': false,
        sort: 'name1_asc',
      },
    })
    .json()

  const [companyBusinessUnitAddresses] = normalizePayloadForType(
    payload,
    'companyBusinessUnitAddressSearch'
  )
  return companyBusinessUnitAddresses
}

export const getCompanyBillingAddress = async (companyId) => {
  const searchParams = new URLSearchParams({
    'company-id': companyId,
    'default-billing': true,
  })

  const payload = await privateClient
    .get('company-business-unit-address-search', {
      searchParams,
    })
    .json()

  const [
    {
      companyBusinessUnitAddresses: [companyBusinessUnitAddress],
    },
  ] = normalizePayloadForType(payload, 'companyBusinessUnitAddressSearch')

  return companyBusinessUnitAddress
}

export const postCompanyAddress = async ({ companyId, address }) => {
  const payload = await privateClient
    .post(`companies/${companyId}/companies-company-addresses`, {
      json: {
        data: {
          type: 'companies-company-addresses',
          attributes: address,
        },
      },
    })
    .json()

  return normalizePayloadForType(payload, 'companiesCompanyAddresses')
}

export const postCompanyUser = async ({ companyId, companyUser }) => {
  const { companyRoleId, ...customer } = companyUser

  const payload = await privateClient
    .post('company-users', {
      json: {
        data: {
          type: 'company-users',
          attributes: {
            company: {
              idCompany: companyId,
            },
            companyRole: {
              uuid: companyRoleId,
            },
            customer,
          },
        },
      },
    })
    .json()

  return normalizePayloadForType(payload, 'companiesCompanyUsers')
}

export const disableCompanyUser = async ({ companyUserId }) =>
  await privateClient
    .post(`disable-company-user`, {
      json: {
        data: {
          type: 'disable-company-user',
          attributes: {
            companyUserReference: companyUserId,
          },
        },
      },
    })
    .json()

export const deleteCompanyUser = async ({ companyUserId }) =>
  await privateClient.delete(`company-users/${companyUserId}`)

export const patchCompanyUserRole = async ({
  companyUserId,
  companyRoleId,
}) => {
  const payload = await privateClient.patch(`company-users/${companyUserId}`, {
    json: {
      data: {
        type: 'company-users',
        attributes: {
          companyRole: {
            uuid: companyRoleId,
          },
        },
      },
    },
  })

  return normalizePayloadForType(payload, 'companyUsers')
}

export const patchCompanyAddress = async ({
  companyId,
  address: { id: addressId, ...address },
}) => {
  const payload = await privateClient
    .patch(`companies/${companyId}/companies-company-addresses/${addressId}`, {
      json: {
        data: {
          type: 'companies-company-addresses',
          attributes: address,
        },
      },
    })
    .json()

  return normalizePayloadForType(payload, 'companiesCompanyAddresses')
}

export const deleteCompanyAddress = async ({ companyId, addressId }) =>
  privateClient.delete(
    `companies/${companyId}/companies-company-addresses/${addressId}`
  )

export const getCompanyBusinessUnitByCompanyId = async (companyId) => {
  const payload = await privateClient('company-business-unit-search', {
    searchParams: {
      'company-id': companyId,
    },
  }).json()

  const [
    {
      companyBusinessUnits: [companyBusinessUnit],
    },
  ] = normalizePayloadForType(payload, 'companyBusinessUnitSearch')

  return companyBusinessUnit
}

export const getManufacturer = async () => {
  const companyUserPayload = await privateClient('company-user-search', {
    searchParams: {
      'company-type': 'manufacturer',
    },
  }).json()

  const [manufacturer] = normalizePayloadForType(
    companyUserPayload,
    'companyUserSearch'
  )

  if (!manufacturer?.companyUser?.[0]) {
    return
  }

  return manufacturer.companyUser[0]
}
