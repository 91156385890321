import PERMISSIONS from 'src/hooks/utils/usePermissionsByCompanyUser/permissions'

const manufacturer = {
  sales_coordination: [
    PERMISSIONS.createCompanyUser,
    PERMISSIONS.deleteCompanyUser,
    PERMISSIONS.seeAvailability,
    PERMISSIONS.seeBetaMode,
    PERMISSIONS.updateCompanyUser,
    PERMISSIONS.updateDistributionProductLists,
    PERMISSIONS.updateOrderBudget,
  ],
  customer_service: [
    PERMISSIONS.createCompanyUser,
    PERMISSIONS.deleteCompanyUserWithRole('customer_service'),
    PERMISSIONS.seeAllMasterData,
    PERMISSIONS.seeAllMedia,
    PERMISSIONS.seeAvailability,
    PERMISSIONS.seeBetaMode,
    PERMISSIONS.updateCompanyUserWithRole('customer_service'),
  ],
  order_management: [
    PERMISSIONS.createCompanyUser,
    PERMISSIONS.deleteCompanyUserWithRole('order_management'),
    PERMISSIONS.seeAllMasterData,
    PERMISSIONS.seeAllMedia,
    PERMISSIONS.seeAvailability,
    PERMISSIONS.seeBetaMode,
    PERMISSIONS.updateCompanyUserWithRole('order_management'),
  ],
  distribution: [PERMISSIONS.seeAvailability, PERMISSIONS.seeBetaMode],
  distribution_management: [
    PERMISSIONS.seeAvailability,
    PERMISSIONS.seeBetaMode,
  ],
  super_distribution: [
    PERMISSIONS.persistTradeFair,
    PERMISSIONS.seeAvailability,
    PERMISSIONS.seeBetaMode,
  ],
  administration: [
    PERMISSIONS.createCompanyUser,
    PERMISSIONS.deleteCompanyUser,
    PERMISSIONS.seeAvailability,
    PERMISSIONS.seeBetaMode,
    PERMISSIONS.updateCompanyUser,
    PERMISSIONS.updateDistributionProductLists,
    PERMISSIONS.updateOrderBudget,
  ],
}

export default manufacturer
