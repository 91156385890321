import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from 'react-query'

import { getCompaniesPaginated } from 'src/api'

interface UseInfiniteCompaniesProps {
  searchTerm?: string
  limit?: number
}

interface UseInfiniteCompanies<
  UseInfiniteCompaniesProps,
  UseInfiniteQueryOptions
> {
  (
    //TODO: Add ESLint Typescript Rules
    // eslint-disable-next-line no-unused-vars
    args?: UseInfiniteCompaniesProps,
    // eslint-disable-next-line no-unused-vars
    options?: UseInfiniteQueryOptions
  ): UseInfiniteQueryResult
}

const selectorFn = (data: any) => {
  const [{ pagination }] = data.pages

  const items = data.pages.flatMap((page: any) => page.companies)

  return { ...data, items, pagination }
}

const getNextPageParam = (lastPage: any) => {
  if (lastPage.pagination.currentPage === lastPage.pagination.maxPage) {
    return undefined
  }

  return lastPage.pagination.currentPage
}

const sort = 'name_asc'

const useInfiniteCompanies: UseInfiniteCompanies<
  UseInfiniteCompaniesProps,
  UseInfiniteQueryOptions<unknown, unknown, unknown, unknown, string[]>
> = ({ searchTerm = '', limit = 12 } = {}, options) => {
  return useInfiniteQuery(
    ['companies-infinite', searchTerm],
    ({ pageParam = 0 }) =>
      getCompaniesPaginated({
        searchTerm,
        offset: pageParam * limit,
        limit,
        sort,
      }),
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 60 * 8,
      cacheTime: 1000 * 60 * 60 * 24,
      select: selectorFn,
      getNextPageParam,
      ...options,
    }
  )
}

export default useInfiniteCompanies
