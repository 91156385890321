import React, { Fragment } from 'react'

import { Trans, t } from '@lingui/macro'
import cn from '@meltdownjs/cn'
import Popover from '@reach/popover'
import { useSelect } from 'downshift'
import useOnclickOutside from 'react-cool-onclickoutside'

import { CheckIcon, XIcon } from '@heroicons/react/outline'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'

import Button from 'src/components/Button'

import pushDataLayer from 'src/lib/pushDataLayer'

const stateReducer = (state, actionAndChanges) => {
  const { changes, type } = actionAndChanges
  switch (type) {
    case useSelect.stateChangeTypes.MenuBlur:
      return {
        ...changes,
        isOpen: true,
      }
    case useSelect.stateChangeTypes.MenuKeyDownEnter:
    case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
    case useSelect.stateChangeTypes.ItemClick:
      return {
        ...changes,
        isOpen: true, // keep menu open after selection.
        highlightedIndex: state.highlightedIndex,
      }
    default:
      return changes
  }
}

const arraysEqual = (a, b) => {
  if (a === b) {
    return true
  }
  if (a == null || b == null) {
    return false
  }
  if (a.length !== b.length) {
    return false
  }

  a.sort((r, l) => r.localeCompare(l))
  b.sort((r, l) => r.localeCompare(l))

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false
    }
  }
  return true
}

const filterDataLayerPush = (label, value) => {
  pushDataLayer({
    event: 'filter_click',
    filter_name: label,
    filter_selection: value,
  })
}

const Filter = ({
  value = [],
  label,
  options,
  config: { isMultiValued },
  onChange,
  className,
  classNameChevron,
  hideSearch = false,
  labelIteratee,
  showIdicator = true,
  resetable = true,
}) => {
  const [filterValues, setFilterValues] = React.useState(value)
  const [searchTerm, setSearchTerm] = React.useState('')
  const popoverRef = React.useRef(null)

  React.useEffect(() => {
    if (value?.length === 0 && !arraysEqual(value, filterValues)) {
      setFilterValues(value)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.length])

  const updateFilterValue = React.useCallback(
    (value) => {
      filterDataLayerPush(label, value)
      const itemIsSelected = filterValues.indexOf(value) > -1
      if (searchTerm) {
        setSearchTerm('')
      }
      if (itemIsSelected) {
        const newFilter = filterValues.filter(
          (currentValue) => currentValue !== value
        )

        onChange(newFilter)
        setFilterValues(newFilter)
        return
      }

      if (isMultiValued) {
        onChange([...filterValues, value])
        setFilterValues([...filterValues, value])
        return
      }

      onChange(value)
      setFilterValues([value])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterValues, searchTerm]
  )

  const onStateChange = React.useCallback(
    ({ type, selectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
        case useSelect.stateChangeTypes.MenuBlur:
          if (selectedItem) {
            updateFilterValue(selectedItem.value)
            selectItem(null)
          }
          break
        default:
          break
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateFilterValue]
  )

  const {
    getToggleButtonProps,
    getItemProps,
    getMenuProps,
    highlightedIndex,
    isOpen,
    selectItem,
    closeMenu,
  } = useSelect({
    selectedItem: null,
    items: options,
    stateReducer,
    onStateChange,
  })

  const clickOutsideRef = useOnclickOutside(closeMenu)

  const resetFilter = () => {
    onChange([])
    setFilterValues([])
  }

  const sortedOptions = React.useMemo(
    () =>
      options.sort(
        ({ label: labelA, value: valueA }, { label: labelB, value: valueB }) =>
          labelA ? labelA.localeCompare(labelB) : valueA.localeCompare(valueB)
      ),
    [options]
  )

  const hideSearchInput = sortedOptions.length <= 3 || hideSearch

  return (
    <Fragment>
      <button
        {...getToggleButtonProps({ ref: popoverRef })}
        className={cn(
          'flex h-8 items-center overflow-hidden rounded border bg-white px-2 hover:border-gray-300',
          className
        )}
      >
        <div className="truncate pr-2">
          <span>{isMultiValued ? label : labelIteratee?.(value) || label}</span>
        </div>
        {showIdicator && filterValues.length > 0 && (
          <div className="flex h-[20px] w-[20px] flex-shrink-0 items-center justify-center rounded-full border-transparent bg-purple text-xs font-medium text-white">
            {isMultiValued ? (
              filterValues.length
            ) : (
              <CheckIcon className="h-4 w-4" />
            )}
          </div>
        )}
        <span className="ml-auto">
          {isOpen ? (
            <ChevronUpIcon className={cn(classNameChevron || 'w-5')} />
          ) : (
            <ChevronDownIcon className={cn(classNameChevron || 'w-5')} />
          )}
        </span>
      </button>
      <Popover
        hidden={!isOpen}
        targetRef={popoverRef}
        ref={clickOutsideRef}
        className={cn(
          hideSearchInput ? 'py-2' : 'pb-2',
          'z-[2] rounded-lg bg-white text-sm shadow-xl'
        )}
      >
        {!hideSearchInput && (
          <Fragment>
            <input
              tabIndex={0}
              className="input-search flex h-8 w-full flex-auto items-center overflow-hidden rounded border border-transparent bg-white placeholder-gray-400 hover:border-gray-300"
              value={searchTerm || ''}
              onChange={({ target: { value } }) => {
                setSearchTerm(value)
              }}
              type="search"
              placeholder={t`Search...`}
            />
            <hr className="my-2" />
          </Fragment>
        )}
        <div
          {...getMenuProps(
            {
              tabIndex: 0,
              className: 'max-h-[calc(2rem*14.8)] overflow-y-auto',
            },
            { suppressRefError: true }
          )}
        >
          {sortedOptions.map((item, index) => {
            const { label, value, doc_count } = item
            const isSelected = filterValues.indexOf(value) > -1
            const isHighlighted = index === highlightedIndex

            const matchesFilter =
              searchTerm && label
                ? label?.toUpperCase().includes(searchTerm.toUpperCase())
                : value.toUpperCase().includes(searchTerm.toUpperCase())
            if (!matchesFilter) {
              return null
            }

            return isMultiValued ? (
              <MultiSelectFilterItem
                key={index}
                checked={isSelected}
                highlighted={isHighlighted}
                label={label ? label : value}
                count={doc_count}
                {...getItemProps({ item: value, index, key: value })}
              />
            ) : (
              <SingleSelectFilterItem
                key={index}
                checked={isSelected}
                highlighted={isHighlighted}
                label={label ? label : value}
                count={doc_count}
                {...getItemProps({ item: value, index, key: value })}
              />
            )
          })}
        </div>
        {resetable && (
          <Fragment>
            <hr className="my-2" />
            <div className="flex flex-col space-y-2">
              <Button
                tabIndex={0}
                onClick={resetFilter}
                className="flex w-full items-center justify-center"
                size="sm"
                variant="red"
              >
                <Trans>Reset</Trans>
              </Button>
            </div>
          </Fragment>
        )}
      </Popover>
    </Fragment>
  )
}

export default Filter

export const MultiSelectFilterItem = React.forwardRef(
  ({ checked, highlighted, label, count, ...props }, ref) => (
    <div
      className={cn(
        'flex h-8 cursor-pointer items-center space-x-2 px-4',
        highlighted && 'bg-gray-300'
      )}
      ref={ref}
      {...props}
    >
      <div
        className={cn(
          'flex h-4 w-4 items-center justify-center rounded-sm border  text-white ',
          checked
            ? 'border-aubergine bg-aubergine'
            : 'border-gray-400 bg-white',
          highlighted && 'border-aubergine bg-aubergine'
        )}
      >
        {checked && <XIcon className="h-full w-full" />}
      </div>
      <div className="truncate">{label}</div>
      <span className="flex-grow" />
      {Number.isInteger(count) && (
        <span className="h-6 min-w-[1.5rem] flex-shrink-0 rounded-full bg-gray-200 px-2 py-1 text-center text-xs">
          {count}
        </span>
      )}
    </div>
  )
)

export const SingleSelectFilterItem = React.forwardRef(
  ({ checked, highlighted, label, count, ...props }, ref) => (
    <div
      className={cn(
        'flex h-8 cursor-pointer items-center space-x-2 px-4',
        checked && 'bg-gray-200',
        highlighted && 'bg-gray-300'
      )}
      ref={ref}
      {...props}
    >
      <span className="truncate">{label}</span>
      <span className="flex-grow" />
      {Number.isInteger(count) && (
        <span className="h-6 min-w-[1.5rem] flex-shrink-0 rounded-full bg-gray-200 px-2 py-1 text-center text-xs">
          {count}
        </span>
      )}
    </div>
  )
)
